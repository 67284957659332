import { useCurrentMode } from "./mode";

export function useColor() {
  const currentMode = useCurrentMode();
  if (currentMode === "unsigned" || currentMode === "guest") {
    return colors.green;
  }
  if (currentMode === "host") {
    return colors.orange;
  }
  if (currentMode === "admin") {
    return colors.blue;
  }
  return "";
}

export const colors = {
  blank: "#D9D9D9",
  darkyellow: "#FFB800",
  lightyellow: "#FFECBA",
  lightgreen: "#00FF57",
  lightorange: "#FFD363",
  darkgreen: "#00FF57",
  red: "#DC143C",
  blue: "#0085FF",
  logo: "#064032",
  green: "#064032",
  white: "#FFFFFF",
  yellow: "#FCFF76",
  ivory: "#FFFAF6",
  orange: "#fc5c00",
  black: "#000000",
  error: "#E02901",
  pink: "#FFBDF8",
};

export function combineHexAlpha(hex: string, alpha: number) {
  const converted = Math.round(alpha * 255).toString(16);
  return `${hex}${converted}`;
}
