import { useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import { useContext, useEffect, useState } from "react";
import { PhoneInput } from "../../inputs/TextInput";
import { useHostHooks } from "../../hooks/host";
import { useUser } from "../../hooks/user";
import { GlobalContext } from "../../App";
import Horizontal from "../../components/Horizontal";
import { ReactComponent as PaypalSvg } from "../../svgs/paypal.svg";
import { ReactComponent as VenmoSvg } from "../../svgs/venmo.svg";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { getEmail } from "../../util";
import RoundButton from "../../components/RoundButton";
import { colors, combineHexAlpha } from "../../hooks/color";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { PaymentMethod } from "../../enums";
import { fetchData } from "../../api";
import { subMonths } from "date-fns";
import TimeUtil from "../../utils/time";
import { abbrMonths } from "../../const";
import CustomDialog from "../../components/CustomDialog";
import RadioInput from "../../inputs/RadioInput";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface PayoutMethodComponentProps {
  method: PaymentMethod;
  account: string;
  handleSave: (method: PaymentMethod, account: string) => Promise<void>;
}

function PayoutMethodComponent({
  method,
  account,
  handleSave,
}: PayoutMethodComponentProps) {
  const [edit, setEdit] = useState<boolean>(false);
  const [tempMethod, setTempMethod] = useState<PaymentMethod>(
    PaymentMethod.paypal
  );
  const [tempAccount, setTempAccount] = useState<string>("");

  useEffect(() => {
    setTempMethod(method);
    setTempAccount(account);
  }, [method, account]);

  return (
    <>
      <div
        style={{
          fontSize: 14,
        }}
      >
        <div style={{ display: "flex", columnGap: 10, alignItems: "center" }}>
          {method === PaymentMethod.paypal && <PaypalSvg />}
          {method === PaymentMethod.venmo && <VenmoSvg />}
        </div>
        <div
          style={{
            marginTop: 15,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div style={{ fontWeight: 600 }}>{account}</div>
          <div
            style={{
              fontWeight: 600,
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => setEdit(true)}
          >
            Edit
          </div>
        </div>
      </div>
      <CustomDialog open={edit} handleClose={() => setEdit(false)}>
        <div style={{ marginTop: 30 }}>
          <RadioInput
            value={tempMethod}
            row
            items={[
              {
                label: <PaypalSvg />,
                value: PaymentMethod.paypal,
              },
              {
                label: <VenmoSvg />,
                value: PaymentMethod.venmo,
              },
            ]}
            handleChange={(value) => setTempMethod(value as PaymentMethod)}
          />
        </div>
        <div style={{ marginTop: 15 }}>
          <PhoneInput
            label="Account phone number"
            handleChange={setTempAccount}
            value={tempAccount}
          />
        </div>
        <div style={{ marginTop: 30, textAlign: "center" }}>
          <RoundButton
            color={colors.white}
            backgroundColor={colors.green}
            handleClick={() =>
              handleSave(tempMethod, tempAccount).then(() => setEdit(false))
            }
          >
            Save
          </RoundButton>
        </div>
      </CustomDialog>
    </>
  );
}

interface ClickComponentProps {
  label: string;
  handleClick?: () => void;
}

function ClickComponent({ label, handleClick }: ClickComponentProps) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        cursor: "pointer",
        fontWeight: 700,
      }}
      onClick={handleClick}
    >
      <div>{label}</div>
      <div style={{ cursor: "pointer" }}>
        <ChevronRightIcon />
      </div>
    </div>
  );
}

interface HandleClickComponentProps {
  handleClick: () => void;
}

interface HandleBackComponentProps {
  handleBack: () => void;
}

function PayoutSummaryComponentMenu({
  handleClick,
}: HandleClickComponentProps) {
  return <ClickComponent label="Payout Summary" handleClick={handleClick} />;
}

function HowGetPaidComponentMenu({ handleClick }: HandleClickComponentProps) {
  return (
    <ClickComponent label="How Do I Get Paid?" handleClick={handleClick} />
  );
}

interface Payout {
  payout_date: string;
  amount: number;
}

interface PayoutInfo {
  total: number;
  payouts: Payout[];
}

function PayoutSummaryComponent({ handleBack }: HandleBackComponentProps) {
  const [payoutInfo, setPayoutInfo] = useState<PayoutInfo | null>(null);
  const [currentIndex, setCurrentIndex] = useState<number>(-1);

  useEffect(() => {
    fetchData<PayoutInfo>("GET", `/hosts/payout`).then(setPayoutInfo);
  }, []);

  const fromDate = subMonths(new Date(), 11);
  const nowDate = new Date();

  if (!payoutInfo) return null;

  // generate data from given payout info
  // use map -> amount
  const amountMap = new Array(12).fill(0);
  const eventMap = new Array(12).fill(0);
  for (const payout of payoutInfo.payouts) {
    // drop if payout date is more than year
    const payoutDate = new Date(payout.payout_date);
    const index = payoutDate.getMonth();
    if (payoutDate < fromDate) continue;
    amountMap[index] += payout.amount;
    eventMap[index]++;
  }

  const labelMonths = abbrMonths
    .slice(fromDate.getMonth())
    .concat(abbrMonths.slice(0, fromDate.getMonth()));

  const labelAmounts = amountMap
    .slice(fromDate.getMonth())
    .concat(amountMap.slice(0, fromDate.getMonth()));

  const labelEvents = eventMap
    .slice(fromDate.getMonth())
    .concat(eventMap.slice(0, fromDate.getMonth()));

  const data = {
    labels: labelMonths,
    datasets: [
      {
        label: "Amount",
        data: labelAmounts,
        backgroundColor: combineHexAlpha(colors.orange, 0.3),
      },
    ],
  };

  return (
    <>
      <Header label="Payout Summary" handleBack={handleBack} />
      <div
        style={{
          padding: 30,
        }}
      >
        <div>
          <div>Total Earning</div>
          <div style={{ fontSize: 24, fontWeight: 700 }}>
            ${payoutInfo.total.toLocaleString()}
          </div>
        </div>
        <Horizontal marginTop={30} marginBottom={30} opacity={0.3} />
        <div
          style={{ marginTop: 30, color: colors.orange, textAlign: "center" }}
        >
          <div>
            {TimeUtil.formatMonthYear(fromDate)} -{" "}
            {TimeUtil.formatMonthYear(nowDate)}
          </div>
          <div>
            <Bar
              options={{
                responsive: true,
                onClick: (event, elements) => {
                  if (elements && elements.length > 0) {
                    const clickedElement = elements[0];
                    const index = clickedElement.index;
                    setCurrentIndex(index);
                  }
                },
              }}
              data={data}
            />
          </div>
        </div>
        {currentIndex !== -1 && (
          <div style={{ marginTop: 30 }}>
            <div>Month: {labelMonths[currentIndex]}</div>
            <div>
              Total Events: {labelEvents[currentIndex].toLocaleString()}
            </div>
            <div>
              Total Earning: ${labelAmounts[currentIndex].toLocaleString()}
            </div>
          </div>
        )}
      </div>
    </>
  );
}

function HowGetPaidComponent({ handleBack }: HandleBackComponentProps) {
  return (
    <>
      <Header label="How Do I Get Paid?" handleBack={handleBack} />
      <div
        style={{
          padding: 30,
        }}
      >
        <div>
          This page outlines the transparent and straightforward payment process
          at Tably.
        </div>
        <div style={{ marginTop: 20 }}>
          <div>
            <b>Earnings</b>
          </div>
          <div style={{ marginTop: 10 }}>
            <ul>
              <li>
                You will receive <b>85% of the total</b> revenue generated from
                your hosted meals.
              </li>
            </ul>
          </div>
        </div>
        <div style={{ marginTop: 20 }}>
          <div>
            <b>Example</b>
          </div>
          <div style={{ marginTop: 10 }}>
            <ul>
              <li>
                If you set a menu price of $50 per guest and host a dinner for 4
                guests, your total revenue would be $200 (50 x 4).
              </li>
              <li>
                After Tably's 7% service fee ($14), your earnings would be $186
                ($200 - $14).
              </li>
            </ul>
          </div>
        </div>
        <div style={{ marginTop: 20 }}>
          <div>
            <b>Payment Method</b>
          </div>
          <div style={{ marginTop: 10 }}>
            <ul>
              <li>
                You can choose your preferred payment method: direct deposit to
                your Venmo or Paypal.
              </li>
              <li>
                We aim to process your earnings within 3 business days after the
                conclusion of your hosted meal.
              </li>
              <li>
                You will receive an email notification once your payment has
                been processed.
              </li>
            </ul>
          </div>
        </div>
        <div style={{ marginTop: 20 }}>
          <div>
            <b>Important Notes</b>
          </div>
          <div style={{ marginTop: 10 }}>
            <ul>
              <li>Tably does not charge any upfront or listing fees.</li>
              <li>
                We encourage you to clearly communicate your pricing and any
                additional fees to your guests before they book your experience.
              </li>
            </ul>
          </div>
        </div>
        <div style={{ marginTop: 20 }}>
          <div>
            <b>Transparency and Fairness:</b>
          </div>
          <div style={{ marginTop: 10 }}>
            <ul>
              <li>
                At Tably, we believe in fair compensation for our hosts who are
                the heart of our community. We strive to provide a transparent
                and efficient payment process that allows you to focus on what
                you do best - creating memorable culinary experiences for our
                guests.
              </li>
            </ul>
            <div style={{ marginTop: 20 }}>
              If you have any further questions about the payment process or any
              aspect of hosting on Tably, please don't hesitate to contact our
              support team at {getEmail()}.
            </div>
            <div style={{ marginTop: 10 }}>
              We look forward to your continued success on Tably!
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

type PayoutMode = "" | "summary" | "how";

function Payouts() {
  const [payoutMode, setPayoutMode] = useState<PayoutMode>("");
  const navigate = useNavigate();
  const { putHost } = useHostHooks();
  const { refreshUser } = useContext(GlobalContext);
  const user = useUser();
  if (!user) return null;
  const host = user.host;
  if (!host) return null;
  if (payoutMode === "summary")
    return <PayoutSummaryComponent handleBack={() => setPayoutMode("")} />;
  if (payoutMode === "how")
    return <HowGetPaidComponent handleBack={() => setPayoutMode("")} />;
  // grab paypal and venmo account object
  const payout = host.meta.payout || {};
  return (
    <>
      <Header label="Payouts" handleBack={() => navigate("/profile")} />
      <div
        style={{
          padding: 30,
        }}
      >
        <div style={{ marginTop: 15, fontWeight: 700 }}>Payout Method</div>
        <div style={{ marginTop: 30 }}>
          <PayoutMethodComponent
            method={payout.method}
            account={payout.account}
            handleSave={(method, account) => {
              // if paypal payout is not available, then create one
              // if available, then modify existing one
              return putHost({
                aboutMe: host.meta.about_me,
                experienceInPartySize: host.meta.experience_in_party_size,
                languages: host.meta.languages,
                payout: {
                  method,
                  account,
                },
              }).then(refreshUser);
            }}
          />
        </div>
        <Horizontal marginTop={30} marginBottom={30} />
        <PayoutSummaryComponentMenu
          handleClick={() => setPayoutMode("summary")}
        />
        <Horizontal marginTop={30} marginBottom={30} />
        <HowGetPaidComponentMenu handleClick={() => setPayoutMode("how")} />
        <Horizontal marginTop={30} />
        {/* {payoutMethod && (
          <div style={{ marginTop: 15, marginBottom: 50 }}>
            <TextInput
              label="Account"
              handleChange={setPayoutAccount}
              defaultValue={payoutAccount}
            />
          </div>
        )}
        <div style={{ marginTop: "auto" }}>
          <CustomButton
            fullWidth
            handleClick={() =>
              putHost({
                culinary_background: host.meta.culinary_background,
                serving_people_experience: host.meta.serving_people_experience,
                fun_fact: host.meta.fun_fact,
                specialize: host.meta.specialize,
                speak: host.meta.speak,
                hobby: host.meta.hobby,
                host_description: host.meta.host_description,
                payout_method: payoutMethod,
                payout_account: payoutAccount,
              })
                .then(refreshUser)
                .then(() => navigate("/profile"))
            }
            disabled={disabled}
          >
            Update
          </CustomButton>
        </div> */}
      </div>
    </>
  );
}

export default Payouts;
