export default class LocationUtil {
  static getLocationString(
    address1: string,
    address2: string,
    city: string,
    state: string,
    zipcode: string
  ) {
    const listDetails = [];
    if (address1) {
      listDetails.push(address1);
    }
    if (address2) {
      listDetails.push(address2);
    }
    if (city) {
      listDetails.push(city);
    }
    if (state) {
      listDetails.push(state);
    }
    if (zipcode) {
      listDetails.push(zipcode);
    }
    return listDetails.join(", ");
  }

  static setCurrentLocation(lat: number, lng: number, sec: number) {
    // Calculate expiration time in milliseconds
    const expirationMS = sec * 1000;
    const expirationTime = new Date().getTime() + expirationMS;

    // Create an object to store both the value and expiration time
    const position = {
      value: {
        lat,
        lng,
      },
      expiration: expirationTime,
    };

    localStorage.setItem("position", JSON.stringify(position));
  }

  static getCurrentPosition() {
    const positionString = window.localStorage.getItem("position");
    if (!positionString) return null;
    // Parse the item string to retrieve the stored object
    const item = JSON.parse(positionString);
    // Check if the item has expired
    if (new Date().getTime() > item.expiration) {
      localStorage.removeItem("position"); // Remove expired item from local storage
      return null; // Item has expired
    }
    // Return the value of the item
    return item.value;
  }
}
