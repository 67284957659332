import React from "react";

interface EventInfoProps {
  eventType: string;
  cuisine: string;
  title: string;
}

function EventInfo({ eventType, cuisine, title }: EventInfoProps) {
  return (
    <>
      <div
        style={{
          fontSize: 14,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {eventType} • {cuisine}
      </div>
      <div style={{ marginTop: 10, fontSize: 24 }}>{title}</div>
    </>
  );
}

export default EventInfo;
