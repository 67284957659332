import React from "react";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { colors } from "../hooks/color";

type BackwardType = "default" | "inverted" | "outlined";

interface BackwardProps {
  handleClick?: () => void;
  style?: React.CSSProperties;
  type?: BackwardType;
}

function Backward({ handleClick, style, type = "default" }: BackwardProps) {
  let refinedStyle = {
    backgroundColor: colors.green,
    color: colors.ivory,
  } as React.CSSProperties;
  if (type === "inverted") {
    refinedStyle = {
      backgroundColor: colors.ivory,
      color: colors.green,
    };
  }
  if (type === "outlined") {
    refinedStyle = {
      backgroundColor: colors.ivory,
      color: colors.green,
      border: `1px solid ${colors.green}`,
    };
  }
  refinedStyle = {
    ...refinedStyle,
    ...style,
  };
  return (
    <div
      style={{
        width: 35,
        height: 35,
        borderRadius: "50%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        top: 30,
        left: 30,
        cursor: "pointer",
        zIndex: 5,
        ...refinedStyle,
      }}
      onClick={handleClick}
    >
      <ArrowBackIosNewIcon
        sx={{
          marginRight: "3px",
        }}
      />
    </div>
  );
}

export default Backward;
