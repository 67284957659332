import React, { createContext, useContext, useEffect, useState } from "react";
import { useHostHooks } from "../../hooks/host";
import TextInput, { PhoneInput } from "../../inputs/TextInput";
import { colors } from "../../hooks/color";
import CustomImage from "../../components/CustomImage";
import { GlobalContext } from "../../App";
import { useNavigate } from "react-router-dom";
import { worldLanguages } from "../../const";
import { fontFamily } from "../../hooks/font";
import Backward from "../../components/Backward";
import RoundButton from "../../components/RoundButton";
import Horizontal from "../../components/Horizontal";
import { ReactComponent as CookingSvg } from "../../svgs/cooking.svg";
import { ReactComponent as MoneySvg } from "../../svgs/money.svg";
import { ReactComponent as Calendar2Svg } from "../../svgs/calendar2.svg";
import { ReactComponent as CommunitySvg } from "../../svgs/community.svg";
import { ReactComponent as InfoSvg } from "../../svgs/info.svg";
import { ReactComponent as AddSvg } from "../../svgs/add.svg";
import { ReactComponent as PaypalSvg } from "../../svgs/paypal.svg";
import { ReactComponent as VenmoSvg } from "../../svgs/venmo.svg";
import HostOnboardCompletePng from "../../pngs/hostonboard_complete.png";
import CustomDialog from "../../components/CustomDialog";
import AccordionSelect from "../../inputs/AccordionSelect";
import RadioInput from "../../inputs/RadioInput";
import ScrollSelect from "../../inputs/ScrollSelect";
import { ReactComponent as CloseSvg } from "../../svgs/close.svg";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { PaymentMethod } from "../../enums";
import { useUser } from "../../hooks/user";

interface LabelProps {
  label: string;
  sublabel: string;
}

function Label({ label, sublabel }: LabelProps) {
  return (
    <div style={{ display: "flex", columnGap: 5, alignItems: "center" }}>
      <div
        style={{
          fontSize: 16,
          fontWeight: 600,
        }}
      >
        {label}
      </div>
      <div
        style={{
          opacity: 0.5,
          fontSize: 14,
        }}
      >
        ({sublabel})
      </div>
    </div>
  );
}

function AboutMeComponent({
  aboutMe,
  setAboutMe,
}: {
  aboutMe: string;
  setAboutMe: (aboutMe: string) => void;
}) {
  const [open, setOpen] = useState<boolean>(false);
  return (
    <>
      <div style={{ position: "relative" }}>
        <TextInput
          label={<Label label="About Me" sublabel="Public" />}
          multiline
          rows={5}
          handleChange={setAboutMe}
          defaultValue={aboutMe}
        />
        <div
          style={{
            position: "absolute",
            display: "flex",
            cursor: "pointer",
            top: 0,
            right: 0,
          }}
          onClick={() => setOpen(true)}
        >
          <InfoSvg />
        </div>
      </div>
      <CustomDialog open={open} handleClose={() => setOpen(false)}>
        <div
          style={{
            color: colors.orange,
            fontSize: 24,
            fontFamily: fontFamily.simula,
          }}
        >
          Tips on writing about yourself
        </div>
        <div style={{ marginTop: 30 }}>
          Tell us about your culinary experience, what you specialize in and
          general info about yourself you’d like to share.
          <br />
          <br />
          As a cherry on top, also tell them your hobby or fun facts!
        </div>
      </CustomDialog>
    </>
  );
}

function ExperienceComponent({
  experienceInPartySize,
  setExperienceInPartySize,
}: {
  experienceInPartySize: string;
  setExperienceInPartySize: (experienceInPartySize: string) => void;
}) {
  return (
    <AccordionSelect
      label={<Label label="Experience in Party Size" sublabel="Private" />}
      items={["0-5", "6-10", "11-20", "20-30", "More than 30"].map((value) => ({
        label: value,
        value,
      }))}
      value={experienceInPartySize}
      handleClick={setExperienceInPartySize}
    />
  );
}

function LanguageComponent({
  languages,
  setLanguages,
}: {
  languages: string[];
  setLanguages: (languages: string[]) => void;
}) {
  const [open, setOpen] = useState<boolean>(false);
  const [localValues, setLocalValues] = useState<string[]>([]);

  useEffect(() => {
    setLocalValues([...languages]);
  }, [languages]);
  return (
    <>
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Label label="Languages I Speak" sublabel="Public" />
          <AddSvg style={{ cursor: "pointer" }} onClick={() => setOpen(true)} />
        </div>
        <div style={{ marginTop: 30 }}>
          <DragDropContext
            onDragEnd={(props) => {
              if (!props.destination) return;
              // swap index
              const temp = languages[props.source.index];
              languages[props.source.index] =
                languages[props.destination.index] || "";
              languages[props.destination.index] = temp;
              setLanguages([...languages.filter((value) => value !== "")]);
            }}
          >
            {(() => {
              const refined = [...languages];
              // only visible 3 elements
              // fill up null value to item
              while (refined.length % 3 !== 0) {
                refined.push("");
              }
              const flexCount = refined.length / 3;
              // form up now
              return (
                <>
                  {(() => {
                    const res = [];
                    for (let i = 0; i < flexCount; i++) {
                      res.push(
                        <Droppable
                          droppableId={String(i)}
                          direction="horizontal"
                          key={i}
                        >
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.droppableProps}
                              style={{
                                marginTop: i === 0 ? 0 : 10,
                                display: "flex",
                                columnGap: 10,
                                alignItems: "center",
                              }}
                            >
                              {(() => {
                                const res = [];
                                for (let j = 3 * i; j < 3 * (i + 1); j++) {
                                  res.push(
                                    <Draggable
                                      draggableId={String(j)}
                                      index={j}
                                      key={j}
                                    >
                                      {(provided) => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={{
                                            ...provided.draggableProps.style,
                                            width: "33.3%",
                                          }}
                                        >
                                          {(() => {
                                            const language = refined[j];
                                            if (!language) return null;
                                            return (
                                              <div
                                                style={{
                                                  padding: 10,
                                                  textAlign: "center",
                                                  fontSize: 14,
                                                  borderRadius: 10,
                                                  border: `1px solid ${colors.green}`,
                                                  position: "relative",
                                                  backgroundColor: colors.ivory,
                                                }}
                                              >
                                                {language}
                                                <div
                                                  style={{
                                                    position: "absolute",
                                                    top: 0,
                                                    right: 0,
                                                    transform:
                                                      "translate(50%, -50%)",
                                                  }}
                                                >
                                                  <CloseSvg
                                                    style={{
                                                      width: 20,
                                                      height: "auto",
                                                      fill: colors.green,
                                                      stroke: colors.ivory,
                                                      cursor: "pointer",
                                                    }}
                                                    onClick={() => {
                                                      refined.splice(j, 1);
                                                      setLanguages([
                                                        ...refined.filter(
                                                          (value) =>
                                                            value !== ""
                                                        ),
                                                      ]);
                                                    }}
                                                  />
                                                </div>
                                              </div>
                                            );
                                          })()}
                                        </div>
                                      )}
                                    </Draggable>
                                  );
                                }
                                return res;
                              })()}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      );
                    }
                    return res;
                  })()}
                </>
              );
            })()}
          </DragDropContext>
        </div>
      </div>
      <CustomDialog open={open} handleClose={() => setOpen(false)}>
        <ScrollSelect
          items={worldLanguages.map((language) => ({
            label: language,
            value: language,
          }))}
          handleChange={(values) => setLocalValues([...values])}
          label="Languages I Speak"
          values={localValues}
        />
        <div style={{ marginTop: 30, textAlign: "center" }}>
          <RoundButton
            handleClick={() => {
              setLanguages([...localValues]);
              setOpen(false);
            }}
          >
            Add
          </RoundButton>
        </div>
      </CustomDialog>
    </>
  );
}

interface HostOnboardContextProps {
  payoutMethod: PaymentMethod;
  payoutAccount: string;
  aboutMe: string;
  experienceInPartySize: string;
  languages: string[];
  file: File | null;
  setPayoutMethod: (payoutMethod: PaymentMethod) => void;
  setPayoutAccount: (payoutAccount: string) => void;
  setAboutMe: (aboutMe: string) => void;
  setExperienceInPartySize: (experienceInPartySize: string) => void;
  setLanguages: (languages: string[]) => void;
  setFile: (file: File | null) => void;
}

const HostOnboardContext = createContext({} as HostOnboardContextProps);

function HostOnboard() {
  const [index, setIndex] = useState<number>(0);
  const [aboutMe, setAboutMe] = useState<string>("");
  const [experienceInPartySize, setExperienceInPartySize] =
    useState<string>("");
  const [languages, setLanguages] = useState<string[]>([]);
  const [file, setFile] = useState<File | null>(null);
  const [payoutMethod, setPayoutMethod] = useState<PaymentMethod>(
    PaymentMethod.paypal
  );
  const [payoutAccount, setPayoutAccount] = useState<string>("");
  const { createHost } = useHostHooks();
  const { refreshUser } = useContext(GlobalContext);
  const navigate = useNavigate();

  const next = () => setIndex(index + 1);

  if (index === 3)
    return <HostOnboardStep4 next={() => navigate("/profile")} />;

  return (
    <HostOnboardContext.Provider
      value={{
        payoutMethod,
        payoutAccount,
        aboutMe,
        experienceInPartySize,
        languages,
        file,
        setPayoutMethod,
        setPayoutAccount,
        setAboutMe,
        setExperienceInPartySize,
        setLanguages,
        setFile,
      }}
    >
      <div
        style={{
          padding: "90px 30px",
        }}
      >
        <Backward handleClick={() => navigate("/profile")} />
        {(() => {
          if (index === 0) return <HostOnboardStep1 next={next} />;
          if (index === 1)
            return (
              <HostOnboardStep2
                next={next}
                disabled={
                  !(
                    Boolean(aboutMe) &&
                    Boolean(experienceInPartySize) &&
                    Boolean(languages)
                  )
                }
              />
            );
          if (index === 2)
            return (
              <HostOnboardStep3
                next={() => {
                  createHost({
                    aboutMe,
                    experienceInPartySize,
                    languages,
                    payout: {
                      method: payoutMethod,
                      account: payoutAccount,
                    },
                  })
                    .then(refreshUser)
                    .then(next);
                }}
                disabled={
                  !(
                    Boolean(aboutMe) &&
                    Boolean(experienceInPartySize) &&
                    Boolean(languages) &&
                    Boolean(payoutMethod) &&
                    Boolean(payoutAccount)
                  )
                }
              />
            );
          return null;
        })()}
      </div>
    </HostOnboardContext.Provider>
  );
}

interface HostOnboardStepProps {
  next: () => void;
  disabled?: boolean;
}

function HostOnboardStep1({ next }: HostOnboardStepProps) {
  const color = colors.orange;
  return (
    <>
      <div style={{ fontSize: 24, fontFamily: fontFamily.simula }}>
        Get ready to become a host
      </div>
      <div style={{ marginTop: 30, display: "flex", columnGap: 50 }}>
        <CookingSvg style={{ marginLeft: 15 }} />
        <div style={{ flex: 1, fontSize: 16 }}>
          <div style={{ color, fontWeight: 700 }}>Share Your Passion</div>
          <div style={{ marginTop: 5 }}>
            Whether you're an experienced chef or a home cook with a flair for
            flavors, becoming a host allows you to share your passion for
            cooking with others.
          </div>
        </div>
      </div>
      <div style={{ marginTop: 30, display: "flex", columnGap: 50 }}>
        <MoneySvg style={{ marginLeft: 15 }} />
        <div style={{ flex: 1, fontSize: 16 }}>
          <div style={{ color, fontWeight: 700 }}>Earn extra income</div>
          <div style={{ marginTop: 5 }}>
            Turn your kitchen into a dining destination and earn extra income by
            hosting meals for guests.
          </div>
        </div>
      </div>
      <div style={{ marginTop: 30, display: "flex", columnGap: 50 }}>
        <Calendar2Svg style={{ marginLeft: 15 }} />
        <div style={{ flex: 1, fontSize: 16 }}>
          <div style={{ color, fontWeight: 700 }}>Set your own schedules</div>
          <div style={{ marginTop: 5 }}>
            Host meals at your convenience, whether it's a weekly dinner or a
            special occasion event.
          </div>
        </div>
      </div>
      <div
        style={{
          marginTop: 30,
          display: "flex",
          columnGap: 50,
          marginBottom: 50,
        }}
      >
        <CommunitySvg style={{ marginLeft: 15 }} />
        <div style={{ flex: 1, fontSize: 16 }}>
          <div style={{ color, fontWeight: 700 }}>Build your own community</div>
          <div style={{ marginTop: 5 }}>
            Join a vibrant community of hosts and guests who share a love for
            food, learning, and exploration.
          </div>
        </div>
      </div>
      <div style={{ marginTop: "auto", textAlign: "center" }}>
        <RoundButton
          color={colors.white}
          backgroundColor={colors.green}
          handleClick={next}
        >
          Continue
        </RoundButton>
      </div>
    </>
  );
}

interface HostInfoInputComponentProps {
  aboutMe: string;
  setAboutMe: (aboutMe: string) => void;
  experienceInPartySize: string;
  setExperienceInPartySize: (experienceInPartySize: string) => void;
  languages: string[];
  setLanguages: (languages: string[]) => void;
}

export function HostInfoInputComponent({
  aboutMe,
  setAboutMe,
  experienceInPartySize,
  setExperienceInPartySize,
  languages,
  setLanguages,
}: HostInfoInputComponentProps) {
  return (
    <>
      <AboutMeComponent aboutMe={aboutMe} setAboutMe={setAboutMe} />
      <Horizontal marginTop={30} marginBottom={30} />
      <ExperienceComponent
        experienceInPartySize={experienceInPartySize}
        setExperienceInPartySize={setExperienceInPartySize}
      />
      <Horizontal marginTop={30} marginBottom={30} />
      <LanguageComponent languages={languages} setLanguages={setLanguages} />
      <Horizontal marginTop={30} marginBottom={30} />
    </>
  );
}

function HostOnboardStep2({ next, disabled }: HostOnboardStepProps) {
  const {
    aboutMe,
    setAboutMe,
    experienceInPartySize,
    setExperienceInPartySize,
    languages,
    setLanguages,
  } = useContext(HostOnboardContext);

  return (
    <>
      <div style={{ fontSize: 24, fontFamily: fontFamily.simula }}>
        Tell us more and become a superhost!
      </div>
      <div style={{ marginTop: 30, marginBottom: 50 }}>
        <HostInfoInputComponent
          aboutMe={aboutMe}
          setAboutMe={setAboutMe}
          experienceInPartySize={experienceInPartySize}
          setExperienceInPartySize={setExperienceInPartySize}
          languages={languages}
          setLanguages={setLanguages}
        />
      </div>
      <div style={{ marginTop: "auto", textAlign: "center" }}>
        <RoundButton
          color={colors.white}
          backgroundColor={colors.green}
          handleClick={next}
          disabled={disabled}
        >
          Continue
        </RoundButton>
      </div>
    </>
  );
}

function HostOnboardStep3({ next, disabled }: HostOnboardStepProps) {
  const { payoutMethod, payoutAccount, setPayoutMethod, setPayoutAccount } =
    useContext(HostOnboardContext);
  const user = useUser();
  if (!user) return null;
  return (
    <>
      <div style={{ fontSize: 24, fontFamily: fontFamily.simula }}>
        Connect your
        <br />
        account to get paid
      </div>
      <div style={{ marginTop: 30 }}>
        <RadioInput
          value={payoutMethod}
          row
          items={[
            {
              label: <PaypalSvg />,
              value: PaymentMethod.paypal,
            },
            {
              label: <VenmoSvg />,
              value: PaymentMethod.venmo,
            },
          ]}
          handleChange={(value) => setPayoutMethod(value as PaymentMethod)}
        />
      </div>
      <div style={{ marginTop: 15 }}>
        <PhoneInput
          label="Account phone number"
          handleChange={setPayoutAccount}
          value={payoutAccount}
        />
      </div>
      <div style={{ marginTop: 30, textAlign: "center" }}>
        <RoundButton
          color={colors.white}
          backgroundColor={colors.green}
          handleClick={next}
          disabled={disabled}
        >
          Submit
        </RoundButton>
      </div>
    </>
  );
}

function HostOnboardStep4({ next }: HostOnboardStepProps) {
  return (
    <div style={{ position: "relative", height: "100%" }}>
      <CustomImage
        src={HostOnboardCompletePng}
        style={{ height: "100%", backgroundPosition: "top" }}
      />
      <div
        style={{
          position: "absolute",
          inset: 0,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          color: colors.yellow,
          textAlign: "center",
        }}
      >
        <div style={{ fontSize: 30, fontFamily: fontFamily.simula }}>
          You’re all set!
        </div>
        <div style={{ marginTop: 30, width: 250, fontSize: 20 }}>
          We’ll send you a confirmation email once we finish verifying your
          identity.
        </div>
        <div style={{ marginTop: 200 }}>
          <RoundButton
            color={colors.yellow}
            backgroundColor={colors.green}
            handleClick={next}
            style={{ width: 130 }}
          >
            Done
          </RoundButton>
        </div>
      </div>
    </div>
  );
}
export default HostOnboard;
