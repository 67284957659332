import { fetchData } from "../api";

interface ImageMeta {
  filename: string;
  location: string;
}

interface ImageProps {
  id: number;
  created_at: string;
  meta: ImageMeta;
}

export function useImageUpload() {
  const imageUpdate = (image_id: number, file: File) => {
    const formData = new FormData();
    formData.append("file", file);
    return fetchData<{
      location: string;
    }>("PUT", `/images/${image_id}`, formData, true);
  };

  const imageUpload = (file: File) => {
    return fetchData<ImageProps>("POST", "/images").then((image) =>
      imageUpdate(image.id, file)
    );
  };

  return { imageUpload };
}
