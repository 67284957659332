import React from "react";
import EventItems from "./EventItems";
import { EventItemType, UserMode } from "../enums";
import { useCurrentMode } from "../hooks/mode";
import UnsignedView from "../components/UnsignedView";

function Upcoming() {
  const currentMode = useCurrentMode();
  if (currentMode === UserMode.unsigned) {
    return (
      <UnsignedView
        label={
          <>
            Sign in or create an account
            <br />
            to see your events.
          </>
        }
        redirect="/upcoming"
      />
    );
  }
  if (currentMode === UserMode.guest) {
    return (
      <EventItems
        eventItemTypes={[
          EventItemType.all,
          EventItemType.upcoming,
          EventItemType.past,
        ]}
      />
    );
  }
  if (currentMode === UserMode.host) {
    return (
      <EventItems
        eventItemTypes={[
          EventItemType.all,
          EventItemType.upcoming,
          EventItemType.approved,
          EventItemType.pending,
          EventItemType.past,
          EventItemType.cancelled,
          EventItemType.rejected,
          EventItemType.draft,
        ]}
      />
    );
  }
  return null;
}

export default Upcoming;
