import { useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import { getEmail } from "../../util";

function FAQ() {
  const navigate = useNavigate();
  return (
    <>
      <Header label="FAQ" handleBack={() => navigate(-1)} />
      <div style={{ padding: 30, fontSize: 14 }}>
        <div>
          <div style={{ fontWeight: 700 }}>What is Tably?</div>
          <div style={{ marginTop: 15 }}>
            Tably is a unique dining platform that connects food enthusiasts
            with local hosts who open up their kitchens to offer one-of-a-kind
            dining experiences. Guests can enjoy delicious home-cooked meals
            while experiencing off-the-market cooking techniques and recipes.
          </div>
        </div>
        <div style={{ marginTop: 30 }}>
          <div style={{ fontWeight: 700 }}>Who can become a host on Tably?</div>
          <div style={{ marginTop: 15 }}>
            Anyone with a passion for cooking and a desire to share their
            culinary talents can become a host on Tably. Whether you're a home
            chef, culinary enthusiast, or professional cook, we welcome
            individuals from all backgrounds to join our community.
          </div>
        </div>
        <div style={{ marginTop: 30 }}>
          <div style={{ fontWeight: 700 }}>How does Tably work for guests?</div>
          <div style={{ marginTop: 15 }}>
            Guests can browse through a variety of dining experiences hosted by
            local chefs and food enthusiasts on the Tably platform. Once they
            find a dinner party that interests them, they can sign up to attend,
            pay the booking fee, and await confirmation from the host. On the
            day of the event, guests can enjoy a unique dining experience in the
            comfort of the host's home.
          </div>
        </div>
        <div style={{ marginTop: 30 }}>
          <div style={{ fontWeight: 700 }}>
            What types of dining experiences are available on Tably?
          </div>
          <div style={{ marginTop: 15 }}>
            Tably offers a diverse range of dining experiences, including
            traditional home-cooked meals, themed dinner parties, cooking
            classes, and more. Each host brings their own unique style and
            culinary expertise to the table, ensuring that there's something for
            everyone to enjoy.
          </div>
        </div>
        <div style={{ marginTop: 30 }}>
          <div style={{ fontWeight: 700 }}>
            How are hosts and guests matched on Tably?
          </div>
          <div style={{ marginTop: 15 }}>
            Hosts create listings for their dining experiences, including
            details such as the menu, date, time, and location. Guests can
            browse through these listings and choose the ones that appeal to
            them. Once a guest signs up for an event, the host has the
            opportunity to review their profile and confirm their attendance.
          </div>
        </div>
        <div style={{ marginTop: 30 }}>
          <div style={{ fontWeight: 700 }}>
            What is the cancellation policy for guests and hosts?
          </div>
          <div style={{ marginTop: 15 }}>
            Guests may cancel their attendance up to 48 hours before the
            scheduled event without penalty. Cancellations made within 48 hours
            will result in a non-refundable fee. Hosts are expected to honor
            their commitment to host confirmed events, but they may cancel up to
            48 hours in advance with no penalty. Cancellations made within 48
            hours may result in a non-refundable fee.
          </div>
        </div>
        <div style={{ marginTop: 30 }}>
          <div style={{ fontWeight: 700 }}>Is Tably available in my area?</div>
          <div style={{ marginTop: 15 }}>
            Tably is continuously expanding to new locations, but availability
            may vary depending on your area. We recommend checking the platform
            to see if there are any dining experiences available near you.
          </div>
        </div>
        <div style={{ marginTop: 30 }}>
          <div style={{ fontWeight: 700 }}>
            How do I become a host on Tably?
          </div>
          <div style={{ marginTop: 15 }}>
            To become a host on Tably, simply create an account on our platform,
            fill out your profile, and create a listing for your dining
            experience. Once your listing is live, guests can sign up to attend,
            and you can start sharing your culinary talents with the world.
          </div>
        </div>
        <div style={{ marginTop: 30 }}>
          <div style={{ fontWeight: 700 }}>
            Can hosts accommodate dietary restrictions or preferences?
          </div>
          <div style={{ marginTop: 15 }}>
            Yes, hosts have the option to specify dietary accommodations in
            their listings, such as vegetarian, vegan, gluten-free, and more.
            Guests can communicate their dietary needs during the booking
            process, and hosts will do their best to accommodate them.
          </div>
        </div>
        <div style={{ marginTop: 30 }}>
          <div style={{ fontWeight: 700 }}>
            What safety measures are in place for Tably events?
          </div>
          <div style={{ marginTop: 15 }}>
            Tably takes the safety and well-being of our users seriously. Hosts
            are required to adhere to local health and safety guidelines, and we
            encourage guests to follow any safety protocols recommended by
            public health authorities.
          </div>
        </div>
        <div style={{ marginTop: 30 }}>
          <div style={{ fontWeight: 700 }}>
            How are payments processed on Tably?
          </div>
          <div style={{ marginTop: 15 }}>
            Guests pay for their dining experiences through the Tably platform
            using secure payment methods. Hosts receive their earnings after the
            event has been successfully completed, minus any applicable fees.
          </div>
        </div>
        <div style={{ marginTop: 30 }}>
          <div style={{ fontWeight: 700 }}>
            What happens if there's a problem with my dining experience?
          </div>
          <div style={{ marginTop: 15 }}>
            If you encounter any issues during your dining experience, please
            contact us immediately. We strive to ensure that all interactions on
            Tably are positive and enjoyable, and we will do our best to address
            any concerns or resolve any disputes that may arise.
          </div>
        </div>
        <div style={{ marginTop: 30 }}>
          <div style={{ fontWeight: 700 }}>
            Can I leave reviews for hosts and guests?
          </div>
          <div style={{ marginTop: 15 }}>
            Yes, both hosts and guests have the opportunity to leave reviews and
            ratings for each other after the completion of a dining experience.
            These reviews help build trust within the Tably community and
            provide valuable feedback for future interactions.
          </div>
        </div>
        <div style={{ marginTop: 30 }}>
          <div style={{ fontWeight: 700 }}>
            Is there a screening process for hosts and guests?
          </div>
          <div style={{ marginTop: 15 }}>
            Tably verifies the identity of all users to ensure a safe and secure
            experience for everyone. Additionally, hosts and guests are
            encouraged to complete their profiles with relevant information to
            help facilitate positive interactions.
          </div>
        </div>
        <div style={{ marginTop: 30 }}>
          <div style={{ fontWeight: 700 }}>
            Can I host private events or special occasions on Tably?
          </div>
          <div style={{ marginTop: 15 }}>
            Yes, hosts have the option to create private dining experiences for
            special occasions such as birthdays, anniversaries, and corporate
            events. Simply indicate your preferences when creating your listing,
            and guests can request to book your event exclusively.
          </div>
        </div>
        <div style={{ marginTop: 30 }}>
          <div style={{ fontWeight: 700 }}>
            How does Tably promote diversity and inclusion?
          </div>
          <div style={{ marginTop: 15 }}>
            Tably is committed to promoting diversity and inclusion within our
            community. We welcome hosts and guests from all backgrounds,
            cultures, and identities, and we strive to create an environment
            where everyone feels valued and respected.
          </div>
        </div>
        <div style={{ marginTop: 30, fontWeight: 700 }}>
          For further questions, please email:
          <br />
          {getEmail()}
        </div>
      </div>
    </>
  );
}

export default FAQ;
