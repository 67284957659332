import React, { useContext, useEffect, useState } from "react";
import TextInput from "../inputs/TextInput";
import RoundButton from "../components/RoundButton";
import { fetchData } from "../api";
import { EventListItem } from "../interfaces";
import EventAvatarCard from "../components/EventAvatarCard";
import { colors } from "../hooks/color";
import { GlobalContext } from "../App";

function Search() {
  const [search, setSearch] = useState<string>("");
  const [key, setKey] = useState<string>("");
  const [eventItems, setEventItems] = useState<EventListItem[] | null>(null);
  const { userLocation } = useContext(GlobalContext);

  useEffect(() => {
    if (!userLocation) return;
    fetchData<EventListItem[]>(
      "GET",
      `/events/search?key=${key}&lat=${userLocation.latitude}&lng=${userLocation.longitude}`
    ).then(setEventItems);
  }, [key, userLocation]);

  if (!eventItems) return null;

  return (
    <div style={{ padding: 30 }}>
      <div style={{ display: "flex", alignItems: "center", columnGap: 10 }}>
        <div style={{ flex: 1 }}>
          <TextInput
            placeholder="Search for an event or menu"
            handleChange={setSearch}
            fullWidth
          />
        </div>
        <RoundButton
          handleClick={() => {
            setKey(search);
          }}
        >
          Search
        </RoundButton>
      </div>
      <div style={{ marginTop: 30 }}>
        {eventItems.map((eventItem, index) => (
          <EventAvatarCard
            eventId={eventItem.id}
            eventCode={eventItem.event_code}
            image={eventItem.image}
            eventType={eventItem.event_type}
            cuisine={eventItem.cuisine}
            address={eventItem.address}
            title={eventItem.title}
            infoColor={colors.orange}
            titleColor={colors.green}
            alcohol={eventItem.alcohol}
            key={index}
          />
        ))}
      </div>
    </div>
  );
}

export default Search;
