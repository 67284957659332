import { differenceInYears } from "date-fns";
import { abbrMonths, days, monthNames } from "../const";

export default class TimeUtil {
  static yearDiffFromNow(dateString: string) {
    const date = new Date(dateString);
    const now = new Date();
    // Calculate the difference in milliseconds
    const diff = now.getTime() - date.getTime();
    return Math.ceil(diff / (1000 * 60 * 60 * 24 * 365));
  }

  static formatDateToMonthYear(dateString: string) {
    const date = new Date(dateString);
    const month = date.toLocaleString("en-US", { month: "long" });
    const year = date.getFullYear();
    return `${month} ${year}`;
  }

  static formatDateToMonthsAgo(dateString: string) {
    const date = new Date(dateString);
    const currentDate = new Date();

    // Calculate the difference in months
    const yearDiff = currentDate.getFullYear() - date.getFullYear();
    const monthDiff = currentDate.getMonth() - date.getMonth();
    const totalMonthsAgo = yearDiff * 12 + monthDiff;

    // Construct the formatted string
    if (totalMonthsAgo === 0) {
      return "This month";
    } else if (totalMonthsAgo === 1) {
      return "1 Month Ago";
    }
    return `${totalMonthsAgo} Months Ago`;
  }

  static formatDayMonth(inputDate: string) {
    const date = new Date(inputDate);
    const dayOfWeek = date.getUTCDay();
    const dayOfMonth = date.getUTCDate();
    const monthName = abbrMonths[date.getUTCMonth()];
    const formattedDate = `${days[dayOfWeek]}, ${monthName} ${dayOfMonth}`;
    return formattedDate;
  }

  static formatMonthYearFromString(inputDate: string) {
    const date = new Date(inputDate);
    return TimeUtil.formatMonthYear(date);
  }

  static formatMonthYear(date: Date) {
    const monthName = abbrMonths[date.getMonth()];
    const formattedDate = `${monthName} ${date.getFullYear()}`;
    return formattedDate;
  }

  static formatDate(inputDate: string) {
    if (!inputDate) return "";
    const [year, month, day] = inputDate.split("-").map(Number);
    const formattedDate = `${monthNames[month - 1]} ${day}, ${year}`;
    return formattedDate;
  }

  static formatDateFromDateTime(dateTime: string) {
    const [year, month, day] = this.getLocalYearMonthDay(dateTime);
    return this.formatDate(this.formDateString(year, month, day));
  }

  static formDateString(year: number, month: number, day: number) {
    return `${year}-${month}-${day}`;
  }

  static getLocalYearMonthDay(dateTimeStr: string) {
    const dateObj = new Date(dateTimeStr);
    return [dateObj.getFullYear(), dateObj.getMonth() + 1, dateObj.getDate()];
  }

  static toLocaleDateString(dateString: string) {
    const [year, month, day] = dateString.split("-");
    return `${month}/${day}/${year}`;
  }

  static formatTimeFromISODateString(isoDateString: string) {
    const date = new Date(isoDateString + "Z");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const timeString = `${hours}:${minutes}`;
    return timeString;
  }

  static convertTo12HourFormat(time24: string) {
    const [hours, minutes] = time24.split(":").map(Number);
    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);
    const formattedTime = date.toLocaleString("en-US", {
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    });

    return formattedTime;
  }

  static calculateAge(birthday: string) {
    const age = differenceInYears(new Date(), new Date(birthday));
    return age;
  }

  static getNowDateString() {
    const currentDate = new Date();
    return TimeUtil.convertDateString(currentDate);
  }

  static convertDateString(date: Date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed, so we add 1
    const day = String(date.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }
}
