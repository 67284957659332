import CustomImage from "../CustomImage";
import { formatDate, formatPrice } from "../../util";
import { colors } from "../../hooks/color";
import { useCurrentMode } from "../../hooks/mode";
import DeleteIcon from "@mui/icons-material/Delete";
import { EventItem } from "../../interfaces";
import { EventStatus } from "../../enums";

interface EventCardProps {
  eventItem: EventItem;
  handleClick?: () => void;
  status?: boolean;
  handleDelete?: () => Promise<any>;
}

interface StatusCardProps {
  status: EventStatus;
}

export function StatusCard({ status }: StatusCardProps) {
  const currentMode = useCurrentMode();
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: 150,
        backgroundColor: (() => {
          if (status === EventStatus.progress) {
            return colors.darkyellow;
          }
          if (status === EventStatus.under_review) {
            return colors.lightorange;
          }
          if (status === EventStatus.ready) {
            return colors.darkgreen;
          }
          if (status === EventStatus.rejected) {
            return colors.red;
          }
          if (status === EventStatus.cancelled) {
            return colors.red;
          }
          return "";
        })(),
        height: 40,
      }}
    >
      {(() => {
        let label = <span></span>;
        if (status === EventStatus.progress) {
          label = <span>In progress</span>;
        }
        if (status === EventStatus.under_review) {
          label = <span>Under review</span>;
        }
        if (status === EventStatus.ready) {
          if (currentMode === "host") {
            label = <span>Publish</span>;
          } else {
            label = <span>Approved</span>;
          }
        }
        if (status === EventStatus.rejected) {
          label = <span style={{ color: "white" }}>Rejected</span>;
        }
        if (status === EventStatus.cancelled) {
          label = <span style={{ color: "white" }}>Cancelled</span>;
        }
        return label;
      })()}
    </div>
  );
}

function EventCard({
  eventItem,
  handleClick,
  status,
  handleDelete,
}: EventCardProps) {
  return (
    <div
      onClick={handleClick}
      style={{
        cursor: handleClick ? "pointer" : "default",
      }}
    >
      <div style={{ position: "relative" }}>
        <CustomImage
          width="100%"
          height={170}
          style={{
            borderRadius: 15,
          }}
          src={eventItem.image}
        />

        {status && (
          <div style={{ position: "absolute", top: 10, right: 10 }}>
            <StatusCard status={eventItem.status} />
          </div>
        )}
        {handleDelete && (
          <div
            style={{
              position: "absolute",
              bottom: 10,
              right: 10,
              cursor: "pointer",
              zIndex: 1,
            }}
            onClick={(e) => {
              e.stopPropagation();
              handleDelete();
            }}
          >
            <DeleteIcon />
          </div>
        )}
      </div>
      <div style={{ marginTop: 5 }}>Hosted by {eventItem.hostname}</div>
      <div style={{ marginTop: 5 }}>{eventItem.address}</div>
      {eventItem.date && (
        <div style={{ marginTop: 5 }}>{formatDate(eventItem.date)}</div>
      )}
      {Boolean(eventItem.price) && (
        <div style={{ marginTop: 5 }}>{formatPrice(eventItem.price)}</div>
      )}
      {Boolean(eventItem.capacity) && (
        <div style={{ marginTop: 5 }}>
          {eventItem.reserved}/{eventItem.capacity} seats filled
        </div>
      )}
    </div>
  );
}

export default EventCard;
