import React, { ReactNode } from "react";
import { getEnvironment } from "../util";
import { Provider, ErrorBoundary } from "@rollbar/react";

interface RollbarProps {
  children?: ReactNode;
}

const rollbarConfig = {
  accessToken: "85a8d5dd975a45c299cc5972f78311c5",
  environment: getEnvironment(),
  captureUncaught: true,
  captureUnhandledRejections: true,
};

function Rollbar({ children }: RollbarProps) {
  return (
    <Provider config={rollbarConfig}>
      <ErrorBoundary>{children}</ErrorBoundary>
    </Provider>
  );
}

export default Rollbar;
