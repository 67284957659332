import React, { ReactNode } from "react";
import { useNavigate } from "react-router";
import CustomImage from "./CustomImage";
import UnsignedDishPng from "../pngs/unsigned_dish.png";
import { fontFamily } from "../hooks/font";
import RoundButton from "./RoundButton";

interface UnsignedViewProps {
  label: ReactNode;
  redirect: string;
}

function UnsignedView({ label, redirect }: UnsignedViewProps) {
  const navigate = useNavigate();
  return (
    <div
      style={{
        height: "100%",
        position: "relative",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{ position: "absolute", inset: 0 }}>
        <CustomImage
          src={UnsignedDishPng}
          style={{ width: "100%", height: "100%", backgroundPosition: "top" }}
        />
      </div>
      <div
        style={{
          position: "relative",
          zIndex: 1,
          fontFamily: fontFamily.simula,
          fontSize: 24,
          textAlign: "center",
        }}
      >
        {label}
        <div style={{ marginTop: 30 }}>
          <RoundButton
            handleClick={() =>
              navigate("/sign", {
                state: {
                  redirect,
                },
              })
            }
          >
            Sign In
          </RoundButton>
        </div>
      </div>
    </div>
  );
}

export default UnsignedView;
