import { Checkbox } from "@mui/material";
import React from "react";

interface CustomCheckboxProps {
  label: string;
  checked?: boolean;
  handleCheck?: (checked: boolean) => void;
  left?: boolean;
}

function CustomCheckbox({
  label,
  checked,
  handleCheck,
  left,
}: CustomCheckboxProps) {
  return (
    <div
      style={{
        display: "flex",
        columnGap: 10,
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      {left && (
        <div>
          <Checkbox
            checked={checked}
            onChange={(e, checked) => handleCheck && handleCheck(checked)}
            style={{ padding: 0 }}
          />
        </div>
      )}
      <div>{label}</div>
      {!left && (
        <div>
          <Checkbox
            checked={checked}
            onChange={(e, checked) => handleCheck && handleCheck(checked)}
            style={{ padding: 0 }}
          />
        </div>
      )}
    </div>
  );
}

export default CustomCheckbox;
