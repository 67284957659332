import React, { ReactNode } from "react";
import { colors } from "../hooks/color";

interface YellowComponentProps {
  children?: ReactNode;
}

function YellowComponent({ children }: YellowComponentProps) {
  return (
    <div
      style={{ backgroundColor: colors.yellow, borderRadius: 20, padding: 20 }}
    >
      {children}
    </div>
  );
}

export default YellowComponent;
