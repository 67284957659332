import { Outlet } from "react-router-dom";
import { useUser } from "../hooks/user";

function AdminHome() {
  const user = useUser();

  if (!user) return null;
  if (!user.admin) return null;

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <div style={{ flex: 1, overflow: "auto" }}>
        <Outlet />
      </div>
    </div>
  );
}

export default AdminHome;
