import React, { ReactNode } from "react";
import Header from "./Header";
import CheckIcon from "@mui/icons-material/Check";
import CustomButton from "../inputs/CustomButton";

interface SuccessProps {
  headerLabel?: string;
  label: ReactNode;
  buttonLabel: string;
  handleClick: () => void;
}

function Success({
  headerLabel,
  label,
  buttonLabel,
  handleClick,
}: SuccessProps) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      {headerLabel && <Header label={headerLabel} />}
      <div
        style={{
          padding: 30,
          flex: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div
          style={{
            paddingTop: 130,
          }}
        >
          <div
            style={{
              width: 75,
              height: 75,
              backgroundColor: "#00FF57",
              borderRadius: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CheckIcon
              style={{ color: "white", width: "50%", height: "50%" }}
            />
          </div>
        </div>
        <div style={{ marginTop: 40, textAlign: "center" }}>{label}</div>
        <div style={{ marginTop: "auto", width: "100%" }}>
          <CustomButton fullWidth handleClick={handleClick}>
            {buttonLabel}
          </CustomButton>
        </div>
      </div>
    </div>
  );
}

export default Success;
