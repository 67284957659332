import React, { Fragment, useContext, useState } from "react";
import { useNavigate } from "react-router";
import Header from "../../components/Header";
import TextInput from "../../inputs/TextInput";
import RoundButton from "../../components/RoundButton";
import { colors } from "../../hooks/color";
import Avatar from "../../components/Avatar";
import Horizontal from "../../components/Horizontal";
import TimeUtil from "../../utils/time";
import UserProfile from "../../components/UserProfile";
import { fetchData } from "../../api";
import { GlobalContext } from "../../App";
import { useHosts } from "../../hooks/host";
import { User } from "../../interfaces";
import { HostStatus, UserMode } from "../../enums";
import CustomDialog from "../../components/CustomDialog";
import SelectInput from "../../inputs/SelectInput";
import { hostRejectTypes } from "../../const";
import ToggleLabel from "../../components/ToggleLabel";

interface RejectButtonProps {
  handleReject: (reject: string, rejectDetail: string) => void;
}

function RejectButton({ handleReject }: RejectButtonProps) {
  const [open, setOpen] = useState<boolean>(false);
  const [reject, setReject] = useState<string>("");
  const [rejectDetail, setRejectDetail] = useState<string>("");

  const disabledCondition = !(
    Boolean(reject) &&
    (reject !== "Other" || Boolean(rejectDetail))
  );

  return (
    <>
      <RoundButton
        handleClick={() => setOpen(true)}
        backgroundColor={colors.red}
      >
        Reject
      </RoundButton>
      <CustomDialog open={open} handleClose={() => setOpen(false)}>
        <div style={{ fontSize: 24, fontWeight: 600, textAlign: "center" }}>
          Reject Host
        </div>
        <div style={{ marginTop: 30, fontSize: 24, fontWeight: 600 }}>
          Are you sure you want to reject this host?
        </div>
        <div style={{ marginTop: 30 }}>
          <SelectInput
            items={hostRejectTypes.map((item) => ({
              label: item,
              value: item,
            }))}
            value={reject}
            fullWidth
            label="Select Your Reason"
            handleChange={setReject}
          />
        </div>
        {reject === "Other" && (
          <div style={{ marginTop: 30 }}>
            <TextInput
              label="Details"
              fullWidth
              handleChange={setRejectDetail}
              multiline
              rows={5}
              defaultValue={rejectDetail}
            />
          </div>
        )}
        <div style={{ marginTop: 30, textAlign: "center" }}>
          <RoundButton
            handleClick={() => handleReject(reject, rejectDetail)}
            disabled={disabledCondition}
          >
            Reject
          </RoundButton>
        </div>
      </CustomDialog>
    </>
  );
}

interface CurrentUserComponentProps {
  handleBack: () => void;
  hostUser: User;
  refresh: () => void;
}

function CurrentHostComponent({
  handleBack,
  hostUser,
  refresh,
}: CurrentUserComponentProps) {
  const { user: self, refreshUser } = useContext(GlobalContext);
  const [loaded, setLoaded] = useState<boolean>(false);
  type ActionType = "approve" | "reject" | "unblock" | "block";

  const handleAction = (actionType: ActionType, body: object = {}) => {
    if (!hostUser.host || !self) return;
    return fetchData("PATCH", `/hosts/${hostUser.host.id}/status`, {
      ...body,
      status: actionType,
    })
      .then(refresh)
      .then(() => {
        if (self.id === hostUser.id) {
          return refreshUser();
        }
        return null;
      })
      .then(handleBack);
  };

  if (!hostUser.host) return null;

  return (
    <>
      <Header handleBack={handleBack} />
      <div style={{ padding: 30, fontSize: 14 }}>
        <UserProfile
          userId={hostUser.id}
          visualize={{
            avatar: true,
            message: true,
            hostSummary: true,
            name: true,
            email: true,
            phone: true,
            address: true,
            birthday: true,
            restriction: true,
            experienceInPartySize: true,
            languages: true,
            identity: true,
            payoutAccounts: true,
            aboutMe: true,
            totalEarned: true,
            hostedEvents: true,
            hostReviews: true,
          }}
          userMode={UserMode.host}
          afterLoadCallback={() => {
            setLoaded(true);
          }}
        />
        {loaded && (
          <div
            style={{
              marginTop: 30,
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            {hostUser.host.meta.status === "approved" && (
              <RoundButton
                backgroundColor={colors.red}
                handleClick={() => handleAction("block")}
              >
                Block
              </RoundButton>
            )}
            {hostUser.host.meta.status === "pending" && (
              <>
                <RoundButton
                  backgroundColor={colors.blue}
                  handleClick={() => handleAction("approve")}
                >
                  Approve
                </RoundButton>
                <RejectButton
                  handleReject={(reject, rejectDetail) =>
                    handleAction("reject", {
                      reject,
                      reject_detail: rejectDetail,
                    })
                  }
                />
              </>
            )}
            {hostUser.host.meta.status === "blocked" && (
              <>
                <RoundButton
                  backgroundColor={colors.blue}
                  handleClick={() => handleAction("unblock")}
                >
                  Unblock
                </RoundButton>
                <RoundButton
                  backgroundColor={colors.red}
                  handleClick={() => handleAction("reject")}
                >
                  Remove
                </RoundButton>
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
}

interface FilteredComponentProps {
  hostUsers: User[];
  setCurrentHost: (user: User) => void;
}

function FilteredComponent({
  hostUsers,
  setCurrentHost,
}: FilteredComponentProps) {
  return (
    <>
      {hostUsers.map((user, index) => (
        <Fragment key={index}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              columnGap: 15,
              marginTop: 30,
              cursor: "pointer",
            }}
            onClick={() => setCurrentHost(user)}
          >
            <Avatar width={92} height={92} src={user.meta.image} />
            <div style={{ fontSize: 14 }}>
              <div
                style={{
                  fontSize: 25,
                  fontWeight: 600,
                }}
              >
                {user.meta.firstname}
              </div>
              <div style={{ marginTop: 10 }}>
                {user.host?.meta.rating.toFixed(1) ?? 0}★
              </div>
              {user.host?.meta.approved_at && (
                <div style={{ marginTop: 10 }}>
                  Host Since:{" "}
                  {TimeUtil.formatDateFromDateTime(user.host?.meta.approved_at)}
                </div>
              )}
              {!user.host?.meta.approved_at && (
                <div style={{ marginTop: 10 }}>
                  Requested:{" "}
                  {TimeUtil.formatDateFromDateTime(user.host?.created_at ?? "")}
                </div>
              )}
              {user.host?.meta.blocked_at && (
                <div style={{ marginTop: 10 }}>
                  Blocked:{" "}
                  {TimeUtil.formatDateFromDateTime(
                    user.host?.meta.blocked_at ?? ""
                  )}
                </div>
              )}
            </div>
          </div>
          <Horizontal marginTop={30} opacity={0.3} />
        </Fragment>
      ))}
    </>
  );
}

interface FilterComponentProps {
  filter: string;
  setFilter: (filter: string) => void;
}

function FilterComponent({ filter, setFilter }: FilterComponentProps) {
  return (
    <TextInput label="Filter" defaultValue={filter} handleChange={setFilter} />
  );
}

function AdminHosts() {
  const navigate = useNavigate();
  const [filter, setFilter] = useState<string>("");
  const [type, setType] = useState<string>("all");
  const [currentHost, setCurrentHost] = useState<User | null>(null);
  const { hostUsers, refresh } = useHosts();
  if (!hostUsers) return null;
  const filtered = hostUsers.filter((hostUser) =>
    hostUser.meta.firstname.includes(filter)
  );
  if (currentHost) {
    return (
      <CurrentHostComponent
        handleBack={() => setCurrentHost(null)}
        hostUser={currentHost}
        refresh={refresh}
      />
    );
  }

  const label = (labelType: string) => {
    if (labelType === "all") {
      return "All Hosts";
    }
    if (labelType === HostStatus.approved) {
      return "Approved Hosts";
    }
    if (labelType === HostStatus.pending) {
      return "Pending Hosts";
    }
    if (labelType === HostStatus.blocked) {
      return "Blocked Hosts";
    }
    return "";
  };

  const refinedHostUsers = () => {
    if (type === "all") {
      return filtered;
    }
    return filtered.filter((user) => user.host?.meta.status === type);
  };

  return (
    <>
      <Header label="Hosts" handleBack={() => navigate(-1)} />
      <ToggleLabel
        labelTypes={[
          "all",
          HostStatus.approved,
          HostStatus.pending,
          HostStatus.blocked,
        ]}
        labelType={type}
        labelFunc={label}
        handleLabelType={setType}
      />
      <div style={{ padding: 30, fontSize: 14 }}>
        <div>
          <FilterComponent filter={filter} setFilter={setFilter} />
        </div>
        <div style={{ marginTop: 30 }}>
          <FilteredComponent
            hostUsers={refinedHostUsers()}
            setCurrentHost={setCurrentHost}
          />
        </div>
      </div>
    </>
  );
}

export default AdminHosts;
