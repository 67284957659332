import { Fade } from "@mui/material";
import { colors } from "../hooks/color";
import BigLogo from "./logos/BigLogo";
import { useEffect, useState } from "react";

interface LoadingProps {
  loadingIn: boolean;
  handleOut?: () => void;
  manualOut?: boolean;
  out?: boolean;
}

function Loading({ loadingIn, handleOut, manualOut, out }: LoadingProps) {
  const [inState, setInState] = useState<boolean>(loadingIn);

  useEffect(() => {
    if (!manualOut) {
      setTimeout(() => setInState(false), 500);
    } else {
      if (out) {
        setInState(false);
      }
    }
  }, [out, manualOut]);

  useEffect(() => {
    if (!inState && handleOut) {
      setTimeout(handleOut, 100);
    }
  }, [handleOut, inState]);

  return (
    <Fade in={inState} timeout={250}>
      <div
        style={{
          backgroundColor: colors.green,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
        }}
      >
        <BigLogo yellow />
      </div>
    </Fade>
  );
}

export default Loading;
