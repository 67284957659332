import React from "react";

interface BulletsProps {
  active: number;
  total: number;
  color: string;
  handleClick?: (index: number) => void;
}

function Bullets({ active, total, color, handleClick }: BulletsProps) {
  return (
    <div
      style={{
        display: "inline-flex",
        columnGap: 5,
      }}
    >
      {(() => {
        const res = [];
        for (let i = 0; i < total; i++) {
          res.push(
            <div
              key={i}
              style={{
                width: 8,
                height: 8,
                borderRadius: "50%",
                backgroundColor: color,
                opacity: i === active ? 1 : 0.5,
                cursor: "pointer",
              }}
              onClick={() => handleClick && handleClick(i)}
            ></div>
          );
        }
        return res;
      })()}
    </div>
  );
}

export default Bullets;
