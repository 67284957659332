import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Horizontal from "../components/Horizontal";
import { ReactComponent as HomeSvg } from "../svgs/home.svg";
import { ReactComponent as SearchSvg } from "../svgs/search.svg";
import { ReactComponent as BowlSvg } from "../svgs/bowl.svg";
import { ReactComponent as MessageSvg } from "../svgs/message.svg";
import { ReactComponent as ProfileSvg } from "../svgs/profile.svg";
import { useColor } from "../hooks/color";
import { SvgType } from "../type";
import { UserMode } from "../enums";
import { useCurrentMode } from "../hooks/mode";

interface NavigationProps {
  icon: SvgType;
  link: string;
  modes: UserMode[];
}

const navigations = [
  {
    icon: HomeSvg,
    link: "/event",
    modes: [UserMode.unsigned, UserMode.guest],
  },
  {
    icon: SearchSvg,
    link: "/search",
    modes: [UserMode.unsigned, UserMode.guest],
  },
  {
    icon: BowlSvg,
    link: "/upcoming",
    modes: [UserMode.unsigned, UserMode.guest, UserMode.host],
  },
  {
    icon: MessageSvg,
    link: "/message",
    modes: [UserMode.unsigned, UserMode.guest, UserMode.host, UserMode.admin],
  },
  {
    icon: ProfileSvg,
    link: "/profile",
    modes: [UserMode.unsigned, UserMode.guest, UserMode.host, UserMode.admin],
  },
] as NavigationProps[];

function Home() {
  const navigate = useNavigate();
  const location = useLocation();
  const color = useColor();
  const currentMode = useCurrentMode();

  const filtered = navigations.filter((navigation) =>
    navigation.modes.includes(currentMode)
  );

  const value = filtered.findIndex(
    (navigation) => navigation.link === location.pathname
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <div style={{ flex: 1, height: "100%", overflow: "auto" }}>
        <Outlet />
      </div>
      <div
        style={{
          zIndex: 1,
        }}
      >
        <Horizontal mode />
        <div
          style={{
            padding: 15,
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          {filtered.map((navigation, index) => (
            <navigation.icon
              onClick={() => navigate(navigation.link)}
              style={{
                cursor: "pointer",
                width: 30,
                height: 30,
                fill: value === index ? color : "none",
                stroke: color,
              }}
              key={index}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Home;
