export default class PhoneUtil {
  static formatPhoneNumber(phoneNumber: string): string {
    // Use regex to capture country code, area code, and the rest of the phone number
    const regex = /^\+(\d{1})(\d{3})(\d{3})(\d{4})$/;
    const matches = phoneNumber.match(regex);

    // If the phone number format doesn't match, return the original input
    if (!matches) {
      return phoneNumber;
    }

    // Extract the captured groups
    const countryCode = matches[1];
    const areaCode = matches[2];
    const firstPart = matches[3];
    const secondPart = matches[4];

    // Format the phone number with the desired format
    return `+${countryCode} ${areaCode}-${firstPart}-${secondPart}`;
  }
}
