import { Switch } from "@mui/material";
import React from "react";
import { colors } from "../hooks/color";

interface CustomSwitchProps {
  checked?: boolean;
  handleChange?: (checked: boolean) => void;
  color?: string;
}

function CustomSwitch({
  checked,
  handleChange,
  color = colors.ivory,
}: CustomSwitchProps) {
  return (
    <Switch
      checked={checked}
      onChange={(e) => {
        if (!handleChange) return;
        handleChange(e.target.checked);
      }}
      sx={{
        "& .Mui-checked": {
          color: `${color} !important`,
        },
        "& .MuiSwitch-track": {
          backgroundColor: `${color} !important`,
        },
      }}
    />
  );
}

export default CustomSwitch;
