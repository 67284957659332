import React from "react";
import { colors } from "../hooks/color";

interface ImageProps {
  src?: string;
  width?: string | number;
  height?: string | number;
  style?: React.CSSProperties;
  handleClick?: () => void;
}

function CustomImage({ src, width, height, style, handleClick }: ImageProps) {
  return (
    <div
      onClick={handleClick}
      style={{
        backgroundColor: src ? "transparent" : colors.blank,
        backgroundImage: `url(${src})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        width,
        height,
        ...style,
      }}
    ></div>
  );
}

export default CustomImage;
