import React, { useState } from "react";
import { colors } from "../hooks/color";
import { UserInfoComponent } from "./UserProfile";
import CustomDialog from "./CustomDialog";
import Horizontal from "./Horizontal";

interface ReviewCardProps {
  src: string;
  firstname: string;
  created_at: string;
  rating: number;
  review: string;
}

function ReviewCard({
  src,
  firstname,
  created_at,
  rating,
  review,
}: ReviewCardProps) {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      <div
        style={{
          padding: 15,
          border: `1px solid ${colors.green}`,
          borderRadius: 20,
          width: 300,
          height: 200,
          cursor: "pointer",
        }}
        onClick={() => setOpen(true)}
      >
        <UserInfoComponent
          src={src}
          firstname={firstname}
          created_at={created_at}
          rating={rating}
        />
        <Horizontal marginTop={30} marginBottom={30} />
        <div
          style={{
            display: "-webkit-box",
            WebkitLineClamp: 5,
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
          }}
        >
          {review}
        </div>
      </div>
      <CustomDialog open={open} handleClose={() => setOpen(false)}>
        <div style={{ maxHeight: 500 }}>
          <UserInfoComponent
            src={src}
            firstname={firstname}
            created_at={created_at}
            rating={rating}
          />
          <Horizontal marginTop={30} marginBottom={30} />
          <div style={{ overflow: "auto" }}>{review}</div>
        </div>
      </CustomDialog>
    </>
  );
}

export default ReviewCard;
