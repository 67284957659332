import { getEnvironment } from "./util";

export default class Config {
  static getPaypalClientId() {
    if (getEnvironment() === "production") {
      return "AdQVCf8Sef21fVRZeehTiZg0h00ruXRK9HT21djIhhriIuj-uM1TudWkv8v_rs2xX8AzMGggiM3ByNrG";
    }
    return "AQExy5zaOa8znRTvgtkPq7HRH_sIwr4lDCTUUEEWKtg09v3S7BidCUJWhF_jswxqX2NlMBqfH3DHkWxj";
  }

  static getGoogleMapsApiKey() {
    return "AIzaSyDLg6Jh48sE4rJFh00ISccbtxrdYkvGvx0";
  }

  static getIpgeolocationApiKey() {
    return "7002ed3ebd94402184587ffbbe247ce7";
  }
}
