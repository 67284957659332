import React, {
  Fragment,
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useUser } from "../../hooks/user";
import { useCurrentMode } from "../../hooks/mode";
import { useEventDetail, useEventHooks } from "../../hooks/event";
import Backward from "../../components/Backward";
import CustomSwiper from "../../components/CustomSwiper";
import CustomImage from "../../components/CustomImage";
import Avatar from "../../components/Avatar";
import { calculateMonthsElapsed, formatNumberDigits } from "../../util";
import StarIcon from "@mui/icons-material/Star";
import Horizontal from "../../components/Horizontal";
import { colors } from "../../hooks/color";
import CustomDrawer from "../../components/CustomDrawer";
import TextInput from "../../inputs/TextInput";
import SelectInput from "../../inputs/SelectInput";
import { cancelTypes, guestCancelTypes, rejectTypes } from "../../const";
import { ReactComponent as BellSvg } from "../../svgs/bell.svg";
import { ReactComponent as CheckSvg } from "../../svgs/check.svg";
import { ReactComponent as ProfileSvg } from "../../svgs/profile.svg";
import { ReactComponent as CalendarSvg } from "../../svgs/calendar.svg";
import { ReactComponent as LocationSvg } from "../../svgs/location.svg";
import { ReactComponent as DrinkSvg } from "../../svgs/drink.svg";
import { ReactComponent as PrivateSvg } from "../../svgs/private.svg";
import { ReactComponent as PencilSvg } from "../../svgs/pencil.svg";
import { ReactComponent as ReceiptSvg } from "../../svgs/receipt.svg";
import { ReactComponent as OrderConfirmationSvg } from "../../svgs/order_confirmation.svg";
import { ReactComponent as ShareSvg } from "../../svgs/share.svg";
import { fontFamily } from "../../hooks/font";
import RoundButton from "../../components/RoundButton";
import CustomDialog from "../../components/CustomDialog";
import { EventIntroLabel } from "../../utils/event";
import CustomCheckbox from "../../inputs/CustomCheckbox";
import YellowComponent from "../../components/YellowComponent";
import { ReactComponent as WarningSvg } from "../../svgs/warning.svg";
import { ReactComponent as AddSvg } from "../../svgs/add.svg";
import { ReactComponent as SubtractSvg } from "../../svgs/subtract.svg";
import { ReactComponent as TablySvg } from "../../svgs/tably.svg";
import UserProfile from "../../components/UserProfile";
import { EventDetail, EventMenuDetail, HostReview } from "../../interfaces";
import { fetchData } from "../../api";
import TimeUtil from "../../utils/time";
import ReadMore from "../../components/ReadMore";
import {
  EventDetailMode,
  EventDisclosure,
  EventPaymentType,
  EventStatus,
  UserMode,
  YesNo,
} from "../../enums";
import { Circle, GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import Config from "../../config";
import AccordionView from "../../components/AccordionView";
import { SwiperClass } from "swiper/react";
import { useHostReview } from "../../hooks/review";
import { PayPalButtons } from "@paypal/react-paypal-js";
import ReviewCard from "../../components/ReviewCard";
import UserAvatar from "../../components/UserAvatar";
import { Helmet } from "react-helmet";
import { TermsAndConditionContent } from "../profiles/TermsAndCondition";
import EventInfo from "./EventInfo";
import VerificationWrapper from "../../components/VerificationWrapper";

function TermsAndConditionsDialogButton() {
  const [termsAndCondition, setTermsAndCondition] = useState<boolean>(false);
  return (
    <>
      <span
        style={{ textDecoration: "underline", cursor: "pointer" }}
        onClick={() => setTermsAndCondition(true)}
      >
        Terms and Conditions
      </span>
      <CustomDialog
        open={termsAndCondition}
        handleClose={() => setTermsAndCondition(false)}
      >
        <div style={{ marginTop: 30, fontSize: 24, fontWeight: 600 }}>
          Terms and Conditions
        </div>
        <div style={{ marginTop: 30, fontSize: 14 }}>
          <TermsAndConditionContent />
        </div>
      </CustomDialog>
    </>
  );
}

// function HostAgainEvent() {
//   const [open, setOpen] = useState<boolean>(false);
//   const { eventDetail } = useContext(EventDetailContext);
//   const [date, setDate] = useState<string>("");
//   const [startTime, setStartTime] = useState<string>("");
//   const [endTime, setEndTime] = useState<string>("");
//   const navigate = useNavigate();

//   const handleRepublish = () => {
//     fetchData<{ id: number }>("POST", `/events/${eventDetail.id}/republish`, {
//       date,
//       startTime,
//       endTime,
//     }).then((id) => navigate(`/events/${id}`));
//   };

//   const disabled = !(date && startTime && endTime);

//   return (
//     <>
//       <RoundButton handleClick={() => setOpen(true)}>
//         Host This Event Again
//       </RoundButton>
//       <CustomDialog open={open} handleClose={() => setOpen(false)}>
//         <div
//           style={{
//             fontWeight: 600,
//             fontSize: 24,
//             textAlign: "center",
//           }}
//         >
//           Change
//           <br />
//           Date and Time
//         </div>
//         <div
//           style={{
//             marginTop: 30,
//             fontSize: 14,
//             fontWeight: 600,
//           }}
//         >
//           Current Date and Time
//         </div>
//         <div
//           style={{
//             marginTop: 15,
//           }}
//         >
//           <YellowComponent>
//             <div style={{ display: "flex", alignItems: "center" }}>
//               <CalendarSvg
//                 style={{
//                   width: 20,
//                 }}
//                 fill={colors.green}
//               />
//               <div style={{ marginLeft: 10, fontSize: 16 }}>
//                 {TimeUtil.formatDayMonth(eventDetail.date)},{" "}
//                 {TimeUtil.convertTo12HourFormat(eventDetail.start_time)} -{" "}
//                 {TimeUtil.convertTo12HourFormat(eventDetail.end_time)}
//               </div>
//             </div>
//           </YellowComponent>
//         </div>
//         <Horizontal marginTop={30} />
//         <div
//           style={{
//             marginTop: 30,
//           }}
//         >
//           <TextInput
//             type="date"
//             label="New Date"
//             handleChange={(date) => {
//               const current = TimeUtil.getNowDateString();
//               if (date < current) return;
//               setDate(date);
//             }}
//             value={date}
//             inputProps={{
//               min: TimeUtil.getNowDateString(),
//             }}
//           />
//         </div>
//         <div
//           style={{
//             marginTop: 30,
//             display: "flex",
//             columnGap: 10,
//           }}
//         >
//           <div style={{ flex: 1 }}>
//             <TextInput
//               type="time"
//               label="Start Time"
//               handleChange={setStartTime}
//               value={startTime}
//               fullWidth
//             />
//           </div>
//           <div style={{ flex: 1 }}>
//             <TextInput
//               type="time"
//               label="End Time"
//               handleChange={setEndTime}
//               value={endTime}
//               fullWidth
//             />
//           </div>
//         </div>
//         <div
//           style={{
//             marginTop: 30,
//             textAlign: "center",
//           }}
//         >
//           This event has already been approved!
//           <br />
//           Once published, your event will be public.
//         </div>
//         <div
//           style={{
//             marginTop: 30,
//             textAlign: "center",
//           }}
//         >
//           <RoundButton handleClick={handleRepublish} disabled={disabled}>
//             Publish Event
//           </RoundButton>
//         </div>
//       </CustomDialog>
//     </>
//   );
// }

function TotalCostComponent() {
  const { eventDetail } = useContext(EventDetailContext);
  if (
    !(
      eventDetail.status === EventStatus.closed &&
      eventDetail.mode === EventDetailMode.reserved &&
      eventDetail.self_reserved > 0
    )
  )
    return null;
  return (
    <>
      <Horizontal marginTop={30} marginBottom={30} />
      <div style={{ display: "flex", columnGap: 20 }}>
        <ReceiptSvg style={{ alignSelf: "flex-start", width: 50 }} />
        <div style={{ fontWeight: 600, fontSize: 16 }}>
          <div>Total Cost</div>
          <div style={{ marginTop: 5 }}>
            ${(eventDetail.price * eventDetail.self_reserved).toLocaleString()}{" "}
            ($
            {eventDetail.price.toLocaleString()} x {eventDetail.self_reserved}{" "}
            Seat)
          </div>
        </div>
      </div>
    </>
  );
}

interface ReviewComponentProps {
  open: boolean;
  handleClose: () => void;
  reviews: HostReview[];
}

function ReviewComponent({ open, handleClose, reviews }: ReviewComponentProps) {
  return (
    <CustomDialog open={open} handleClose={handleClose}>
      <div
        style={{
          marginTop: 30,
          display: "flex",
          flexDirection: "column",
          rowGap: 15,
        }}
      >
        {reviews.map((review, index) => (
          <ReviewCard
            key={index}
            src={review.image}
            firstname={review.firstname}
            created_at={review.created_at}
            rating={review.rating}
            review={review.review}
          />
        ))}
      </div>
    </CustomDialog>
  );
}

function EventRatingComponent() {
  const { eventDetail } = useContext(EventDetailContext);
  const [myReview, setMyReview] = useState<boolean>(false);
  const [reviews, setReviews] = useState<boolean>(false);
  if (!(eventDetail.status === EventStatus.closed)) return null;
  if (
    eventDetail.mode === EventDetailMode.reserved &&
    !Boolean(eventDetail.self_rating)
  )
    return null;

  return (
    <>
      <Horizontal marginTop={30} marginBottom={30} />
      <div style={{ display: "flex", columnGap: 20 }}>
        <PencilSvg style={{ alignSelf: "flex-start" }} />
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            rowGap: 15,
          }}
        >
          {eventDetail.mode === EventDetailMode.reserved &&
            Boolean(eventDetail.self_rating) &&
            eventDetail.self_rating &&
            eventDetail.self_rating > 0 && (
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ fontWeight: 600 }}>
                  <div style={{ fontSize: 16 }}>My Review</div>
                  <div style={{ marginTop: 5, fontSize: 24 }}>
                    {eventDetail.self_rating.toFixed(1)} ★
                  </div>
                </div>
                <div
                  style={{
                    marginLeft: "auto",
                    textDecoration: "underline",
                    cursor: "pointer",
                    fontWeight: 600,
                    fontSize: 14,
                  }}
                  onClick={() => setMyReview(true)}
                >
                  Read My Review
                </div>
              </div>
            )}
          {Boolean(eventDetail.event_rating) &&
            eventDetail.event_rating &&
            eventDetail.event_rating > 0 && (
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ fontWeight: 600 }}>
                  <div style={{ fontSize: 16 }}>Event Rating</div>
                  <div style={{ marginTop: 5, fontSize: 24 }}>
                    {eventDetail.event_rating.toFixed(1)} ★
                  </div>
                </div>
                <div
                  style={{
                    marginLeft: "auto",
                    textDecoration: "underline",
                    cursor: "pointer",
                    fontWeight: 600,
                    fontSize: 14,
                  }}
                  onClick={() => setReviews(true)}
                >
                  Read Review
                </div>
              </div>
            )}
        </div>
      </div>
      <ReviewComponent
        open={myReview}
        handleClose={() => setMyReview(false)}
        reviews={eventDetail.self_review ? [eventDetail.self_review] : []}
      />
      <ReviewComponent
        open={reviews}
        handleClose={() => setReviews(false)}
        reviews={eventDetail.reviews ?? []}
      />
    </>
  );
}

function WaitComponent() {
  const userMode = useCurrentMode();
  const location = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);
  const [confirmed, setConfirmed] = useState<boolean>(false);
  const { eventDetail } = useContext(EventDetailContext);

  const notify = () => {
    if (userMode === UserMode.unsigned) return;
    return fetchData("POST", `/events/${eventDetail.id}/wait`).then(() => {
      setOpen(false);
      setConfirmed(true);
    });
  };

  return (
    <>
      <RoundButton
        backgroundColor={colors.orange}
        handleClick={() => {
          // if user is not signed in, then go to sign in
          if (userMode === UserMode.unsigned) {
            return navigate("/sign", {
              state: {
                redirect: location.pathname,
              },
            });
          }
          setOpen(true);
        }}
      >
        <div
          style={{
            display: "flex",
            columnGap: 5,
            alignItems: "center",
          }}
        >
          <BellSvg />
          Notify
        </div>
      </RoundButton>
      <CustomDialog open={open} handleClose={() => setOpen(false)}>
        <div style={{ fontSize: 24, fontFamily: fontFamily.simula }}>
          Want to wait for seats?
        </div>
        <div style={{ marginTop: 30, textAlign: "center" }}>
          <RoundButton handleClick={notify}>Notify</RoundButton>
        </div>
      </CustomDialog>
      <CustomDialog open={confirmed} handleClose={() => setConfirmed(false)}>
        <div style={{ fontSize: 24, fontFamily: fontFamily.simula }}>
          We’ll send you an email when a seat opens up for this event!
        </div>
      </CustomDialog>
    </>
  );
}

function AgeRestrictionComponent() {
  const { eventDetail } = useContext(EventDetailContext);
  const [restrict, setRestrict] = useState<boolean | null>(null);
  const user = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      eventDetail.alcohol === YesNo.yes &&
      (!user || TimeUtil.calculateAge(user.meta.birthday) < 21)
    ) {
      return setRestrict(true);
    }
    return setRestrict(false);
  }, [user, eventDetail]);

  if (restrict === null) return null;

  return (
    <CustomDialog open={restrict}>
      <div
        style={{
          fontFamily: fontFamily.simula,
          color: colors.orange,
          fontSize: 26,
        }}
      >
        Age Restriction
      </div>
      <div style={{ marginTop: 15, fontSize: 16 }}>
        Sorry, this event contains alcohol and requires guests to be over 21
        years old.
      </div>
      <div style={{ marginTop: 30, textAlign: "center" }}>
        <RoundButton handleClick={() => navigate(-1)}>
          Back to Dashboard
        </RoundButton>
      </div>
    </CustomDialog>
  );
}

function ShareComponent() {
  const { eventDetail } = useContext(EventDetailContext);
  const sharable = Boolean(navigator.share);
  if (!sharable) return null;
  return (
    <>
      <div
        style={{
          position: "absolute",
          top: 30,
          right: 30,
          zIndex: 5,
        }}
      >
        <ShareSvg
          style={{
            cursor: "pointer",
            width: 35,
            height: 35,
          }}
          onClick={() => {
            navigator.share({
              title: eventDetail.title,
              text: eventDetail.description,
              url: window.location.href,
            });
          }}
        />
      </div>
    </>
  );
}

function EventImageComponent() {
  const [swiper, setSwiper] = useState<SwiperClass | null>(null);
  const [current, setCurrent] = useState<number>(0);
  const { eventDetail } = useContext(EventDetailContext);

  useEffect(() => {
    if (!swiper) return;
    swiper.on("activeIndexChange", (swiper: SwiperClass) =>
      setCurrent(swiper.activeIndex)
    );
  }, [swiper]);

  let labelPill = null as ReactNode;
  if (eventDetail.status === EventStatus.progress) {
    labelPill = (
      <RoundButton color={colors.green} backgroundColor={colors.yellow}>
        Draft
      </RoundButton>
    );
  }
  if (eventDetail.status === EventStatus.under_review) {
    labelPill = (
      <RoundButton color={colors.green} backgroundColor={colors.pink}>
        Under Review
      </RoundButton>
    );
  }
  if (eventDetail.status === EventStatus.rejected) {
    labelPill = (
      <RoundButton color={colors.ivory} backgroundColor={colors.orange}>
        Rejected
      </RoundButton>
    );
  }
  if (eventDetail.status === EventStatus.cancelled) {
    labelPill = (
      <RoundButton color={colors.ivory} backgroundColor={colors.red}>
        Cancelled
      </RoundButton>
    );
  }
  if (eventDetail.status === EventStatus.ready) {
    labelPill = <RoundButton>Approved</RoundButton>;
  }

  return (
    <div style={{ position: "relative" }}>
      <ShareComponent />
      {eventDetail.images.length === 0 && <Banner image="" />}
      {eventDetail.images.length > 0 && (
        <CustomSwiper
          slides={eventDetail.images.map((image, index) => (
            <Banner image={image} key={index} />
          ))}
          setSwiper={setSwiper}
        />
      )}
      {labelPill && (
        <div style={{ zIndex: 1, position: "absolute", bottom: 30, left: 30 }}>
          {labelPill}
        </div>
      )}
      <div style={{ zIndex: 1, position: "absolute", bottom: 30, right: 30 }}>
        <RoundButton
          style={{
            opacity: 0.5,
          }}
        >
          {current + 1}/{eventDetail.images.length}
        </RoundButton>
      </div>
    </div>
  );
}

interface RejectComponentProps {
  reject: string;
  reject_detail: string;
}

export function RejectComponent({
  reject,
  reject_detail,
}: RejectComponentProps) {
  return (
    <div style={{ color: colors.red }}>
      <div style={{ fontWeight: 700 }}>Reject Reason</div>
      <div style={{ marginTop: 15 }}>
        {reject !== "Other" && reject}
        {reject === "Other" && reject_detail}
      </div>
    </div>
  );
}

function CancelComponent() {
  const { eventDetail } = useContext(EventDetailContext);
  return (
    <div style={{ color: colors.red }}>
      <div style={{ fontWeight: 700 }}>Cancellation Reason</div>
      <div style={{ marginTop: 15 }}>
        {eventDetail.reject !== "Other" && eventDetail.cancel}
        {eventDetail.reject === "Other" && eventDetail.cancel_detail}
      </div>
    </div>
  );
}

interface GoogleMapComponentProps {
  eventDetail: EventDetail;
}

function GoogleMapComponent({ eventDetail }: GoogleMapComponentProps) {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: Config.getGoogleMapsApiKey(),
  });

  if (!isLoaded || !eventDetail.lat_lng) return null;

  return (
    <GoogleMap
      mapContainerStyle={{
        height: 200,
        borderRadius: 20,
      }}
      center={{
        lat: eventDetail.lat_lng.lat,
        lng: eventDetail.lat_lng.lng,
      }}
      zoom={13}
      options={{
        fullscreenControl: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        zoomControl: false,
      }}
    >
      <Circle
        options={{
          strokeColor: colors.red,
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: colors.red,
          fillOpacity: 0.35,
          center: {
            lat: eventDetail.lat_lng.lat,
            lng: eventDetail.lat_lng.lng,
          },
          radius: 600,
        }}
      />
    </GoogleMap>
  );
}

function HostInfoComponent() {
  const [open, setOpen] = useState<boolean>(false);
  const { eventDetail } = useContext(EventDetailContext);
  const { reviews } = useHostReview(eventDetail.host.id);
  const params = useParams();

  useEffect(() => {
    setOpen(false);
  }, [params]);

  if (!reviews) return null;
  return (
    <>
      <div
        style={{
          display: "flex",
          columnGap: 20,
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={() => setOpen(true)}
      >
        <div>
          <Avatar src={eventDetail.host.image} width={50} height={50} />
        </div>
        <div>
          <div>Hosted by {eventDetail.host.firstname}</div>
          <div style={{ display: "flex", alignItems: "center" }}>
            {formatNumberDigits(eventDetail.host.rating, 1)}
            <span style={{ marginLeft: 5, display: "flex" }}>
              <StarIcon fontSize="small" />
            </span>
          </div>
          {eventDetail.host.first_hosting_date && (
            <div>
              {calculateMonthsElapsed(eventDetail.host.first_hosting_date)}{" "}
              Months Hosting
            </div>
          )}
        </div>
      </div>
      <CustomDrawer open={open} handleClose={() => setOpen(false)}>
        <UserProfile
          userId={eventDetail.host.user_id}
          userMode={UserMode.host}
          visualize={{
            avatar: true,
            hostSummary: true,
            aboutMe: true,
            hostedEvents: true,
            hostReviews: true,
          }}
        />
      </CustomDrawer>
    </>
  );
}

function RejectButton() {
  const [open, setOpen] = useState<boolean>(false);
  const [rejected, setRejected] = useState<boolean>(false);
  const [reject, setReject] = useState<string>("");
  const [rejectDetail, setRejectDetail] = useState<string>("");
  const { patchStatus } = useEventHooks();
  const { eventDetail, refresh } = useContext(EventDetailContext);

  const handleReject = () => {
    patchStatus(Number(eventDetail.id), EventStatus.rejected, {
      reject,
      reject_detail: rejectDetail,
    })
      .then(refresh)
      .then(() => setRejected(true));
  };

  const disabledCondition = !(
    Boolean(reject) &&
    (reject !== "Other" || Boolean(rejectDetail))
  );

  return (
    <>
      <RoundButton
        handleClick={() => setOpen(true)}
        backgroundColor={colors.red}
        disabled={eventDetail.status === EventStatus.rejected}
      >
        Reject
      </RoundButton>
      <CustomDialog open={open} handleClose={() => setOpen(false)}>
        {rejected && (
          <>
            <div
              style={{
                fontFamily: fontFamily.simula,
                color: colors.red,
                fontSize: 30,
              }}
            >
              Rejected event:
            </div>
            <div style={{ marginTop: 30, fontSize: 24, fontWeight: 600 }}>
              {eventDetail.title}
            </div>
          </>
        )}
        {!rejected && (
          <>
            <div style={{ fontSize: 24, fontWeight: 600, textAlign: "center" }}>
              Reject Event
            </div>
            <div style={{ marginTop: 30, fontSize: 24, fontWeight: 600 }}>
              Are you sure you want to reject this event?
            </div>
            <div style={{ marginTop: 30 }}>
              <SelectInput
                items={rejectTypes.map((item) => ({
                  label: item,
                  value: item,
                }))}
                value={reject}
                fullWidth
                label="Select Your Reason"
                handleChange={setReject}
              />
            </div>
            {reject === "Other" && (
              <div style={{ marginTop: 30 }}>
                <TextInput
                  label="Details"
                  fullWidth
                  handleChange={setRejectDetail}
                  multiline
                  rows={5}
                  defaultValue={rejectDetail}
                />
              </div>
            )}
            <div style={{ marginTop: 30, textAlign: "center" }}>
              <RoundButton
                handleClick={handleReject}
                disabled={disabledCondition}
              >
                Reject
              </RoundButton>
            </div>
          </>
        )}
      </CustomDialog>
    </>
  );
}

function PublishButton() {
  const { patchStatus } = useEventHooks();
  const { refresh } = useContext(EventDetailContext);
  const { eventDetail } = useContext(EventDetailContext);
  const [open, setOpen] = useState<boolean>(false);

  const handlePublish = () => {
    patchStatus(Number(eventDetail.id), EventStatus.published)
      .then(refresh)
      .then(() => setOpen(true));
  };

  return (
    <>
      <RoundButton handleClick={handlePublish}>Publish Event</RoundButton>
      <CustomDialog open={open} handleClose={() => setOpen(false)}>
        <div
          style={{
            fontSize: 24,
            fontFamily: fontFamily.simula,
            color: colors.orange,
          }}
        >
          Congratulations!
          <br />
          Your event is published.
        </div>
        <div
          style={{ marginTop: 30, textAlign: "center", position: "relative" }}
        >
          <ShareComponent />
          <CustomImage
            height={200}
            src={eventDetail.images[0] || ""}
            style={{ borderRadius: 20 }}
          />
        </div>
        <div style={{ marginTop: 30 }}>
          <EventInfo
            eventType={eventDetail.event_type}
            cuisine={eventDetail.cuisine}
            title={eventDetail.title}
          />
        </div>
      </CustomDialog>
    </>
  );
}

function ApproveButton() {
  const [open, setOpen] = useState<boolean>(false);
  const { patchStatus } = useEventHooks();
  const { eventDetail, refresh } = useContext(EventDetailContext);

  const handleApprove = () => {
    patchStatus(Number(eventDetail.id), EventStatus.ready)
      .then(refresh)
      .then(() => setOpen(false));
  };

  return (
    <>
      <RoundButton
        handleClick={() => setOpen(true)}
        disabled={eventDetail.status === EventStatus.ready}
      >
        Approve
      </RoundButton>
      <CustomDialog open={open} handleClose={() => setOpen(false)}>
        <div style={{ fontFamily: fontFamily.simula, fontSize: 30 }}>
          Approve event:
        </div>
        <div style={{ marginTop: 30, fontSize: 24, fontWeight: 600 }}>
          {eventDetail.title}
        </div>
        <div style={{ marginTop: 50, textAlign: "center" }}>
          <RoundButton handleClick={handleApprove}>Approve</RoundButton>
        </div>
      </CustomDialog>
    </>
  );
}

interface DeleteEventProps {
  eventDetail: EventDetail;
}

function DeleteEvent({ eventDetail }: DeleteEventProps) {
  const [open, setOpen] = useState<boolean>(false);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const navigate = useNavigate();
  const { deleteEvent } = useEventHooks();
  return (
    <>
      <div
        style={{
          fontSize: 14,
          fontWeight: 700,
          cursor: "pointer",
          display: "inline",
        }}
        onClick={() => setOpen(true)}
      >
        Delete Event
      </div>
      <CustomDialog open={open} handleClose={() => setOpen(false)}>
        <div style={{ fontSize: 24, fontWeight: 600 }}>
          Are you sure you want to delete this event?
        </div>
        <div style={{ marginTop: 30 }}>
          <EventIntroLabel
            image={eventDetail.images[0] || ""}
            title={eventDetail.title}
            cuisine={eventDetail.cuisine}
            date={eventDetail.date}
            eventType={eventDetail.event_type}
            address={eventDetail.address}
          />
        </div>
        <div style={{ marginTop: 30, textAlign: "center" }}>
          <RoundButton
            handleClick={() =>
              deleteEvent(eventDetail.id).then(() => {
                setOpen(false);
                setOpenDelete(true);
              })
            }
          >
            Delete
          </RoundButton>
        </div>
      </CustomDialog>
      <CustomDrawer
        open={openDelete}
        handleClose={() => setOpenDelete(false)}
        height="90vh"
      >
        <div>Your event</div>
        <div style={{ fontSize: 24, fontWeight: 600, marginTop: 15 }}>
          {eventDetail.title}
        </div>
        <div style={{ marginTop: 15 }}>Is deleted.</div>
        <div style={{ marginTop: 30 }}>
          <RoundButton
            backgroundColor={colors.orange}
            handleClick={() => navigate("/events/new/edit")}
          >
            Create New Event
          </RoundButton>
        </div>
        <div style={{ marginTop: 30 }}>
          <RoundButton handleClick={() => navigate(-1)}>Done</RoundButton>
        </div>
      </CustomDrawer>
    </>
  );
}

interface ModifyEventProps {
  disabled?: boolean;
  setChangeDatetime: (changeDatetime: boolean) => void;
}

function ModifyEvent({ disabled, setChangeDatetime }: ModifyEventProps) {
  const navigate = useNavigate();
  const { eventDetail } = useContext(EventDetailContext);
  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      <div
        style={{
          fontSize: 14,
          fontWeight: 700,
          cursor: disabled ? "default" : "pointer",
          opacity: disabled ? 0.3 : 1,
          pointerEvents: disabled ? "none" : "auto",
        }}
        onClick={() => {
          if (eventDetail.status === EventStatus.ready) {
            setOpen(true);
          } else {
            navigate(`/events/${eventDetail.id}/edit`);
          }
        }}
      >
        Modify Event
      </div>
      <CustomDialog open={open} handleClose={() => setOpen(false)}>
        <div
          style={{
            fontWeight: 600,
            fontSize: 24,
          }}
        >
          Are you sure you want to modify this event?
        </div>
        <div
          style={{
            marginTop: 15,
            fontWeight: 500,
            fontSize: 14,
            color: colors.orange,
          }}
        >
          Changing event details other than date and time will require it to go
          under review again.
        </div>
        <div
          style={{
            marginTop: 30,
            textAlign: "center",
          }}
        >
          <RoundButton
            backgroundColor={colors.orange}
            handleClick={() => navigate(`/events/${eventDetail.id}/edit`)}
          >
            Proceed to Modify
          </RoundButton>
        </div>
        <div
          style={{
            marginTop: 30,
            textAlign: "center",
          }}
        >
          <RoundButton handleClick={() => setChangeDatetime(true)}>
            Change Date and Publish
          </RoundButton>
        </div>
      </CustomDialog>
    </>
  );
}

function CancelReservation() {
  const [open, setOpen] = useState<boolean>(false);
  const [cancelled, setCancelled] = useState<boolean>(false);
  const { eventDetail, refresh } = useContext(EventDetailContext);

  const cancelReservation = () => {
    fetchData("POST", `/events/${eventDetail.id}/reservations/cancel`)
      .then(refresh)
      .then(() => {
        setOpen(false);
        setCancelled(true);
      });
  };

  return (
    <>
      <div
        style={{ fontSize: 14, fontWeight: 700, cursor: "pointer" }}
        onClick={() => setOpen(true)}
      >
        Cancel Reservation
      </div>
      <CustomDialog open={open} handleClose={() => setOpen(false)}>
        <div style={{ marginTop: 30, fontWeight: 600, fontSize: 24 }}>
          Are you sure you want to cancel this reservation?
        </div>
        <div style={{ marginTop: 30 }}>
          <CustomImage
            src={eventDetail.images[0] || ""}
            height={160}
            style={{ borderRadius: 20 }}
          />
        </div>
        <div
          style={{
            marginTop: 30,
            color: colors.orange,
            fontWeight: 700,
            textAlign: "center",
          }}
        >
          By finish cancelling, you agree to the
          <br />
          <TermsAndConditionsDialogButton />.
        </div>
        <div style={{ marginTop: 30, textAlign: "center" }}>
          <RoundButton
            backgroundColor={colors.orange}
            handleClick={cancelReservation}
          >
            Cancel Reservation
          </RoundButton>
        </div>
      </CustomDialog>
      <CustomDialog open={cancelled} handleClose={() => setCancelled(false)}>
        <div>Your reservation for</div>
        <div style={{ marginTop: 30, fontSize: 24, fontWeight: 600 }}>
          {eventDetail.title}
        </div>
        <div style={{ marginTop: 30 }}>Is cancelled.</div>
      </CustomDialog>
    </>
  );
}

function FAQ() {
  const navigate = useNavigate();
  return (
    <div
      style={{ fontSize: 14, fontWeight: 700, cursor: "pointer" }}
      onClick={() => navigate("/profile/faq")}
    >
      FAQ
    </div>
  );
}

interface HouseRulesProps {
  home_rule: string;
}

function HouseRules({ home_rule }: HouseRulesProps) {
  return <AccordionView label="House Rules" detail={home_rule} />;
}

function TermsAndConditionComponent() {
  const navigate = useNavigate();
  return (
    <div
      style={{ fontSize: 14, fontWeight: 700, cursor: "pointer" }}
      onClick={() => navigate("/profile/terms_and_condition")}
    >
      Terms and Conditions
    </div>
  );
}

interface ExpectedRevenueProps {
  label: string;
  total: string;
}

function ExpectedRevenue({ label, total }: ExpectedRevenueProps) {
  return (
    <>
      <div
        style={{
          fontSize: 14,
          fontWeight: 700,
        }}
      >
        {label}
      </div>
      <div
        style={{
          marginTop: 30,
          display: "flex",
          columnGap: 10,
          alignItems: "center",
        }}
      >
        <div>Total</div>
        <div style={{ fontSize: 24, fontWeight: 700 }}>{total}</div>
      </div>
    </>
  );
}

interface ActionButtonProps {
  setChangeDatetime: (changeDatetime: boolean) => void;
}

function ActionButton({ setChangeDatetime }: ActionButtonProps) {
  const currentMode = useCurrentMode();
  const { eventDetail, myEvent } = useContext(EventDetailContext);

  if (currentMode === UserMode.admin) {
    if (eventDetail.status === EventStatus.under_review) {
      return (
        <div
          style={{
            marginTop: 30,
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <ApproveButton />
          <RejectButton />
        </div>
      );
    }
  }
  if (currentMode === UserMode.host && myEvent) {
    // if event is for same host
    if (eventDetail.status === EventStatus.ready) {
      return (
        <div
          style={{
            marginTop: 30,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <PublishButton />
        </div>
      );
    }
    if (
      [EventStatus.expired, EventStatus.cancelled].includes(eventDetail.status)
    ) {
      return (
        <div
          style={{
            marginTop: 30,
            textAlign: "center",
          }}
        >
          <RoundButton handleClick={() => setChangeDatetime(true)}>
            Change Date and Publish
          </RoundButton>
        </div>
      );
    }
    // if ([EventStatus.closed].includes(eventDetail.status)) {
    //   return (
    //     <div
    //       style={{
    //         marginTop: 30,
    //         textAlign: "center",
    //       }}
    //     >
    //       <HostAgainEvent />
    //     </div>
    //   );
    // }
  }
  return null;
}

interface PrefixMenuProps {
  menus: EventMenuDetail[];
}

function PrefixMenu({ menus }: PrefixMenuProps) {
  return (
    <>
      <YellowComponent>
        <div style={{ fontFamily: fontFamily.simula, fontSize: 20 }}>
          Prefix Menu
        </div>
        <div>
          {menus.map((menu, index) => (
            <Fragment key={index}>
              <div
                style={{
                  marginTop: 15,
                  fontWeight: 700,
                }}
              >
                {menu.title}
              </div>
              <div style={{ fontSize: 14 }}>{menu.description}</div>
              {menu.allergens && menu.allergens.length > 0 && (
                <div
                  style={{
                    display: "flex",
                    columnGap: 5,
                    fontSize: 14,
                    alignItems: "center",
                    marginTop: 5,
                  }}
                >
                  <WarningSvg />
                  <div style={{ color: colors.red }}>
                    Contains: {menu.allergens.join(", ")}
                  </div>
                </div>
              )}
            </Fragment>
          ))}
        </div>
      </YellowComponent>
    </>
  );
}

interface EventIntroductionProps {
  description: string;
}

function EventIntroduction({ description }: EventIntroductionProps) {
  return <ReadMore>{description}</ReadMore>;
}

interface BannerProps {
  image?: string;
}

function Banner({ image }: BannerProps) {
  return <CustomImage height={250} src={image} />;
}

interface LocationInfoProps {
  capacity: number;
  date: string;
  startTime: string;
  endTime: string;
  address: string;
  alcohol: YesNo;
  disclosure: EventDisclosure;
  totalReserved: number;
  selfReserved: number;
  status: EventStatus;
}

function LocationInfo({
  capacity,
  date,
  startTime,
  endTime,
  address,
  alcohol,
  disclosure,
  totalReserved,
  selfReserved,
  status,
}: LocationInfoProps) {
  return (
    <YellowComponent>
      <div style={{ display: "flex", flexDirection: "column", rowGap: 10 }}>
        {selfReserved > 0 && (
          <div style={{ display: "flex", alignItems: "center" }}>
            <CheckSvg
              style={{
                width: 20,
              }}
              fill={colors.green}
            />
            <div style={{ marginLeft: 10 }}>{selfReserved} Seats Reserved</div>
          </div>
        )}
        <div style={{ display: "flex", alignItems: "center" }}>
          <ProfileSvg
            style={{
              width: 20,
            }}
            fill={colors.green}
          />
          <div style={{ marginLeft: 10 }}>
            {status !== EventStatus.closed &&
              `${totalReserved}/${capacity} Seats Filled`}
            {status === EventStatus.closed && `${totalReserved} Attended`}
          </div>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <CalendarSvg
            style={{
              width: 20,
            }}
            fill={colors.green}
          />
          <div style={{ marginLeft: 10 }}>
            {TimeUtil.formatDayMonth(date)},{" "}
            {TimeUtil.convertTo12HourFormat(startTime)} -{" "}
            {TimeUtil.convertTo12HourFormat(endTime)}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <LocationSvg
            style={{
              width: 20,
            }}
            fill={colors.green}
          />
          <div
            style={{ marginLeft: 10, cursor: "pointer" }}
            onClick={() => {
              const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                address
              )}`;
              window.open(googleMapsUrl, "_blank", "noopener,noreferrer");
            }}
          >
            {address}
          </div>
        </div>
        {alcohol === YesNo.yes && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <DrinkSvg
              style={{
                width: 20,
              }}
              fill={colors.green}
            />
            <div style={{ marginLeft: 10 }}>21+</div>
          </div>
        )}
        {disclosure === EventDisclosure.private && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <PrivateSvg
              style={{
                width: 20,
              }}
              fill={colors.green}
            />
            <div style={{ marginLeft: 10 }}>Private Event</div>
          </div>
        )}
      </div>
    </YellowComponent>
  );
}

function CancelEvent() {
  const [open, setOpen] = useState<boolean>(false);
  const [openCancel, setOpenCancel] = useState<boolean>(false);
  const { eventDetail, refresh } = useContext(EventDetailContext);
  const [cancel, setCancel] = useState<string>("");
  const [cancelDetail, setCancelDetail] = useState<string>("");
  const [cancelConditionAgreement, setCancelConditionAgreement] =
    useState<boolean>(false);
  const { patchStatus } = useEventHooks();

  const handleCancel = () => {
    patchStatus(Number(eventDetail.id), EventStatus.cancelled, {
      cancel,
      cancel_detail: cancelDetail,
      cancel_condition_agreement: cancelConditionAgreement,
    }).then(() => {
      setOpenCancel(true);
      setOpen(false);
    });
  };

  const disabledCondition = !(
    Boolean(cancel) &&
    (cancel !== "Other" || Boolean(cancelDetail)) &&
    cancelConditionAgreement
  );

  return (
    <>
      <div
        style={{
          fontSize: 14,
          fontWeight: 700,
          cursor: "pointer",
          display: "inline",
        }}
        onClick={() => setOpen(true)}
      >
        Cancel Event
      </div>
      <CustomDialog open={open} handleClose={() => setOpen(false)}>
        <div style={{ fontSize: 24, fontWeight: 600 }}>
          Are you sure you want to cancel this event?
        </div>
        <div style={{ marginTop: 30 }}>
          <SelectInput
            label="Select Your Reason"
            value={cancel}
            items={cancelTypes.map((cancelType) => ({
              label: cancelType,
              value: cancelType,
            }))}
            handleChange={setCancel}
          />
        </div>
        {cancel === "Other" && (
          <div style={{ marginTop: 30 }}>
            <TextInput
              label="Details"
              multiline
              rows={5}
              handleChange={setCancelDetail}
              defaultValue={cancelDetail}
            />
          </div>
        )}
        <div
          style={{
            marginTop: 30,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            I Agree to the
            <br />
            <TermsAndConditionsDialogButton />
          </div>
          <div>
            <CustomCheckbox
              label="Yes"
              left
              handleCheck={setCancelConditionAgreement}
              checked={cancelConditionAgreement}
            />
          </div>
        </div>
        <div style={{ marginTop: 30, textAlign: "center" }}>
          <RoundButton handleClick={handleCancel} disabled={disabledCondition}>
            Cancel
          </RoundButton>
        </div>
      </CustomDialog>
      <CustomDrawer
        open={openCancel}
        handleClose={() => {
          setOpenCancel(false);
          refresh();
        }}
        height="90vh"
      >
        <div>Your event</div>
        <div style={{ fontSize: 24, fontWeight: 600, marginTop: 15 }}>
          {eventDetail.title}
        </div>
        <div style={{ marginTop: 15 }}>Is cancelled.</div>
        <div style={{ marginTop: 30 }}>
          You can view your cancelled event in your Pending Events.
        </div>
      </CustomDrawer>
    </>
  );
}

function AttendingList() {
  const { eventDetail, refresh } = useContext(EventDetailContext);
  const [open, setOpen] = useState<boolean>(false);
  const [guestCancel, setGuestCancel] = useState<string>("");
  const [guestCancelDetail, setGuestCancelDetail] = useState<string>("");
  const user = useUser();

  const handleRemoveReservation = (reservationId: number) => {
    fetchData("DELETE", `/reservations/${reservationId}`, {
      guest_cancel: guestCancel,
      guest_cancel_detail: guestCancelDetail,
    })
      .then(refresh)
      .then(() => setOpen(false));
  };

  const disabledCondition = !(
    Boolean(guestCancel) &&
    (guestCancel !== "Other" || Boolean(guestCancelDetail))
  );

  return (
    <div>
      <div
        style={{
          fontSize: 14,
          fontWeight: 700,
        }}
      >
        People Attending
      </div>
      <div
        style={{
          display: "flex",
          columnGap: 25,
          overflow: "auto",
        }}
      >
        {eventDetail.reservations.map((reservation, index) => (
          <Fragment key={index}>
            <UserAvatar
              firstname={reservation.firstname}
              image={reservation.image}
              partySize={reservation.party_size}
              userId={reservation.user_id}
              footer={
                <>
                  {eventDetail.status === EventStatus.published &&
                    eventDetail.host.id === user?.host?.id && (
                      <div style={{ textAlign: "center" }}>
                        <RoundButton
                          backgroundColor={colors.red}
                          handleClick={() => setOpen(true)}
                        >
                          Remove from This Event
                        </RoundButton>
                      </div>
                    )}
                </>
              }
            />
            <CustomDialog open={open} handleClose={() => setOpen(false)}>
              <div style={{ marginTop: 30, fontSize: 24, fontWeight: 600 }}>
                Are you sure you want to remove this guest from your event?
              </div>
              <div style={{ marginTop: 30 }}>
                <SelectInput
                  label="Select Your Reason"
                  value={guestCancel}
                  items={guestCancelTypes.map((guestCancelType) => ({
                    label: guestCancelType,
                    value: guestCancelType,
                  }))}
                  handleChange={setGuestCancel}
                />
              </div>
              {guestCancel === "Other" && (
                <div style={{ marginTop: 30 }}>
                  <TextInput
                    label="Details"
                    multiline
                    rows={5}
                    handleChange={setGuestCancelDetail}
                    defaultValue={guestCancelDetail}
                  />
                </div>
              )}
              <div style={{ marginTop: 30, textAlign: "center" }}>
                <RoundButton
                  color={colors.ivory}
                  backgroundColor={colors.red}
                  disabled={disabledCondition}
                  handleClick={() =>
                    handleRemoveReservation(reservation.reservation_id)
                  }
                >
                  Remove
                </RoundButton>
              </div>
            </CustomDialog>
          </Fragment>
        ))}
      </div>
    </div>
  );
}

enum EventDetailType {
  info = "info",
  checkout = "checkout",
}

interface AllergenComponentProps {
  handleBack: () => void;
  handleClick: () => void;
}

function AllergenComponent({
  handleBack,
  handleClick,
}: AllergenComponentProps) {
  const { eventDetail } = useContext(EventDetailContext);
  const allergenSet = new Set<string>();
  for (const menu of eventDetail.menus) {
    for (const allergen of menu.allergens) {
      allergenSet.add(allergen);
    }
  }
  return (
    <div style={{ textAlign: "center", padding: 30 }}>
      <Backward handleClick={handleBack} />
      <div style={{ marginTop: 50, fontSize: 20, fontWeight: 600 }}>
        This event consists of listed allergens:
      </div>
      <div
        style={{
          marginTop: 30,
          fontSize: 24,
          fontWeight: 600,
          color: colors.orange,
        }}
      >
        {Array.from(allergenSet).map((allergen, index) => (
          <div
            key={index}
            style={{
              marginTop: 10,
            }}
          >
            {allergen}
          </div>
        ))}
      </div>
      <div style={{ marginTop: 30, fontSize: 20, fontWeight: 600 }}>
        Would you like to proceed to payment?
      </div>
      <div style={{ marginTop: 30, fontSize: 14, color: colors.orange }}>
        Please make sure your other guests are free from these allergens as well
        if you’re booking multiple seats.
      </div>
      <div style={{ marginTop: 30 }}>
        <RoundButton handleClick={handleClick}>Continue</RoundButton>
      </div>
    </div>
  );
}

interface PaymentComponentProps {
  partySize: number;
  handleNext: () => void;
  handleBack: () => void;
}

function PaymentComponent({
  partySize,
  handleNext,
  handleBack,
}: PaymentComponentProps) {
  const { eventDetail, refresh } = useContext(EventDetailContext);
  return (
    <div style={{ padding: 30 }}>
      <Backward handleClick={handleBack} />
      <div style={{ marginTop: 10, textAlign: "center" }}>Payment</div>
      <Horizontal marginTop={30} marginBottom={30} />
      <PayPalButtons
        createOrder={() =>
          fetchData<any>("POST", `/events/${eventDetail.id}/orders`, {
            party_size: partySize,
          }).then((orderData) => orderData.id)
        }
        onApprove={(data) =>
          fetchData("POST", `/orders/${data.orderID}/authorize`)
            .then(refresh)
            .then(handleNext)
        }
      />
    </div>
  );
}

interface ConfirmationComponentProps {
  handleClick: () => void;
}

function ConfirmationComponent({ handleClick }: ConfirmationComponentProps) {
  const { eventDetail } = useContext(EventDetailContext);

  return (
    <div style={{ padding: 30 }}>
      <div>Your reservation for</div>
      <div style={{ marginTop: 30, fontSize: 24, fontWeight: 600 }}>
        {eventDetail.title}
      </div>
      <div style={{ marginTop: 30 }}>Is complete!</div>
      <OrderConfirmationSvg />
      <div style={{ marginTop: 30 }}>
        <RoundButton color={colors.yellow} handleClick={handleClick}>
          Continue
        </RoundButton>
      </div>
    </div>
  );
}

function CancellationPolicy() {
  return (
    <AccordionView
      label="Cancellation Policy"
      detail={
        "Guests can cancel their attendance to a dinner party up to 48 hours before the scheduled start time without any penalty. Cancellations made within 48 hours will result in a non-refundable fee equivalent to 50% of the total booking cost. No-shows will be charged the full booking amount."
      }
    />
  );
}

enum CheckoutStep {
  default = "default",
  allergen = "allergen",
  payment = "payment",
  confirmation = "confirmation",
}

interface EventDetailCheckoutProps {
  handleBack: () => void;
}

function EventDetailCheckout({ handleBack }: EventDetailCheckoutProps) {
  const [step, setStep] = useState<CheckoutStep>(CheckoutStep.default);
  const { eventDetail, myEvent, refresh } = useContext(EventDetailContext);
  const [partySize, setPartySize] = useState<number>(1);
  let allergenCondition = false;
  for (const menu of eventDetail.menus) {
    if (menu.allergens.length > 0) {
      allergenCondition = true;
      break;
    }
  }

  if (step === CheckoutStep.allergen)
    return (
      <AllergenComponent
        handleBack={() => setStep(CheckoutStep.default)}
        handleClick={() => setStep(CheckoutStep.payment)}
      />
    );
  if (step === CheckoutStep.payment)
    return (
      <PaymentComponent
        partySize={partySize}
        handleBack={() => {
          if (allergenCondition) {
            setStep(CheckoutStep.allergen);
          } else {
            setStep(CheckoutStep.default);
          }
        }}
        handleNext={() => setStep(CheckoutStep.confirmation)}
      />
    );
  if (step === CheckoutStep.confirmation)
    return (
      <ConfirmationComponent
        handleClick={() => {
          handleBack();
          setStep(CheckoutStep.default);
        }}
      />
    );

  if (myEvent) return null;

  return (
    <>
      <Backward type="inverted" handleClick={handleBack} />
      <EventImageComponent />
      <div style={{ padding: 30 }}>
        <div>
          <EventInfo
            eventType={eventDetail.event_type}
            cuisine={eventDetail.cuisine}
            title={eventDetail.title}
          />
        </div>
        <div style={{ marginTop: 30 }}>
          <LocationInfo
            capacity={eventDetail.capacity}
            date={eventDetail.date}
            startTime={eventDetail.start_time}
            endTime={eventDetail.end_time}
            address={eventDetail.address}
            alcohol={eventDetail.alcohol}
            disclosure={eventDetail.event_disclosure}
            totalReserved={eventDetail.reserved}
            selfReserved={eventDetail.self_reserved}
            status={eventDetail.status}
          />
        </div>
        <div style={{ marginTop: 30 }}>
          <div
            style={{
              border: `1px solid ${colors.green}`,
              padding: 30,
              borderRadius: 20,
            }}
          >
            <StatusDisplayComponent />
            <Horizontal marginTop={30} marginBottom={30} />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                columnGap: 40,
              }}
            >
              <SubtractSvg
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (partySize === 1) return;
                  setPartySize(partySize - 1);
                }}
              />
              <div style={{ display: "flex", position: "relative" }}>
                <TablySvg />
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: "50%",
                    transform: "translate(-50%,-50%)",
                    fontSize: 24,
                    fontWeight: 700,
                  }}
                >
                  {partySize}
                </div>
              </div>
              <AddSvg
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (
                    partySize === 99 ||
                    eventDetail.reserved + partySize >= eventDetail.capacity
                  )
                    return;
                  setPartySize(partySize + 1);
                }}
              />
            </div>
            <Horizontal marginTop={30} marginBottom={30} />
            <CancellationPolicy />
            <Horizontal marginTop={30} marginBottom={30} />
            <HouseRules home_rule={eventDetail.home_rule} />
            <Horizontal marginTop={30} marginBottom={30} />
            <div style={{ fontSize: 14, fontWeight: 700 }}>
              *Exact address provided upon booking
            </div>
          </div>
        </div>
        {eventDetail.event_payment_type === EventPaymentType.paid && (
          <>
            <div style={{ marginLeft: -30, marginRight: -30 }}>
              <Horizontal marginTop={30} marginBottom={30} />
            </div>
            <div
              style={{
                fontSize: 20,
                fontWeight: 600,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div>Total</div>
              <div>${(eventDetail.price * partySize).toLocaleString()}</div>
            </div>
          </>
        )}
        <Horizontal marginTop={30} marginBottom={30} />
        <div style={{ textAlign: "center" }}>
          <RoundButton
            backgroundColor={colors.orange}
            handleClick={() => {
              // if allergens, then go to allergen
              // if not, then go to payment
              if (allergenCondition) {
                setStep(CheckoutStep.allergen);
              } else if (
                eventDetail.event_payment_type === EventPaymentType.free
              ) {
                fetchData<any>("POST", `/events/${eventDetail.id}/reserve`, {
                  party_size: partySize,
                })
                  .then(refresh)
                  .then(() => setStep(CheckoutStep.confirmation));
              } else if (
                eventDetail.event_payment_type === EventPaymentType.paid
              ) {
                setStep(CheckoutStep.payment);
              }
            }}
          >
            Purchase
          </RoundButton>
        </div>
      </div>
    </>
  );
}

function EventDetailInfo() {
  const { eventDetail, refresh, myEvent } = useContext(EventDetailContext);
  const currentMode = useCurrentMode();
  const navigate = useNavigate();
  const [changeDatetime, setChangeDatetime] = useState<boolean>(false);
  const [date, setDate] = useState<string>(eventDetail.date);
  const [startTime, setStartTime] = useState<string>(eventDetail.start_time);
  const [endTime, setEndTime] = useState<string>(eventDetail.end_time);
  const [expired, setExpired] = useState<boolean>(false);

  const handleChangeDatetimePublish = () => {
    fetchData("POST", `/events/${eventDetail.id}/datetime`, {
      date,
      start_time: startTime,
      end_time: endTime,
    })
      .then(refresh)
      .then(() => setChangeDatetime(false));
  };

  useEffect(() => {
    if (eventDetail.status === EventStatus.expired) {
      setExpired(true);
    }
  }, [eventDetail]);

  return (
    <>
      <Backward
        type="inverted"
        handleClick={() => {
          if (window.history.length <= 1) {
            return navigate("/");
          }
          navigate(-1);
        }}
      />
      <EventImageComponent />
      <div style={{ padding: 30 }}>
        <EventInfo
          eventType={eventDetail.event_type}
          cuisine={eventDetail.cuisine}
          title={eventDetail.title}
        />
        <div style={{ marginTop: 30 }}>
          <LocationInfo
            capacity={eventDetail.capacity}
            date={eventDetail.date}
            startTime={eventDetail.start_time}
            endTime={eventDetail.end_time}
            address={eventDetail.address}
            alcohol={eventDetail.alcohol}
            disclosure={eventDetail.event_disclosure}
            totalReserved={eventDetail.reserved}
            selfReserved={eventDetail.self_reserved}
            status={eventDetail.status}
          />
        </div>
        {eventDetail.status === EventStatus.rejected && (
          <>
            <Horizontal marginTop={30} marginBottom={30} />
            <RejectComponent
              reject={eventDetail.reject}
              reject_detail={eventDetail.reject_detail}
            />
          </>
        )}
        {eventDetail.status === EventStatus.cancelled && (
          <>
            <Horizontal marginTop={30} marginBottom={30} />
            <CancelComponent />
          </>
        )}
        {/* <div>
      <EventRating />
    </div>
    <Horizontal marginTop={30} marginBottom={30} /> */}
        {/* <div>
      <EventRevenue />
    </div>
    <Horizontal marginTop={30} marginBottom={30} /> */}
        <Horizontal marginTop={30} marginBottom={30} />
        <HostInfoComponent />
        <EventRatingComponent />
        <TotalCostComponent />
        {eventDetail.reservations.length > 0 && (
          <>
            <Horizontal marginTop={30} marginBottom={30} />
            <AttendingList />
          </>
        )}
        {/* <div>
      <EventException />
    </div>
    <Horizontal marginTop={30} marginBottom={30} /> */}
        <Horizontal marginTop={30} marginBottom={30} />
        <EventIntroduction description={eventDetail.description} />
        <div style={{ marginTop: 30 }}>
          <PrefixMenu menus={eventDetail.menus} />
        </div>
        <div style={{ marginTop: 30 }}>
          <GoogleMapComponent eventDetail={eventDetail} />
        </div>
        {Boolean(eventDetail.entry_instruction) && (
          <div style={{ marginTop: 30 }}>
            <div style={{ fontWeight: 700 }}>Entry Instruction</div>
            <div style={{ marginTop: 15 }}>{eventDetail.entry_instruction}</div>
          </div>
        )}
        {[EventDetailMode.hosting, EventDetailMode.admin].includes(
          eventDetail.mode
        ) && (
          <>
            <Horizontal marginTop={30} marginBottom={30} />
            <ExpectedRevenue
              label={(() => {
                // general cases other than closed
                if (eventDetail.status !== EventStatus.closed) {
                  if (eventDetail.mode === EventDetailMode.hosting)
                    return "My Expected Earning";
                  if (eventDetail.mode === EventDetailMode.admin)
                    return "Expected Earning for Host";
                }
                if (eventDetail.mode === EventDetailMode.hosting)
                  return "My Earning";
                if (eventDetail.mode === EventDetailMode.admin)
                  return "Earning for Host";
                return "";
              })()}
              total={`$${(
                eventDetail.price *
                eventDetail.reserved *
                0.93
              ).toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`}
            />
          </>
        )}
        {eventDetail.mode === EventDetailMode.admin && (
          <>
            <Horizontal marginTop={30} marginBottom={30} />
            <ExpectedRevenue
              label="Expected Earning for Tably"
              total={`$${(
                eventDetail.price *
                eventDetail.reserved *
                0.07
              ).toLocaleString("en-US", {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`}
            />
          </>
        )}
        {eventDetail.mode !== EventDetailMode.stranger && (
          <>
            <Horizontal marginTop={30} marginBottom={30} />
            <TermsAndConditionComponent />
            <Horizontal marginTop={30} marginBottom={30} />
            <HouseRules home_rule={eventDetail.home_rule} />
            <Horizontal marginTop={30} marginBottom={30} />
            <FAQ />
          </>
        )}
        {eventDetail.status === EventStatus.published &&
          eventDetail.mode === EventDetailMode.reserved && (
            <>
              <Horizontal marginTop={30} marginBottom={30} />
              <CancelReservation />
            </>
          )}
        {/* <div>
      <CancelEvent />
    </div>
    <Horizontal marginTop={30} marginBottom={30} /> */}
        {currentMode === UserMode.host && myEvent && (
          <>
            {[
              EventStatus.under_review,
              EventStatus.progress,
              EventStatus.ready,
              EventStatus.rejected,
            ].includes(eventDetail.status) && (
              <>
                <Horizontal marginTop={30} marginBottom={30} />
                <ModifyEvent setChangeDatetime={setChangeDatetime} />
              </>
            )}
            {[EventStatus.published].includes(eventDetail.status) && (
              <>
                <Horizontal marginTop={30} marginBottom={30} />
                <CancelEvent />
              </>
            )}
            {[
              EventStatus.under_review,
              EventStatus.progress,
              EventStatus.cancelled,
              EventStatus.ready,
              EventStatus.rejected,
            ].includes(eventDetail.status) && (
              <>
                <Horizontal marginTop={30} marginBottom={30} />
                <DeleteEvent eventDetail={eventDetail} />
              </>
            )}
          </>
        )}
        <ActionButton setChangeDatetime={setChangeDatetime} />
      </div>
      <AgeRestrictionComponent />
      <CustomDialog
        open={changeDatetime}
        handleClose={() => setChangeDatetime(false)}
      >
        <div
          style={{
            fontWeight: 600,
            fontSize: 24,
            textAlign: "center",
          }}
        >
          Change
          <br />
          Date and Time
        </div>
        <div style={{ marginTop: 30 }}>Current Date and Time</div>
        <div style={{ marginTop: 15 }}>
          <YellowComponent>
            <div
              style={{ display: "flex", alignItems: "center", columnGap: 15 }}
            >
              <CalendarSvg
                style={{
                  width: 20,
                }}
                fill={colors.green}
              />
              <div>
                {TimeUtil.formatDayMonth(date)},{" "}
                {TimeUtil.convertTo12HourFormat(startTime)} -{" "}
                {TimeUtil.convertTo12HourFormat(endTime)}
              </div>
            </div>
          </YellowComponent>
        </div>
        <Horizontal marginTop={30} />
        <div style={{ marginTop: 30 }}>
          <TextInput
            type="date"
            label="Date"
            handleChange={(date) => {
              const current = TimeUtil.getNowDateString();
              if (date < current) return;
              setDate(date);
            }}
            value={date}
            inputProps={{
              min: TimeUtil.getNowDateString(),
            }}
          />
        </div>
        <div
          style={{
            marginTop: 30,
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "space-between",
          }}
        >
          <div>
            <TextInput
              type="time"
              label="Time"
              handleChange={setStartTime}
              value={startTime}
            />
          </div>
          <div style={{ width: 30 }}>
            <Horizontal marginBottom={25} />
          </div>
          <div>
            <TextInput type="time" handleChange={setEndTime} value={endTime} />
          </div>
        </div>
        <div style={{ marginTop: 30, fontSize: 14 }}>
          This event has already been approved!
          <br />
          Once published, your event will be public.
        </div>
        <div
          style={{
            marginTop: 30,
            textAlign: "center",
          }}
        >
          <RoundButton handleClick={handleChangeDatetimePublish}>
            Publish Event
          </RoundButton>
        </div>
        <div
          style={{
            marginTop: 30,
            textAlign: "center",
          }}
        >
          <RoundButton
            handleClick={() => navigate(`/events/${eventDetail.id}/edit`)}
            backgroundColor={colors.orange}
          >
            Modify Event
          </RoundButton>
        </div>
      </CustomDialog>
      <CustomDialog open={expired} handleClose={() => setExpired(false)}>
        <div
          style={{
            fontSize: 24,
            fontWeight: 600,
          }}
        >
          Your event date has been expired.
          <br />
          But don’t worry!
        </div>
        <div
          style={{
            marginTop: 15,
            fontSize: 14,
            fontWeight: 500,
          }}
        >
          This event has already been approved. Once you change the date, your
          event will be public.
        </div>
        <div
          style={{
            marginTop: 30,
            textAlign: "center",
          }}
        >
          <RoundButton handleClick={() => setChangeDatetime(true)}>
            Change Date and Publish
          </RoundButton>
        </div>
      </CustomDialog>
    </>
  );
}

function StatusDisplayComponent() {
  const { eventDetail } = useContext(EventDetailContext);
  return (
    <div style={{ fontSize: 20, fontWeight: 600 }}>
      {eventDetail.event_payment_type === EventPaymentType.free && <>Free</>}
      {eventDetail.event_payment_type === EventPaymentType.paid && (
        <>${eventDetail.price.toLocaleString()} / Person</>
      )}
    </div>
  );
}

interface EventDetailContextProps {
  eventDetail: EventDetail;
  refresh: () => void;
  myEvent: boolean;
}

const EventDetailContext = createContext({} as EventDetailContextProps);

function EventsDetail() {
  const params = useParams();
  const { eventDetail, refresh } = useEventDetail({
    eventCode: String(params.eventCode),
  });
  const [type, setType] = useState<EventDetailType>(EventDetailType.info);

  if (!eventDetail) return null;

  const myEvent = eventDetail.mode === EventDetailMode.hosting;

  return (
    <EventDetailContext.Provider
      value={{
        eventDetail,
        refresh,
        myEvent,
      }}
    >
      <Helmet>
        <title>{eventDetail.title}</title>
        <meta property="og:title" content={eventDetail.title} />
        <meta property="og:description" content={eventDetail.description} />
        <meta property="og:image" content={eventDetail.images[0] || ""} />
        <meta property="og:url" content={window.location.href} />
      </Helmet>
      {type === EventDetailType.info && <EventDetailInfo />}
      {type === EventDetailType.checkout && (
        <EventDetailCheckout handleBack={() => setType(EventDetailType.info)} />
      )}
      {type === EventDetailType.info && (
        <>
          {eventDetail.mode === EventDetailMode.stranger && (
            <div
              style={{
                marginTop: "auto",
              }}
            >
              <Horizontal />
              <div
                style={{
                  padding: 30,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {eventDetail.reserved === eventDetail.capacity && (
                  <>
                    <div style={{ color: colors.green, opacity: 0.5 }}>
                      <div style={{ fontSize: 20, fontWeight: 600 }}>
                        Sold Out
                      </div>
                      <StatusDisplayComponent />
                    </div>
                    <WaitComponent />
                  </>
                )}
                {eventDetail.reserved < eventDetail.capacity && (
                  <>
                    <StatusDisplayComponent />
                    <VerificationWrapper
                      handleClick={() => setType(EventDetailType.checkout)}
                    >
                      <RoundButton backgroundColor={colors.orange}>
                        Reserve
                      </RoundButton>
                    </VerificationWrapper>
                  </>
                )}
              </div>
            </div>
          )}
        </>
      )}
    </EventDetailContext.Provider>
  );
}

export default EventsDetail;
