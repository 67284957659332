import React, { ReactNode } from "react";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { colors } from "../hooks/color";

interface AccordionViewProps {
  label: ReactNode;
  detail: ReactNode;
  color?: string;
}

function AccordionView({
  label,
  detail,
  color = colors.green,
}: AccordionViewProps) {
  return (
    <Accordion
      elevation={0}
      style={{
        backgroundColor: "inherit",
        color,
      }}
      disableGutters
      sx={{
        "&::before": {
          display: "none",
        },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon style={{ color }} />}
        sx={{
          padding: 0,
          minHeight: 0,
          "& .MuiAccordionSummary-content": {
            margin: 0,
          },
          alignItems: "flex-start",
        }}
      >
        <div style={{ fontSize: 14, fontWeight: 700 }}>{label}</div>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: 0 }}>
        <div style={{ fontSize: 14 }}>{detail}</div>
      </AccordionDetails>
    </Accordion>
  );
}

export default AccordionView;
