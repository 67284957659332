import { useEffect, useState } from "react";
import Horizontal from "../../components/Horizontal";
import Header from "../../components/Header";
import { useEventList } from "../../hooks/event";
import EventCard from "../../components/events/EventCard";
import { fetchData } from "../../api";
import Backward from "../../components/Backward";
import CustomImage from "../../components/CustomImage";
import StarIcon from "@mui/icons-material/Star";
import {
  formatDate,
  formatTwoDecimalNumber,
  timeFromToLabel,
} from "../../util";
import { useCurrentMode } from "../../hooks/mode";
import { useNavigate } from "react-router-dom";

interface HistoryEventDetailProps {
  eventId: number;
  handleBack: () => void;
}

interface HistoryEventItem {
  id: number;
  title: string;
  images: string[];
  host: {
    rating: number;
    firstname: string;
  };
  date: string;
  start_time: string;
  end_time: string;
  timezone: string;
  party_size: number;
  price: number;
  total: number;
}

function HistoryEventItemComponent({
  eventId,
  handleBack,
}: HistoryEventDetailProps) {
  const [eventItem, setEventItem] = useState<HistoryEventItem | null>(null);
  useEffect(() => {
    fetchData<HistoryEventItem>("GET", `/events/${eventId}/history`).then(
      setEventItem
    );
  }, [eventId]);
  if (!eventItem) return null;
  return (
    <>
      <Backward handleClick={handleBack} />
      <div style={{ padding: 30 }}>
        <div style={{ marginTop: 30, display: "flex", columnGap: 20 }}>
          <div style={{ flex: 1 }}>
            <CustomImage
              src={eventItem.images[0] || ""}
              width="100%"
              height="100%"
            />
          </div>
          <div style={{ flex: 1 }}>
            <div style={{ fontWeight: 700 }}>{eventItem.title}</div>
            <div
              style={{
                marginTop: 10,
                display: "flex",
                alignItems: "center",
                columnGap: 10,
              }}
            >
              <StarIcon />
              <div>{formatTwoDecimalNumber(eventItem.host.rating)}</div>
            </div>
            <div style={{ marginTop: 10 }}>
              Hosted by {eventItem.host.firstname}
            </div>
          </div>
        </div>
        <Horizontal marginTop={15} marginBottom={15} mode />
        <div style={{ fontWeight: 700 }}>Event details</div>
        <div>{formatDate(eventItem.date)}</div>
        <div>
          {timeFromToLabel(
            eventItem.start_time,
            eventItem.end_time,
            eventItem.timezone
          )}
        </div>
        <div>Party size: {eventItem.party_size}</div>
        <Horizontal marginTop={15} marginBottom={15} mode />
        <div style={{ fontWeight: 700 }}>Price details</div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            ${formatTwoDecimalNumber(eventItem.price)} x {eventItem.party_size}
          </div>
          <div>${formatTwoDecimalNumber(eventItem.total)}</div>
        </div>
        <Horizontal marginTop={15} marginBottom={15} />
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>Total (USD)</div>
          <div>${formatTwoDecimalNumber(eventItem.total)}</div>
        </div>
      </div>
    </>
  );
}

function History() {
  const { eventItems } = useEventList("history");
  const [eventId, setEventId] = useState<number>(0);
  const currentMode = useCurrentMode();
  const navigate = useNavigate();

  useEffect(() => {
    if (!eventId) return;
    if (currentMode === "guest") return;
    navigate(`/events/${eventId}`);
  }, [eventId, currentMode, navigate]);

  if (!eventItems) return null;
  if (currentMode === "guest" && eventId)
    return (
      <HistoryEventItemComponent
        eventId={eventId}
        handleBack={() => setEventId(0)}
      />
    );

  return (
    <>
      <Header label="History" handleBack={() => navigate("/profile")} />
      <div style={{ padding: 30 }}>
        <div style={{ fontWeight: 700 }}>Your past events</div>
        <Horizontal marginTop={15} mode />
        {eventItems.map((eventItem, index) => (
          <div key={index} style={{ marginTop: 20 }}>
            <EventCard
              eventItem={eventItem}
              handleClick={() => setEventId(eventItem.id)}
            />
          </div>
        ))}
      </div>
    </>
  );
}

export default History;
