import React from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as LocationSvg } from "../svgs/location.svg";
import Avatar from "./Avatar";
import { YesNo } from "../enums";
import { ReactComponent as DrinkMarkSvg } from "../svgs/drink_mark.svg";

interface EventAvatarCardProps {
  eventId: number;
  eventCode: string;
  image: string;
  eventType: string;
  cuisine: string;
  address: string;
  title: string;
  infoColor: string;
  titleColor: string;
  alcohol: YesNo;
}

function EventAvatarCard({
  eventId,
  eventCode,
  image,
  eventType,
  cuisine,
  address,
  title,
  infoColor,
  titleColor,
  alcohol,
}: EventAvatarCardProps) {
  const navigate = useNavigate();
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        marginTop: 30,
        cursor: "pointer",
      }}
      onClick={() => navigate(`/events/${eventCode}`)}
    >
      <div style={{ position: "relative" }}>
        <Avatar width={80} height={80} src={image} />
        {alcohol === YesNo.yes && (
          <div style={{ position: "absolute", top: 0, right: 0 }}>
            <DrinkMarkSvg />
          </div>
        )}
      </div>
      <div style={{ marginLeft: 5, flex: 1 }}>
        <div
          style={{
            color: infoColor,
            fontSize: 14,
          }}
        >
          <div>
            {eventType} • {cuisine}
          </div>
          <div
            style={{
              marginTop: 5,
              display: "flex",
              alignItems: "center",
              columnGap: 5,
            }}
          >
            <LocationSvg
              style={{
                width: 10,
                height: 14,
                fill: infoColor,
              }}
            />
            <div>{address}</div>
          </div>
        </div>
        <div style={{ marginTop: 5, color: titleColor }}>{title}</div>
      </div>
    </div>
  );
}

export default EventAvatarCard;
