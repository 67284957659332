import { fontFamily } from "../hooks/font";
import { colors, useColor } from "../hooks/color";
import { Fragment, useContext, useEffect, useState } from "react";
import { Room } from "../interfaces";
import { fetchData } from "../api";
import Horizontal from "../components/Horizontal";
import Avatar from "../components/Avatar";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "../App";
import { SocketMessageType, UserMode } from "../enums";
import { useCurrentMode } from "../hooks/mode";
import UnsignedView from "../components/UnsignedView";

interface RoomComponentProps {
  room: Room;
}

function RoomComponent({ room }: RoomComponentProps) {
  const navigate = useNavigate();
  return (
    <div
      style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
      onClick={() => navigate(`/rooms/${room.id}`)}
    >
      <Avatar width={70} height={70} src={room.image} />
      <div style={{ marginLeft: 30, fontSize: 16, flex: 1 }}>
        <div style={{ fontWeight: 600 }}>{room.title}</div>
        <div
          style={{
            marginTop: 5,
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: 2,
            WebkitBoxOrient: "vertical",
          }}
        >
          {room.message}
        </div>
      </div>
      <div style={{ marginLeft: 10 }}>
        {!room.read && (
          <div
            style={{
              width: 10,
              height: 10,
              borderRadius: "50%",
              backgroundColor: colors.orange,
            }}
          ></div>
        )}
      </div>
    </div>
  );
}

function SignedMessage() {
  const [rooms, setRooms] = useState<Room[] | null>([]);
  const { websocket } = useContext(GlobalContext);
  const [render, setRender] = useState<number>(0);
  const color = useColor();

  useEffect(() => {
    if (!websocket) return;
    websocket.onmessage = (event) => {
      // Handle incoming messages
      const message = JSON.parse(event.data);
      if (message.type !== SocketMessageType.room) return;
      setRender((render) => render + 1);
    };
  }, [websocket]);

  useEffect(() => {
    fetchData<Room[]>("GET", `/rooms`).then(setRooms);
  }, [render]);

  if (!rooms) return null;

  return (
    <div style={{ padding: 30 }}>
      <div
        style={{
          fontFamily: fontFamily.simula,
          fontSize: 24,
          color,
        }}
      >
        Message
      </div>
      <div style={{ marginTop: 30 }}>
        {rooms.map((room, index) => (
          <Fragment key={index}>
            {index > 0 && <Horizontal marginTop={30} marginBottom={30} />}
            <RoomComponent room={room} />
          </Fragment>
        ))}
      </div>
    </div>
  );
}

function Message() {
  const currentMode = useCurrentMode();
  if (currentMode === UserMode.unsigned)
    return (
      <UnsignedView
        label={
          <>
            Sign in or create an account
            <br />
            to see your messages.
          </>
        }
        redirect="/message"
      />
    );
  return <SignedMessage />;
}

export default Message;
