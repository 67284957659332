import { MenuItem, Select } from "@mui/material";
import React, { ReactNode } from "react";
import { colors } from "../hooks/color";
import { fontFamily } from "../hooks/font";

interface SelectItem {
  label: ReactNode;
  value: string;
  disabled?: boolean;
}

interface SelectInputProps {
  name?: string;
  value: string;
  label?: ReactNode;
  items: SelectItem[];
  handleChange?: (value: string) => void;
  fullWidth?: boolean;
  disabled?: boolean;
}

function SelectInput({
  name,
  value,
  label,
  items,
  handleChange,
  fullWidth = true,
  disabled,
}: SelectInputProps) {
  return (
    <div style={{ textAlign: "start" }}>
      <div style={{ opacity: disabled ? 0.3 : 1 }}>{label}</div>
      <div style={{ marginTop: 5 }}>
        <Select
          disabled={disabled}
          fullWidth={fullWidth}
          name={name}
          value={value}
          displayEmpty
          variant="outlined"
          onChange={(e) => handleChange && handleChange(e.target.value)}
          sx={{
            "&.MuiInputBase-root": {
              fontFamily: "Manrope",
              borderRadius: "10px",
            },
            "& fieldset": {
              borderColor: colors.green,
            },
          }}
        >
          <MenuItem value="" disabled>
            Choose One
          </MenuItem>
          {items.map((item, index) => (
            <MenuItem value={item.value} key={index} disabled={item.disabled}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </div>
    </div>
  );
}

export default SelectInput;
