import CustomImage from "../components/CustomImage";
import { colors } from "../hooks/color";
import { ReactComponent as LocationSvg } from "../svgs/location.svg";
import TimeUtil from "./time";

interface EventIntroLabelProps {
  eventType: string;
  cuisine: string;
  title: string;
  date: string;
  address: string;
  image: string;
}

export function EventIntroLabel({
  image,
  eventType,
  cuisine,
  title,
  date,
  address,
}: EventIntroLabelProps) {
  return (
    <div>
      <div>
        <CustomImage
          height={200}
          style={{
            borderRadius: 20,
          }}
          src={image}
        />
      </div>
      <div
        style={{
          marginTop: 30,
        }}
      >
        <div style={{ fontSize: 14 }}>
          <div>
            {eventType} • {cuisine}
          </div>

          <div
            style={{
              fontSize: 26,
              marginTop: 10,
              lineHeight: 1.25,
            }}
          >
            {title}
          </div>
          <div style={{ marginTop: 10 }}>{TimeUtil.formatDayMonth(date)}</div>
          <div style={{ marginTop: 10, display: "flex", alignItems: "center" }}>
            <LocationSvg
              style={{
                width: 10,
                height: 14,
                fill: colors.green,
              }}
            />
            <div style={{ marginLeft: 5 }}>{address}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
