import { useEffect, useState } from "react";
import { HostReview, UserReview } from "../interfaces";
import { fetchData } from "../api";

export function useUserReview(userId: number) {
  const [reviews, setReviews] = useState<UserReview[] | null>(null);
  const [render, setRender] = useState<number>(0);

  useEffect(() => {
    fetchData<UserReview[]>("GET", `/users/${userId}/reviews`).then(setReviews);
  }, [userId, render]);

  const refresh = () => setRender((render) => render + 1);

  return { reviews, refresh };
}

export function useHostReview(hostId: number) {
  const [reviews, setReviews] = useState<HostReview[] | null>(null);
  const [render, setRender] = useState<number>(0);

  useEffect(() => {
    fetchData<HostReview[]>("GET", `/hosts/${hostId}/reviews`).then(setReviews);
  }, [hostId, render]);

  const refresh = () => setRender((render) => render + 1);

  return { reviews, refresh };
}
