import React, { useContext, useEffect, useState } from "react";
import { fontFamily } from "../hooks/font";
import TextInput from "../inputs/TextInput";
import RoundButton from "../components/RoundButton";
import { colors } from "../hooks/color";
import { fetchData, setCredential } from "../api";
import { GlobalContext } from "../App";
import { useLocation, useNavigate } from "react-router-dom";

function PasswordReset() {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const { refreshUser } = useContext(GlobalContext);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    fetchData<{ email: string }>(
      "GET",
      `/password/reset?token=${searchParams.get("token")}`
    ).then(({ email }) => setEmail(email));
  }, [location]);

  const handleReset = () => {
    return fetchData<{ token: string }>("POST", "/password/reset/verify", {
      email,
      password,
      confirm_password: confirmPassword,
    })
      .then(({ token }) => setCredential(token))
      .then(refreshUser)
      .then(() => navigate("/"))
      .catch(setErrors);
  };

  if (!email) return null;

  return (
    <div
      style={{
        padding: "90px 30px",
      }}
    >
      <div
        style={{
          fontSize: 24,
          fontFamily: fontFamily.simula,
        }}
      >
        Create New Password
      </div>
      <div
        style={{
          marginTop: 50,
        }}
      >
        <TextInput name="email" label="Email" value={email} readOnly />
      </div>
      <div
        style={{
          marginTop: 15,
        }}
      >
        <TextInput
          name="password"
          type="password"
          label="Password"
          handleChange={setPassword}
          defaultValue={password}
          errorMessage={errors.password}
        />
      </div>
      <div
        style={{
          marginTop: 15,
        }}
      >
        <TextInput
          type="password"
          label="Confirm Password"
          handleChange={setConfirmPassword}
          defaultValue={confirmPassword}
          errorMessage={errors.confirm_password}
        />
      </div>
      <div
        style={{
          marginTop: 15,
          textAlign: "end",
        }}
      >
        <RoundButton
          backgroundColor={colors.green}
          color={colors.white}
          handleClick={handleReset}
        >
          Sign In
        </RoundButton>
      </div>
    </div>
  );
}

export default PasswordReset;
