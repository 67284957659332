import React, { ReactNode } from "react";

interface InfoComponentProps {
  label: ReactNode;
  handleEdit?: () => void;
  value: ReactNode;
}

function InfoComponent({ label, handleEdit, value }: InfoComponentProps) {
  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            fontWeight: 600,
          }}
        >
          {label}
        </div>
        {handleEdit && (
          <div
            style={{
              fontSize: 14,
              fontWeight: 600,
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={handleEdit}
          >
            Edit
          </div>
        )}
      </div>
      <div style={{ marginTop: 10, fontSize: 14 }}>{value}</div>
    </div>
  );
}

export default InfoComponent;
