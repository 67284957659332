import { useContext } from "react";
import NotificationRender from "./UserNotification";
import HostNotificationRender from "./HostNotification";
import { GlobalContext } from "../../App";
import { useCurrentMode } from "../../hooks/mode";
import { UserMode } from "../../enums";

function NotificationsComponent() {
  const { notifications, host_notifications } = useContext(GlobalContext);
  const currentMode = useCurrentMode();
  if (currentMode === UserMode.guest) {
    return (
      <>
        {notifications
          .filter((notification) => notification.meta.status === "unread")
          .map((notification) => (
            <NotificationRender
              notification={notification}
              key={notification.id}
            />
          ))}
      </>
    );
  }
  if (currentMode === UserMode.host) {
    return (
      <>
        {host_notifications
          .filter(
            (host_notification) => host_notification.meta.status === "unread"
          )
          .map((host_notification) => (
            <HostNotificationRender
              host_notification={host_notification}
              key={host_notification.id}
            />
          ))}
      </>
    );
  }
  return null;
}

export default NotificationsComponent;
