import { Rating } from "@mui/material";
import React from "react";
import { colors } from "../hooks/color";

interface CustomRatingProps {
  value: number;
  handleChange: (value: number) => void;
  style?: React.CSSProperties;
}

function CustomRating({ value, handleChange, style }: CustomRatingProps) {
  return (
    <Rating
      value={value}
      precision={1}
      onChange={(event, value) => {
        if (!value) return;
        handleChange(value);
      }}
      style={style}
      sx={{
        "&.MuiRating-root": {
          color: colors.green,
          fontSize: "40px",
          columnGap: "10px",
        },
      }}
    />
  );
}

export default CustomRating;
