import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { useUser, useUserHooks } from "../../hooks/user";
import SelectInput from "../../inputs/SelectInput";
import TextInput from "../../inputs/TextInput";
import Horizontal from "../../components/Horizontal";
import Header from "../../components/Header";
import { USStates, restrictions } from "../../const";
import { useNavigate } from "react-router-dom";
import RoundButton from "../../components/RoundButton";
import { colors } from "../../hooks/color";
import CustomSwitch from "../../inputs/CustomSwitch";
import CustomDrawer from "../../components/CustomDrawer";
import CustomDialog from "../../components/CustomDialog";
import { fontFamily } from "../../hooks/font";
import InfoComponent from "../../components/InfoComponent";
import PhoneUtil from "../../utils/phone";
import { ReactComponent as FacebookSvg } from "../../svgs/facebook.svg";
import { ReactComponent as InstagramSvg } from "../../svgs/instagram.svg";
import { ReactComponent as XSvg } from "../../svgs/x.svg";
import { ReactComponent as TiktokSvg } from "../../svgs/tiktok.svg";
import { UserSocialMedias } from "../../interfaces";
import { EventDisclosure } from "../../enums";
import { GlobalContext } from "../../App";

function DeleteAccountComponent() {
  const [open, setOpen] = useState<boolean>(false);
  const [confirmed, setConfirmed] = useState<boolean>(false);
  const { deleteUser, signout } = useUserHooks();
  const navigate = useNavigate();
  return (
    <>
      <div
        style={{
          fontWeight: 600,
          cursor: "pointer",
          display: "inline",
        }}
        onClick={() => setOpen(true)}
      >
        Delete Account
      </div>
      <CustomDialog open={open} handleClose={() => setOpen(false)}>
        <div
          style={{
            color: colors.orange,
            fontSize: 24,
            fontFamily: fontFamily.simula,
          }}
        >
          Are you sure you want to delete your account?
        </div>
        <div
          style={{
            marginTop: 15,
          }}
        >
          All your data will be deleted.
        </div>
        <div style={{ marginTop: 30, textAlign: "center" }}>
          <RoundButton
            color={colors.white}
            backgroundColor={colors.green}
            handleClick={() =>
              deleteUser()
                .then(() => setOpen(false))
                .then(() => setConfirmed(true))
            }
          >
            Delete
          </RoundButton>
        </div>
      </CustomDialog>
      <CustomDialog
        open={confirmed}
        handleClose={() => signout().then(() => navigate("/profile"))}
      >
        <div
          style={{
            color: colors.orange,
            fontSize: 24,
            fontFamily: fontFamily.simula,
          }}
        >
          we're sorry to see you go. Feel free to join us anytime.
        </div>
      </CustomDialog>
    </>
  );
}

function MealsAttendedComponent() {
  const { mealsAttended, handleSave } = useContext(AccountInfoContext);
  return (
    <div>
      <div
        style={{
          fontWeight: 600,
        }}
      >
        Meals Attended
      </div>
      <div
        style={{
          marginTop: 10,
          fontSize: 14,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {mealsAttended === EventDisclosure.public && <div>Public</div>}
        {mealsAttended === EventDisclosure.private && <div>Private</div>}
        <div>
          <CustomSwitch
            checked={mealsAttended === EventDisclosure.public}
            handleChange={(checked) => {
              let mealsAttended = "";
              if (checked) {
                mealsAttended = EventDisclosure.public;
              } else {
                mealsAttended = EventDisclosure.private;
              }
              handleSave({ meals_attended: mealsAttended });
            }}
            color={colors.green}
          />
        </div>
      </div>
    </div>
  );
}

interface SocialMediaEditComponentProps {
  svg: ReactNode;
  value: string;
  handleChange: (value: string) => void;
  handleSave: () => void;
}

function SocialMediaEditComponent({
  svg,
  value,
  handleChange,
  handleSave,
}: SocialMediaEditComponentProps) {
  const [edit, setEdit] = useState<boolean>(false);

  return (
    <div style={{ display: "flex", alignItems: "center", fontSize: 14 }}>
      {svg}
      <div style={{ marginLeft: 10 }}>
        {edit && (
          <TextInput
            defaultValue={value}
            handleChange={handleChange}
            sx={{
              "& input": {
                padding: "5px 10px",
                fontSize: "14px",
              },
            }}
          />
        )}
        {!edit && (
          <div
            style={{
              height: 35,
              display: "flex",
              alignItems: "center",
            }}
          >
            {value}
          </div>
        )}
      </div>
      <div
        style={{
          marginLeft: "auto",
          fontSize: 14,
          fontWeight: 600,
          textDecoration: "underline",
          cursor: "pointer",
        }}
        onClick={() => {
          if (!edit) {
            setEdit(true);
          } else {
            handleSave();
            setEdit(false);
          }
        }}
      >
        {edit ? "Save" : "Edit"}
      </div>
    </div>
  );
}

function SocialMediaComponent() {
  const { socialMedias, setSocialMedias, handleSave } =
    useContext(AccountInfoContext);
  const user = useUser();
  if (!user) return null;

  return (
    <>
      <div
        style={{
          fontWeight: 600,
        }}
      >
        Social media
      </div>
      <div style={{ marginTop: 10 }}>
        <SocialMediaEditComponent
          svg={<FacebookSvg style={{ width: 20 }} />}
          value={socialMedias.facebook}
          handleChange={(value) => {
            socialMedias.facebook = value;
            setSocialMedias({
              ...socialMedias,
            });
          }}
          handleSave={() =>
            handleSave({
              social_medias: socialMedias,
            })
          }
        />
      </div>
      <div style={{ marginTop: 10 }}>
        <SocialMediaEditComponent
          svg={<InstagramSvg style={{ width: 20 }} />}
          value={socialMedias.instagram}
          handleChange={(value) => {
            socialMedias.instagram = value;
            setSocialMedias({
              ...socialMedias,
            });
          }}
          handleSave={() =>
            handleSave({
              social_medias: socialMedias,
            })
          }
        />
      </div>
      <div style={{ marginTop: 10 }}>
        <SocialMediaEditComponent
          svg={<XSvg style={{ width: 20 }} />}
          value={socialMedias.x}
          handleChange={(value) => {
            socialMedias.x = value;
            setSocialMedias({
              ...socialMedias,
            });
          }}
          handleSave={() =>
            handleSave({
              social_medias: socialMedias,
            })
          }
        />
      </div>
      <div style={{ marginTop: 10 }}>
        <SocialMediaEditComponent
          svg={<TiktokSvg style={{ width: 20 }} />}
          value={socialMedias.tiktok}
          handleChange={(value) => {
            socialMedias.tiktok = value;
            setSocialMedias({
              ...socialMedias,
            });
          }}
          handleSave={() =>
            handleSave({
              social_medias: socialMedias,
            })
          }
        />
      </div>
    </>
  );
}

function RestrictionComponent() {
  const { restriction, handleSave } = useContext(AccountInfoContext);
  const [open, setOpen] = useState<boolean>(false);
  const [tempRestriction, setTempRestriction] = useState<string>(restriction);
  const user = useUser();
  if (!user) return null;
  return (
    <>
      <InfoComponent
        label="Dietary Restriction"
        value={restriction}
        handleEdit={() => setOpen(true)}
      />
      <CustomDrawer
        open={open}
        handleClose={() => setOpen(false)}
        height="90vh"
      >
        <div
          style={{ display: "flex", flexDirection: "column", height: "100%" }}
        >
          <div style={{ marginTop: 50 }}>
            <SelectInput
              name="restriction"
              label="Dietary restriction"
              value={tempRestriction}
              items={restrictions.map((restriction) => ({
                value: restriction,
                label: restriction,
              }))}
              handleChange={setTempRestriction}
            />
          </div>
          <div style={{ marginTop: "auto", textAlign: "center" }}>
            <RoundButton
              handleClick={() =>
                handleSave({ restriction: tempRestriction }).then(() =>
                  setOpen(false)
                )
              }
            >
              Save
            </RoundButton>
          </div>
        </div>
      </CustomDrawer>
    </>
    // <div>
    //   <SelectInput
    //     name="restriction"
    //     label="Dietary restriction"
    //     value={restriction ? String(restriction) : ""}
    //     items={restrictions.map((restriction) => ({
    //       value: String(restriction.id),
    //       label: restriction.label,
    //     }))}
    //     handleChange={(value) => setRestriction(Number(value))}
    //   />
    // </div>
  );
}

function BirthdayComponent() {
  const { birthday, handleSave } = useContext(AccountInfoContext);
  const [open, setOpen] = useState<boolean>(false);
  const [tempBirthday, setTempBirthday] = useState<string>(birthday);
  const user = useUser();
  if (!user) return null;
  return (
    <>
      <InfoComponent
        label="Birthday"
        value={birthday}
        handleEdit={() => setOpen(true)}
      />
      <CustomDrawer
        open={open}
        handleClose={() => setOpen(false)}
        height="90vh"
      >
        <div
          style={{ display: "flex", flexDirection: "column", height: "100%" }}
        >
          <div style={{ marginTop: 30 }}>
            <TextInput
              type="date"
              label="Birthday"
              handleChange={setTempBirthday}
              defaultValue={user.meta.birthday}
            />
          </div>
          <div style={{ marginTop: "auto", textAlign: "center" }}>
            <RoundButton
              handleClick={() =>
                handleSave({ email: tempBirthday }).then(() => setOpen(false))
              }
            >
              Save
            </RoundButton>
          </div>
        </div>
      </CustomDrawer>
    </>
    // <div>
    //   <TextInput
    //     type="date"
    //     label="Birthday"
    //     readOnly
    //     defaultValue={user.meta.birthday}
    //   />
    // </div>
  );
}

function AddressComponent() {
  const { address1, address2, city, state, zipcode, handleSave } =
    useContext(AccountInfoContext);
  const [open, setOpen] = useState<boolean>(false);
  const [tempAddress1, setTempAddress1] = useState<string>(address1);
  const [tempAddress2, setTempAddress2] = useState<string>(address2);
  const [tempCity, setTempCity] = useState<string>(city);
  const [tempState, setTempState] = useState<string>(state);
  const [tempZipcode, setTempZipcode] = useState<string>(zipcode);
  const user = useUser();
  if (!user) return null;
  return (
    <>
      <InfoComponent
        label="Address"
        value={
          <>
            <div>{address1}</div>
            <div>{address2}</div>
            <div>
              {city}, {state} {zipcode}
            </div>
          </>
        }
        handleEdit={() => setOpen(true)}
      />
      <CustomDrawer
        open={open}
        handleClose={() => setOpen(false)}
        height="90vh"
      >
        <div style={{ marginTop: 30 }}>
          <TextInput
            label="Street Address"
            defaultValue={user.meta.address1}
            handleChange={setTempAddress1}
          />
        </div>
        <div style={{ marginTop: 20 }}>
          <TextInput
            label="Apt. / Suite Number (Optional)"
            defaultValue={user.meta.address2}
            handleChange={setTempAddress2}
          />
        </div>
        <div style={{ marginTop: 20 }}>
          <TextInput
            label="City"
            defaultValue={user.meta.city}
            handleChange={setTempCity}
          />
        </div>
        <div style={{ marginTop: 20 }}>
          <SelectInput
            name="state"
            label="State"
            value={tempState}
            items={USStates.map((state) => ({
              value: state,
              label: state,
            }))}
            handleChange={setTempState}
          />
        </div>
        <div style={{ marginTop: 20 }}>
          <TextInput
            label="ZipCode"
            defaultValue={user.meta.zipcode}
            handleChange={setTempZipcode}
          />
        </div>
        <div style={{ marginTop: 30, textAlign: "center" }}>
          <RoundButton
            handleClick={() =>
              handleSave({
                address1: tempAddress1,
                address2: tempAddress2,
                city: tempCity,
                state: tempState,
                zipcode: tempZipcode,
              }).then(() => setOpen(false))
            }
          >
            Save
          </RoundButton>
        </div>
      </CustomDrawer>
    </>
    // <>
    //   <div>
    //     <TextInput
    //       label="Address line 1"
    //       defaultValue={user.meta.address1}
    //       handleChange={setAddress1}
    //     />
    //   </div>
    //   <div style={{ marginTop: 15 }}>
    //     <TextInput
    //       label="Address line 2 (optional)"
    //       defaultValue={user.meta.address2}
    //       handleChange={setAddress2}
    //     />
    //   </div>
    //   <div style={{ marginTop: 15 }}>
    //     <TextInput
    //       label="City"
    //       defaultValue={user.meta.city}
    //       handleChange={setCity}
    //     />
    //   </div>
    //   <div style={{ marginTop: 15 }}>
    //     <SelectInput
    //       name="state"
    //       label="State"
    //       value={state}
    //       items={USStates.map((state) => ({
    //         value: state,
    //         label: state,
    //       }))}
    //       handleChange={setState}
    //     />
    //   </div>
    //   <div style={{ marginTop: 15 }}>
    //     <TextInput
    //       label="Zip/ post code"
    //       defaultValue={user.meta.zipcode}
    //       handleChange={setZipcode}
    //     />
    //   </div>
    // </>
  );
}

function PhoneComponent() {
  const user = useUser();
  if (!user) return null;
  return (
    <InfoComponent
      label="Phone Number"
      value={PhoneUtil.formatPhoneNumber(user.meta.phone)}
    />
  );
}

function EmailComponent() {
  const { email, handleSave } = useContext(AccountInfoContext);
  const [open, setOpen] = useState<boolean>(false);
  const [tempEmail, setTempEmail] = useState<string>(email);
  const user = useUser();

  if (!user) return null;
  return (
    <>
      <InfoComponent
        label="Email"
        value={email}
        // just for now, remove to change email
        // handleEdit={() => setOpen(true)}
      />
      <CustomDrawer
        open={open}
        handleClose={() => setOpen(false)}
        height="90vh"
      >
        <div
          style={{ display: "flex", flexDirection: "column", height: "100%" }}
        >
          <div style={{ marginTop: 30 }}>
            We’ll send you a confirmation email to your new email address.
          </div>
          <div style={{ marginTop: 30 }}>
            <TextInput
              type="email"
              label="Email"
              handleChange={setTempEmail}
              defaultValue={user.meta.email}
            />
          </div>
          <div style={{ marginTop: "auto", textAlign: "center" }}>
            <RoundButton
              handleClick={() =>
                handleSave({ email: tempEmail }).then(() => setOpen(false))
              }
            >
              Save
            </RoundButton>
          </div>
        </div>
      </CustomDrawer>
    </>
    // <div>
    //   <TextInput
    //     type="email"
    //     label="Email"
    //     handleChange={setEmail}
    //     defaultValue={user.meta.email}
    //   />
    // </div>
  );
}

function NameComponent() {
  const user = useUser();
  if (!user) return null;
  return (
    <InfoComponent
      label="Name"
      value={`${user.meta.firstname} ${user.meta.lastname}`}
    />
  );
}

interface AccountInfoContextProps {
  email: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zipcode: string;
  birthday: string;
  restriction: string;
  socialMedias: UserSocialMedias;
  mealsAttended: EventDisclosure;
  setEmail: (email: string) => void;
  setAddress1: (address1: string) => void;
  setAddress2: (address2: string) => void;
  setCity: (city: string) => void;
  setState: (state: string) => void;
  setZipcode: (zipcode: string) => void;
  setBirthday: (birthday: string) => void;
  setRestriction: (restriction: string) => void;
  setSocialMedias: (socialMedias: UserSocialMedias) => void;
  setMealsAttended: (mealsAttended: EventDisclosure) => void;
  handleSave: (body: object) => Promise<any>;
}

const AccountInfoContext = createContext({} as AccountInfoContextProps);

function AccountInfo() {
  const [email, setEmail] = useState<string>("");
  const [address1, setAddress1] = useState<string>("");
  const [address2, setAddress2] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [state, setState] = useState<string>("");
  const [zipcode, setZipcode] = useState<string>("");
  const [birthday, setBirthday] = useState<string>("");
  const [restriction, setRestriction] = useState<string>("");
  const [socialMedias, setSocialMedias] = useState<UserSocialMedias>({
    facebook: "",
    instagram: "",
    x: "",
    tiktok: "",
  });
  const [mealsAttended, setMealsAttended] = useState<EventDisclosure>(
    EventDisclosure.public
  );
  const { putUserProfile } = useUserHooks();
  const navigate = useNavigate();
  const user = useUser();
  const { refreshUser } = useContext(GlobalContext);

  useEffect(() => {
    if (!user) return;
    setEmail(user.meta.email);
    setAddress1(user.meta.address1);
    setAddress2(user.meta.address2);
    setCity(user.meta.city);
    setState(user.meta.state);
    setZipcode(user.meta.zipcode);
    setBirthday(user.meta.birthday);
    setRestriction(user.meta.restriction);
    setSocialMedias(
      user.meta.social_medias || {
        facebook: "",
        instagram: "",
        x: "",
        tiktok: "",
      }
    );
    setMealsAttended(user.meta.meals_attended || EventDisclosure.public);
  }, [user]);

  const handleSave = (body: object) => {
    return putUserProfile({
      email,
      address1,
      address2,
      city,
      state,
      zipcode,
      birthday,
      restriction,
      social_medias: socialMedias,
      meals_attended: mealsAttended,
      ...body,
    }).then(refreshUser);
  };

  if (!user) return null;

  return (
    <AccountInfoContext.Provider
      value={{
        email,
        address1,
        address2,
        city,
        state,
        zipcode,
        birthday,
        restriction,
        socialMedias,
        mealsAttended,
        setEmail,
        setAddress1,
        setAddress2,
        setCity,
        setState,
        setZipcode,
        setBirthday,
        setRestriction,
        setSocialMedias,
        setMealsAttended,
        handleSave,
      }}
    >
      <Header label="Account info" handleBack={() => navigate("/profile")} />
      <div style={{ padding: 30 }}>
        <NameComponent />
        <Horizontal marginTop={30} marginBottom={15} />
        <EmailComponent />
        <Horizontal marginTop={30} marginBottom={15} />
        <PhoneComponent />
        <Horizontal marginTop={30} marginBottom={15} />
        <AddressComponent />
        <Horizontal marginTop={30} marginBottom={15} />
        <BirthdayComponent />
        <Horizontal marginTop={30} marginBottom={15} />
        <RestrictionComponent />
        <Horizontal marginTop={30} marginBottom={15} />
        <SocialMediaComponent />
        <Horizontal marginTop={30} marginBottom={15} />
        <MealsAttendedComponent />
        <Horizontal marginTop={30} marginBottom={15} />
        <DeleteAccountComponent />
      </div>
    </AccountInfoContext.Provider>
  );
}

export default AccountInfo;
