import { useContext, useEffect, useState } from "react";
import { useEventDetail } from "../../hooks/event";
import { fetchData } from "../../api";
import CustomImage from "../CustomImage";
import CustomRating from "../CustomRating";
import TextInput from "../../inputs/TextInput";
import { colors } from "../../hooks/color";
import { useHostNotificationHooks } from "../../hooks/notification";
import {
  EventDetail,
  EventDetailReservation,
  HostNotification,
} from "../../interfaces";
import CustomDialog from "../CustomDialog";
import RoundButton from "../RoundButton";
import Backward from "../Backward";
import UserAvatar from "../UserAvatar";
import { GlobalContext } from "../../App";
import { fontFamily } from "../../hooks/font";
import EventInfo from "../../pages/events/EventInfo";
import { useNavigate } from "react-router-dom";
import { HostNotificationType } from "../../enums";
import { RejectComponent } from "../../pages/events/EventsDetail";

interface UserComponentProps {
  handleClick: (reservation: EventDetailReservation) => void;
  eventDetail: EventDetail;
  userIdRatingReviews: Record<string, RatingReview>;
}

function UserComponent({
  handleClick,
  eventDetail,
  userIdRatingReviews,
}: UserComponentProps) {
  return (
    <>
      {eventDetail.reservations.map((reservation, index) => (
        <div
          key={index}
          style={{ display: "flex", alignItems: "center", marginTop: 15 }}
        >
          <UserAvatar
            key={index}
            firstname={reservation.firstname}
            image={reservation.image}
            partySize={reservation.party_size}
            userId={reservation.user_id}
          />
          <div
            style={{
              marginLeft: "auto",
              cursor: "pointer",
            }}
            onClick={() => handleClick(reservation)}
          >
            {!userIdRatingReviews[reservation.user_id] && (
              <span
                style={{
                  textDecoration: "underline",
                }}
              >
                Rate and Review
              </span>
            )}
            {userIdRatingReviews[reservation.user_id] && (
              <span style={{ color: colors.orange }}>Rated</span>
            )}
          </div>
        </div>
      ))}
    </>
  );
}

interface RateSelectedComponentProps {
  selected: EventDetailReservation;
  setSelected: (selected: EventDetailReservation | null) => void;
  handleSave: (rating: number, review: string) => void;
  userIdRatingReviews: Record<string, RatingReview>;
}

function RateSelectedComponent({
  selected,
  setSelected,
  handleSave,
  userIdRatingReviews,
}: RateSelectedComponentProps) {
  const [rating, setRating] = useState<number>(5);
  const [review, setReview] = useState<string>("");
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    try {
      if (!selected) return;
      if (!userIdRatingReviews[selected.user_id]) return;
      setRating(userIdRatingReviews[selected.user_id].rating);
      setReview(userIdRatingReviews[selected.user_id].review);
    } finally {
      setLoaded(true);
    }
  }, [selected, userIdRatingReviews]);

  if (!loaded) return null;

  return (
    <>
      <Backward
        handleClick={() => setSelected(null)}
        type="outlined"
        style={{
          top: 15,
          left: 15,
        }}
      />
      <div style={{ textAlign: "center" }}>
        <UserAvatar
          firstname={selected.firstname}
          image={selected.image}
          partySize={selected.party_size}
          userId={selected.user_id}
        />
        <div style={{ marginTop: 30 }}>
          <CustomRating value={rating} handleChange={setRating} />
        </div>
        <div style={{ marginTop: 30 }}>
          <TextInput
            multiline
            rows={5}
            placeholder="Write review"
            handleChange={setReview}
            defaultValue={review}
          />
        </div>
        <div style={{ marginTop: 30 }}>
          <RoundButton handleClick={() => handleSave(rating, review)}>
            Save
          </RoundButton>
        </div>
      </div>
    </>
  );
}

interface RateAllComponentProps {
  handleBack: () => void;
  eventDetail: EventDetail;
  handleRateAll: (rating: number, review: string) => void;
}

function RateAllComponent({
  handleBack,
  eventDetail,
  handleRateAll,
}: RateAllComponentProps) {
  const [rating, setRating] = useState<number>(5);
  const [review, setReview] = useState<string>("");

  return (
    <>
      <Backward
        handleClick={handleBack}
        type="outlined"
        style={{
          top: 15,
          left: 15,
        }}
      />
      <div style={{ textAlign: "center" }}>
        <div style={{ fontSize: 20, fontWeight: 600 }}>
          Rate all your guests at once.
        </div>
        <div
          style={{
            marginTop: 30,
            display: "flex",
            columnGap: 20,
            overflow: "auto",
          }}
        >
          {eventDetail.reservations.map((reservation, index) => (
            <UserAvatar
              key={index}
              firstname={reservation.firstname}
              image={reservation.image}
              partySize={reservation.party_size}
              userId={reservation.user_id}
            />
          ))}
        </div>
        <div style={{ marginTop: 30 }}>
          <CustomRating value={rating} handleChange={setRating} />
        </div>
        <div style={{ marginTop: 30 }}>
          <TextInput
            multiline
            rows={5}
            placeholder="Write review"
            handleChange={setReview}
          />
        </div>
        <div style={{ marginTop: 30 }}>
          <RoundButton handleClick={() => handleRateAll(rating, review)}>
            Submit
          </RoundButton>
        </div>
      </div>
    </>
  );
}

interface HostNotificationComponentProps {
  host_notification: HostNotification;
}

interface RatingReview {
  rating: number;
  review: string;
}

function UserRatingNotificationComponent({
  host_notification,
}: HostNotificationComponentProps) {
  const [open, setOpen] = useState<boolean>(true);
  const [userIdRatingReviews, setUserIdRatingReviews] = useState<
    Record<string, RatingReview>
  >({});
  const eventId = host_notification.meta.event_id;
  const { eventDetail } = useEventDetail({ eventId });
  const { patchHostNotificationRead } = useHostNotificationHooks();
  const [rateAll, setRateAll] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [selected, setSelected] = useState<EventDetailReservation | null>(null);
  const { refreshUser } = useContext(GlobalContext);

  const handleRateAll = (rating: number, review: string) => {
    if (!eventDetail) return;
    for (const reservation of eventDetail.reservations) {
      userIdRatingReviews[reservation.user_id] = {
        rating,
        review,
      };
    }
    return fetchData("POST", `/events/${eventId}/ratings`, {
      user_id_ratings: userIdRatingReviews,
    }).then(() => setSubmitted(true));
  };

  const handleSubmit = () => {
    return fetchData("POST", `/events/${eventId}/ratings`, {
      user_id_ratings: userIdRatingReviews,
    }).then(() => setSubmitted(true));
  };

  const handleClose = () => {
    return patchHostNotificationRead(host_notification.id)
      .then(() => setOpen(false))
      .then(refreshUser);
  };

  if (!eventDetail) return null;

  return (
    <CustomDialog open={open} handleClose={handleClose} fullHeight>
      <div>
        <div style={{ marginTop: 30 }}>
          <CustomImage
            height={180}
            style={{ borderRadius: 20 }}
            src={eventDetail.images[0] || ""}
          />
        </div>
        <div style={{ marginTop: 30 }}>
          {submitted && (
            <>
              <div
                style={{ fontSize: 20, fontWeight: 600, textAlign: "center" }}
              >
                Thank you for rating!
              </div>
              <div style={{ marginTop: 30, textAlign: "center" }}>
                <RoundButton handleClick={handleClose}>Close</RoundButton>
              </div>
            </>
          )}
          {!submitted && (
            <>
              {rateAll && (
                <RateAllComponent
                  handleBack={() => setRateAll(false)}
                  eventDetail={eventDetail}
                  handleRateAll={(rating, review) =>
                    handleRateAll(rating, review)
                  }
                />
              )}
              {!selected && !rateAll && (
                <>
                  <div style={{ fontSize: 20, fontWeight: 600 }}>
                    {eventDetail.title}
                  </div>
                  <div style={{ marginTop: 30, textWrap: "wrap" }}>
                    How was your experience with your guests?
                  </div>
                  <div
                    style={{
                      marginTop: 30,
                      textDecoration: "underline",
                      cursor: "pointer",
                      display: "inline-block",
                      fontWeight: 600,
                    }}
                    onClick={() => setRateAll(true)}
                  >
                    Rate All
                  </div>
                  <div
                    style={{ overflow: "auto", height: 250, fontWeight: 600 }}
                  >
                    <UserComponent
                      eventDetail={eventDetail}
                      handleClick={setSelected}
                      userIdRatingReviews={userIdRatingReviews}
                    />
                  </div>
                  <div style={{ marginTop: 30, textAlign: "center" }}>
                    <RoundButton handleClick={handleSubmit}>Submit</RoundButton>
                  </div>
                </>
              )}
              {selected && (
                <RateSelectedComponent
                  selected={selected}
                  setSelected={setSelected}
                  handleSave={(rating, review) => {
                    userIdRatingReviews[selected.user_id] = {
                      rating,
                      review,
                    };
                    setUserIdRatingReviews({
                      ...userIdRatingReviews,
                    });
                    setSelected(null);
                  }}
                  userIdRatingReviews={userIdRatingReviews}
                />
              )}
            </>
          )}
        </div>
      </div>
    </CustomDialog>
  );
}

function EventStatusNotificationComponent({
  host_notification,
}: HostNotificationComponentProps) {
  const [open, setOpen] = useState<boolean>(true);
  const { refreshUser } = useContext(GlobalContext);
  const { patchHostNotificationRead } = useHostNotificationHooks();
  const { eventDetail } = useEventDetail({
    eventId: host_notification.meta.event_id,
  });
  const navigate = useNavigate();

  const handleClose = () => {
    return patchHostNotificationRead(host_notification.id)
      .then(() => setOpen(false))
      .then(refreshUser);
  };

  if (!eventDetail) return null;

  return (
    <CustomDialog open={open} handleClose={handleClose}>
      <div
        style={{
          fontFamily: fontFamily.simula,
          color: colors.orange,
          fontSize: 24,
        }}
      >
        {host_notification.meta.type === HostNotificationType.event_ready && (
          <>
            Congratulations!
            <br />
            Your event is approved
          </>
        )}
        {host_notification.meta.type === HostNotificationType.event_rejected &&
          "Update on Your Event"}
        {host_notification.meta.type === HostNotificationType.event_expired &&
          "Event Expired"}
      </div>
      {host_notification.meta.type === HostNotificationType.event_expired && (
        <div style={{ marginTop: 10 }}>
          This event has been expired. Please update the date and request to
          publish.
        </div>
      )}
      <div style={{ marginTop: 30 }}>
        <CustomImage
          height={180}
          style={{ borderRadius: 20 }}
          src={eventDetail.images[0] || ""}
        />
      </div>
      <div style={{ marginTop: 30 }}>
        <EventInfo
          eventType={eventDetail.event_type}
          cuisine={eventDetail.cuisine}
          title={eventDetail.title}
        />
      </div>
      {host_notification.meta.type === HostNotificationType.event_rejected && (
        <div style={{ marginTop: 30 }}>
          <div style={{ color: colors.red }}>
            This event has been rejected due to the following reasons. Please
            make edits to your event and request to publish again.
          </div>
          <div style={{ marginTop: 10 }}>
            <RejectComponent
              reject={eventDetail.reject}
              reject_detail={eventDetail.reject_detail}
            />
          </div>
        </div>
      )}
      <div style={{ marginTop: 30, textAlign: "center" }}>
        <RoundButton
          handleClick={() => {
            navigate(`/events/${eventDetail.event_code}`);
            handleClose();
          }}
        >
          Review Event
        </RoundButton>
      </div>
    </CustomDialog>
  );
}

interface HostNotificationRenderProps {
  host_notification: HostNotification;
}

function HostNotificationRender({
  host_notification,
}: HostNotificationRenderProps) {
  if (host_notification.meta.type === "user_rating")
    return (
      <UserRatingNotificationComponent host_notification={host_notification} />
    );
  if (
    [
      HostNotificationType.event_ready,
      HostNotificationType.event_rejected,
      HostNotificationType.event_expired,
    ].includes(host_notification.meta.type)
  )
    return (
      <EventStatusNotificationComponent host_notification={host_notification} />
    );
  return null;
}

export default HostNotificationRender;
