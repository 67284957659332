import React, {
  Fragment,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import RoundButton from "./RoundButton";
import CustomSwitch from "../inputs/CustomSwitch";
import { colors } from "../hooks/color";
import Horizontal from "./Horizontal";
import { EventItem, HostReview, User, UserReview } from "../interfaces";
import { fetchData } from "../api";
import { fontFamily } from "../hooks/font";
import Avatar from "./Avatar";
import TimeUtil from "../utils/time";
import CustomImage from "./CustomImage";
import { ReactComponent as PaypalSvg } from "../svgs/paypal.svg";
import { ReactComponent as VenmoSvg } from "../svgs/venmo.svg";
import InfoComponent from "./InfoComponent";
import YellowComponent from "./YellowComponent";
import StarIcon from "@mui/icons-material/Star";
import { ReactComponent as FacebookSvg } from "../svgs/facebook.svg";
import { ReactComponent as InstagramSvg } from "../svgs/instagram.svg";
import { ReactComponent as XSvg } from "../svgs/x.svg";
import { ReactComponent as TiktokSvg } from "../svgs/tiktok.svg";
import PhoneUtil from "../utils/phone";
import ReadMore from "./ReadMore";
import { PaymentMethod, UserMode } from "../enums";
import { useNavigate } from "react-router";
import ImageUpload from "../inputs/ImageUpload";
import { useImageUpload } from "../hooks/image";
import { useUser, useUserHooks } from "../hooks/user";
import { GlobalContext } from "../App";
import ReviewCard from "./ReviewCard";

interface AvatarComponentProps {
  src?: string;
  firstname?: string;
  lastname?: string;
  facebook?: string;
  instagram?: string;
  x?: string;
  tiktok?: string;
  avatarEditable?: boolean;
  refresh: () => void;
  userMode: UserMode;
}

function AvatarComponent({
  src,
  firstname,
  lastname,
  facebook,
  instagram,
  x,
  tiktok,
  avatarEditable,
  refresh,
  userMode,
}: AvatarComponentProps) {
  const { imageUpload } = useImageUpload();
  const { putUserImage } = useUserHooks();
  const { refreshUser } = useContext(GlobalContext);
  const handleOpen = (link: string) => {
    return window.open(link, "_blank");
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div>
        {!avatarEditable && <Avatar width={92} height={92} src={src} />}
        {avatarEditable && (
          <ImageUpload
            handleChange={(file) =>
              imageUpload(file).then(({ location }) =>
                putUserImage(location).then(refresh).then(refreshUser)
              )
            }
          >
            <Avatar width={92} height={92} src={src} />
          </ImageUpload>
        )}
      </div>
      <div
        style={{
          marginLeft: 30,
          fontSize: 24,
          fontWeight: 600,
        }}
      >
        <div>
          {firstname} {lastname}
        </div>
        <div style={{ marginTop: 5, fontSize: 14 }}>
          {userMode === UserMode.guest && "Guest"}
          {userMode === UserMode.host && "Host"}
          {userMode === UserMode.admin && "Admin"}
        </div>
        <div
          style={{
            marginTop: 5,
            columnGap: 10,
            display: "flex",
            fill: colors.green,
            alignItems: "center",
          }}
        >
          {facebook && (
            <FacebookSvg
              style={{ height: 20, cursor: "pointer" }}
              onClick={() => handleOpen(`https://www.facebook.com/${facebook}`)}
            />
          )}
          {instagram && (
            <InstagramSvg
              style={{ height: 20, cursor: "pointer" }}
              onClick={() =>
                handleOpen(`https://www.instagram.com/${instagram}`)
              }
            />
          )}
          {x && (
            <XSvg
              style={{ height: 20, cursor: "pointer" }}
              onClick={() => handleOpen(`https://twitter.com/${x}`)}
            />
          )}
          {tiktok && (
            <TiktokSvg
              style={{ height: 20, cursor: "pointer" }}
              onClick={() => handleOpen(`https://www.tiktok.com/@${tiktok}`)}
            />
          )}
        </div>
      </div>
    </div>
  );
}

interface YellowInfoComponentProps {
  meals: number;
  rating?: number;
  year: number;
  mode: UserMode;
}

export function YellowInfoComponent({
  meals,
  rating,
  year,
  mode,
}: YellowInfoComponentProps) {
  return (
    <>
      <YellowComponent>
        <div
          style={{
            display: "flex",
            fontSize: 24,
            fontWeight: 600,
            alignItems: "center",
          }}
        >
          <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
            {meals}
          </div>
          {rating !== undefined && (
            <>
              <div
                style={{
                  width: 1,
                  height: 30,
                  backgroundColor: colors.green,
                }}
              ></div>
              <div
                style={{
                  flex: 1,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {rating === 0 && "-"}
                {rating > 0 && (
                  <>
                    {rating.toFixed(1)} <StarIcon style={{ marginLeft: 5 }} />
                  </>
                )}
              </div>
            </>
          )}
          <div
            style={{ width: 1, height: 30, backgroundColor: colors.green }}
          ></div>
          <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
            {year}
          </div>
        </div>
      </YellowComponent>
      <div
        style={{
          marginTop: 10,
          padding: 10,
          display: "flex",
          fontSize: 12,
          fontWeight: 600,
        }}
      >
        <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
          {mode === UserMode.guest && "Meals at Tably"}
          {mode === UserMode.host && "Meals Hosted"}
        </div>
        <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
          Rating
        </div>
        <div style={{ flex: 1, display: "flex", justifyContent: "center" }}>
          Year on Tably
        </div>
      </div>
    </>
  );
}

interface UserInfoComponentProps {
  src: string;
  firstname: string;
  created_at: string;
  rating: number;
}

export function UserInfoComponent({
  src,
  firstname,
  created_at,
  rating,
}: UserInfoComponentProps) {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div>
        <Avatar width={50} height={50} src={src} />
      </div>
      <div style={{ flex: 1, marginLeft: 15 }}>
        <div>{firstname}</div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>{TimeUtil.formatDateToMonthsAgo(created_at)}</div>
          <div>{rating.toFixed(1)}★</div>
        </div>
      </div>
    </div>
  );
}

interface UserReviewComponentProps {
  name: string;
  reviews: UserReview[];
}

function UserReviewComponent({ name, reviews }: UserReviewComponentProps) {
  if (reviews.length === 0) return null;
  return (
    <>
      <div
        style={{
          color: colors.orange,
          fontSize: 24,
          fontFamily: fontFamily.simula,
        }}
      >
        What Hosts Say About {name}
      </div>
      <div
        style={{
          marginTop: 15,
          display: "flex",
          columnGap: 20,
          overflow: "auto",
          marginLeft: -30,
          marginRight: -30,
        }}
      >
        <div style={{ paddingLeft: 15 }}></div>
        {reviews.map((review, index) => (
          <div key={index}>
            <ReviewCard
              src={review.image}
              firstname={review.firstname}
              created_at={review.created_at}
              rating={review.rating}
              review={review.review}
            />
          </div>
        ))}
        <div style={{ paddingRight: 15 }}></div>
      </div>
    </>
  );
}

interface HostReviewComponentProps {
  name: string;
  reviews: HostReview[];
}

function HostReviewComponent({ name, reviews }: HostReviewComponentProps) {
  if (reviews.length === 0) return null;
  return (
    <>
      <div
        style={{
          color: colors.orange,
          fontSize: 24,
          fontFamily: fontFamily.simula,
        }}
      >
        What Users Say About {name}
      </div>
      <div
        style={{
          marginTop: 15,
          display: "flex",
          columnGap: 20,
          overflow: "auto",
        }}
      >
        {reviews.map((review, index) => (
          <div key={index}>
            <ReviewCard
              src={review.image}
              firstname={review.firstname}
              created_at={review.created_at}
              rating={review.rating}
              review={review.review}
            />
          </div>
        ))}
      </div>
    </>
  );
}

interface AttendedCardProps {
  event_code: string;
  image: string;
  date: string;
  title: string;
}

function EventCard({ event_code, image, date, title }: AttendedCardProps) {
  const navigate = useNavigate();
  return (
    <div
      style={{
        position: "relative",
        cursor: "pointer",
      }}
      onClick={() => navigate(`/events/${event_code}`)}
    >
      <CustomImage
        width={300}
        height={200}
        style={{
          borderRadius: 20,
        }}
        src={image}
      />
      <div
        style={{
          position: "absolute",
          top: 15,
          left: 15,
        }}
      >
        <RoundButton
          backgroundColor={colors.yellow}
          color={colors.green}
          style={{
            padding: "5px 20px",
            fontSize: 14,
          }}
        >
          {TimeUtil.formatDateToMonthYear(date)}
        </RoundButton>
      </div>
      <div
        style={{
          position: "absolute",
          bottom: 15,
          left: 15,
          color: colors.white,
          fontWeight: 700,
        }}
      >
        {title}
      </div>
    </div>
  );
}

interface EventsWithLabelProps {
  label: string;
  events: EventItem[];
}

function EventsWithLabel({ label, events }: EventsWithLabelProps) {
  if (events.length === 0) return null;
  return (
    <>
      <div
        style={{
          color: colors.orange,
          fontSize: 24,
          fontFamily: fontFamily.simula,
        }}
      >
        {label}
      </div>
      <div
        style={{
          marginTop: 15,
          display: "flex",
          columnGap: 20,
          overflow: "auto",
          marginLeft: -30,
          marginRight: -30,
        }}
      >
        <div style={{ paddingLeft: 15 }}></div>
        {events.map((event, index) => (
          <div key={index}>
            <EventCard
              key={index}
              event_code={event.event_code}
              image={event.image}
              title={event.title}
              date={event.date}
            />
          </div>
        ))}
        <div style={{ paddingRight: 15 }}></div>
      </div>
    </>
  );
}

interface TotalSpentProps {
  totalSpent: number;
}

function TotalSpent({ totalSpent }: TotalSpentProps) {
  return (
    <div
      style={{
        fontSize: 14,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div>Total Spent</div>
      <div style={{ fontSize: 24, fontWeight: 600 }}>
        ${totalSpent.toLocaleString()}
      </div>
    </div>
  );
}

interface TotalEarnedProps {
  totalEarned: number;
}

function TotalEarned({ totalEarned }: TotalEarnedProps) {
  return (
    <div
      style={{
        fontSize: 14,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div>Total Earned</div>
      <div style={{ fontSize: 24, fontWeight: 600 }}>
        ${totalEarned.toLocaleString()}
      </div>
    </div>
  );
}

interface PaymentAccountsProps {
  payoutMethod: PaymentMethod;
  payoutAccount: string;
}

function PaymentAccounts({
  payoutMethod,
  payoutAccount,
}: PaymentAccountsProps) {
  if (!payoutMethod || !payoutAccount) return null;
  return (
    <div style={{ fontSize: 14 }}>
      <div style={{ marginBottom: 30 }}>Payment Accounts</div>
      <InfoComponent
        label={
          <>
            {payoutMethod === PaymentMethod.paypal && <PaypalSvg />}
            {payoutMethod === PaymentMethod.venmo && <VenmoSvg />}
          </>
        }
        value={payoutAccount}
      />
    </div>
  );
}

interface ViewHostProfileProps {
  viewUserMode: UserMode;
  setViewUserMode: (userMode: UserMode) => void;
}

function ViewHostProfile({
  viewUserMode,
  setViewUserMode,
}: ViewHostProfileProps) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div style={{ fontWeight: 700 }}>
        {viewUserMode === UserMode.guest && "View Host Profile"}
        {viewUserMode === UserMode.host && "View Guest Profile"}
      </div>
      <div>
        <CustomSwitch
          color={colors.green}
          checked={viewUserMode === UserMode.host}
          handleChange={(checked) => {
            // checked -> host
            // unchecked -> guest
            if (checked) {
              setViewUserMode(UserMode.host);
            } else {
              setViewUserMode(UserMode.guest);
            }
          }}
        />
      </div>
    </div>
  );
}

interface UserProfileProps {
  userId: number;
  avatarEditable?: boolean;
  userMode: UserMode;
  visualize?: {
    avatar?: boolean;
    message?: boolean;
    summary?: boolean;
    guestSummary?: boolean;
    hostSummary?: boolean;
    name?: boolean;
    email?: boolean;
    phone?: boolean;
    address?: boolean;
    birthday?: boolean;
    restriction?: boolean;
    experienceInPartySize?: boolean;
    languages?: boolean;
    identity?: boolean;
    mealsAttended?: boolean;
    payoutAccounts?: boolean;
    aboutMe?: boolean;
    totalSpent?: boolean;
    totalEarned?: boolean;
    attendedEvents?: boolean;
    hostedEvents?: boolean;
    sharedEvents?: boolean;
    userReviews?: boolean;
    hostReviews?: boolean;
  };
  afterLoadCallback?: () => void;
  viewUserMode?: UserMode;
  setViewUserMode?: (userMode: UserMode) => void;
  footer?: ReactNode;
}

interface UserProfileObject {
  user: User;
  attended_events: EventItem[];
  hosted_events: EventItem[];
  shared_events: EventItem[];
  user_reviews: UserReview[];
  host_reviews: HostReview[];
  total_spent: number;
  total_earned: number;
}

function UserProfile({
  userId,
  userMode,
  visualize,
  avatarEditable,
  afterLoadCallback,
  viewUserMode,
  setViewUserMode,
  footer,
}: UserProfileProps) {
  const [userProfile, setUserProfile] = useState<UserProfileObject | null>(
    null
  );
  const [render, setRender] = useState<number>(0);
  const refresh = () => setRender(render + 1);
  const navigate = useNavigate();
  const user = useUser();

  useEffect(() => {
    fetchData<UserProfileObject>("GET", `/users/${userId}/profile`).then(
      setUserProfile
    );
  }, [userId, render]);

  const handleCreateRoom = () => {
    fetchData<{ room_id: number }>(
      "POST",
      `/users/${userId}/rooms?mode=${userMode}`
    ).then(({ room_id }) => navigate(`/rooms/${room_id}`));
  };

  useEffect(() => {
    if (!userProfile) return;
    if (!afterLoadCallback) return;
    afterLoadCallback();
  }, [userProfile, afterLoadCallback]);

  if (!userProfile) return null;

  return (
    <div>
      {visualize?.avatar && (
        <div>
          <AvatarComponent
            src={userProfile.user.meta.image}
            firstname={userProfile.user.meta.firstname}
            lastname={userProfile.user.meta.lastname}
            facebook={userProfile.user.meta.social_medias?.facebook}
            instagram={userProfile.user.meta.social_medias?.instagram}
            x={userProfile.user.meta.social_medias?.x}
            tiktok={userProfile.user.meta.social_medias?.tiktok}
            avatarEditable={avatarEditable}
            refresh={refresh}
            userMode={userMode}
          />
        </div>
      )}
      {visualize?.message && user && user.id !== userProfile.user.id && (
        <div
          style={{
            marginTop: 30,
          }}
        >
          <RoundButton
            fullWidth
            style={{
              padding: "10px",
            }}
            handleClick={handleCreateRoom}
          >
            Message
          </RoundButton>
        </div>
      )}
      {visualize?.summary && (
        <div
          style={{
            marginTop: 30,
          }}
        >
          <YellowInfoComponent
            meals={userProfile.attended_events.length}
            rating={userProfile.user.meta.rating}
            year={TimeUtil.yearDiffFromNow(userProfile.user.created_at)}
            mode={UserMode.guest}
          />
        </div>
      )}
      {visualize?.guestSummary && (
        <div
          style={{
            marginTop: 30,
          }}
        >
          <YellowInfoComponent
            meals={userProfile.attended_events.length}
            year={TimeUtil.yearDiffFromNow(userProfile.user.created_at)}
            mode={UserMode.guest}
          />
        </div>
      )}
      {visualize?.hostSummary && userProfile.user.host && (
        <div
          style={{
            marginTop: 30,
          }}
        >
          <YellowInfoComponent
            meals={userProfile.hosted_events.length}
            rating={userProfile.user.host.meta.rating}
            year={
              userProfile.user.host.meta.first_hosting_date
                ? TimeUtil.yearDiffFromNow(
                    userProfile.user.host.meta.first_hosting_date
                  )
                : 0
            }
            mode={UserMode.host}
          />
        </div>
      )}
      {viewUserMode && setViewUserMode && (
        <>
          <Horizontal marginTop={30} />
          <div style={{ marginTop: 30 }}>
            <ViewHostProfile
              viewUserMode={viewUserMode}
              setViewUserMode={setViewUserMode}
            />
          </div>
        </>
      )}
      {visualize?.name && (
        <>
          <Horizontal marginTop={30} />
          <div style={{ marginTop: 30 }}>
            <InfoComponent
              label="Name"
              value={`${userProfile.user.meta.firstname} ${userProfile.user.meta.lastname}`}
            />
          </div>
        </>
      )}
      {visualize?.email && (
        <>
          <Horizontal marginTop={30} />
          <div style={{ marginTop: 30 }}>
            <InfoComponent label="Email" value={userProfile.user.meta.email} />
          </div>
        </>
      )}
      {visualize?.phone && (
        <>
          <Horizontal marginTop={30} />
          <div style={{ marginTop: 30 }}>
            <InfoComponent
              label="Phone Number"
              value={PhoneUtil.formatPhoneNumber(userProfile.user.meta.phone)}
            />
          </div>
        </>
      )}
      {visualize?.address && (
        <>
          <Horizontal marginTop={30} />
          <div style={{ marginTop: 30 }}>
            <InfoComponent
              label="Address"
              value={
                <>
                  <div>{userProfile.user.meta.address1}</div>
                  {userProfile.user.meta.address2 && (
                    <div>{userProfile.user.meta.address2}</div>
                  )}
                  <div>
                    {userProfile.user.meta.city}, {userProfile.user.meta.state}{" "}
                    {userProfile.user.meta.zipcode}
                  </div>
                </>
              }
            />
          </div>
        </>
      )}
      {visualize?.birthday && (
        <>
          <Horizontal marginTop={30} />
          <div style={{ marginTop: 30 }}>
            <InfoComponent
              label="Birthday"
              value={TimeUtil.toLocaleDateString(
                userProfile.user.meta.birthday
              )}
            />
          </div>
        </>
      )}
      {visualize?.restriction && (
        <>
          <Horizontal marginTop={30} />
          <div style={{ marginTop: 30 }}>
            <InfoComponent
              label="Dietary Restriction"
              value={userProfile.user.meta.restriction}
            />
          </div>
        </>
      )}
      {visualize?.experienceInPartySize && (
        <>
          <Horizontal marginTop={30} />
          <div style={{ marginTop: 30 }}>
            <InfoComponent
              label="Experience in Party Size"
              value={userProfile.user.host?.meta.experience_in_party_size ?? ""}
            />
          </div>
        </>
      )}
      {visualize?.languages && (
        <>
          <Horizontal marginTop={30} />
          <div style={{ marginTop: 30 }}>
            <InfoComponent
              label="Languages I Speak"
              value={(userProfile.user.host?.meta.languages || []).join(", ")}
            />
          </div>
        </>
      )}
      {visualize?.identity && (
        <>
          <Horizontal marginTop={30} />
          <div style={{ marginTop: 30 }}>
            <InfoComponent
              label="Government ID"
              value={
                <CustomImage
                  style={{
                    borderRadius: 7,
                    width: 115,
                    height: 60,
                  }}
                  src={userProfile.user.meta.identity_image ?? ""}
                />
              }
            />
          </div>
        </>
      )}
      {visualize?.mealsAttended && (
        <>
          <Horizontal marginTop={30} />
          <div style={{ marginTop: 30 }}>
            <InfoComponent
              label="Meals Attended"
              value={
                userProfile.user.meta.meals_attended === "private"
                  ? "Private"
                  : "Public"
              }
            />
          </div>
        </>
      )}
      {visualize?.payoutAccounts && user && user.host && (
        <>
          <Horizontal marginTop={30} />
          <div style={{ marginTop: 30 }}>
            <PaymentAccounts
              payoutMethod={user.host.meta.payout.method}
              payoutAccount={user.host.meta.payout.account}
            />
          </div>
        </>
      )}
      {visualize?.aboutMe && (
        <>
          <Horizontal marginTop={30} />
          <div style={{ marginTop: 30 }}>
            <ReadMore>{userProfile.user.host?.meta.about_me ?? ""}</ReadMore>
          </div>
        </>
      )}
      {visualize?.totalSpent && (
        <>
          <Horizontal marginTop={30} />
          <div style={{ marginTop: 30 }}>
            <TotalSpent totalSpent={userProfile.total_spent} />
          </div>
        </>
      )}
      {visualize?.totalEarned && (
        <>
          <Horizontal marginTop={30} />
          <div style={{ marginTop: 30 }}>
            <TotalEarned totalEarned={userProfile.total_earned} />
          </div>
        </>
      )}
      {visualize?.attendedEvents && (
        <>
          <div style={{ marginTop: 30 }}>
            <EventsWithLabel
              label="Meals Attended"
              events={userProfile.attended_events}
            />
          </div>
        </>
      )}
      {visualize?.hostedEvents && (
        <>
          <div style={{ marginTop: 30 }}>
            <EventsWithLabel
              label="Meals Hosted"
              events={userProfile.hosted_events}
            />
          </div>
        </>
      )}
      {visualize?.sharedEvents && (
        <>
          <div style={{ marginTop: 30 }}>
            <EventsWithLabel
              label="Shared Events"
              events={userProfile.shared_events}
            />
          </div>
        </>
      )}
      {visualize?.userReviews && (
        <>
          <div style={{ marginTop: 30 }}>
            <UserReviewComponent
              name={userProfile.user.meta.firstname}
              reviews={userProfile.user_reviews}
            />
          </div>
        </>
      )}
      {visualize?.hostReviews && (
        <>
          <div style={{ marginTop: 30 }}>
            <HostReviewComponent
              name={userProfile.user.meta.firstname}
              reviews={userProfile.host_reviews}
            />
          </div>
        </>
      )}
      {footer}
    </div>
  );
}

export default UserProfile;
