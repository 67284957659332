import React, {
  Fragment,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { ReactComponent as FeaturedSvg } from "../svgs/featured.svg";
import { ReactComponent as NewSvg } from "../svgs/new.svg";
import { ReactComponent as WeekSvg } from "../svgs/week.svg";
import { ReactComponent as BreakfastSvg } from "../svgs/breakfast.svg";
import { ReactComponent as LunchSvg } from "../svgs/lunch.svg";
import { ReactComponent as DinnerSvg } from "../svgs/dinner.svg";
import { ReactComponent as PartySvg } from "../svgs/party.svg";
import { ReactComponent as AllSvg } from "../svgs/all.svg";
import { ReactComponent as LocationSvg } from "../svgs/location.svg";
import { colors } from "../hooks/color";
import CustomImage from "../components/CustomImage";
import Horizontal from "../components/Horizontal";
import CustomSwiper from "../components/CustomSwiper";
import Avatar from "../components/Avatar";
import Bullets from "../components/Bullets";
import { SwiperClass } from "swiper/react";
import { SvgType } from "../type";
import { Box } from "@mui/material";
import { useLocation, useNavigate } from "react-router";
import { fetchData } from "../api";
import { GlobalContext } from "../App";
import TimeUtil from "../utils/time";
import EventAvatarCard from "../components/EventAvatarCard";
import { EventListItem } from "../interfaces";
import { YesNo } from "../enums";
import { ReactComponent as DrinkMarkSvg } from "../svgs/drink_mark.svg";
import RoundButton from "../components/RoundButton";
import EventInfo from "./events/EventInfo";

interface EventNavigation {
  svg: SvgType;
  label: string;
  type: string;
}

const navigations = [
  {
    svg: FeaturedSvg,
    label: "Featured",
    type: "featured",
  },
  {
    svg: AllSvg,
    label: "All",
    type: "all",
  },
  {
    svg: NewSvg,
    label: "New",
    type: "new",
  },
  {
    svg: WeekSvg,
    label: "This Week",
    type: "this_week",
  },
  {
    svg: BreakfastSvg,
    label: "Breakfast",
    type: "Breakfast",
  },
  {
    svg: LunchSvg,
    label: "Lunch",
    type: "Lunch",
  },
  {
    svg: DinnerSvg,
    label: "Dinner",
    type: "Dinner",
  },
  {
    svg: PartySvg,
    label: "Party",
    type: "Party",
  },
] as EventNavigation[];

interface EventSectionProps {
  event: EventListItem;
}

function EventSection({ event }: EventSectionProps) {
  const navigate = useNavigate();
  return (
    <div
      style={{
        color: colors.green,
        fontSize: 14,
        cursor: "pointer",
      }}
      onClick={() => navigate(`/events/${event.event_code}`)}
    >
      <div style={{ position: "relative" }}>
        <CustomImage
          src={event.image}
          width="100%"
          height={200}
          style={{
            borderRadius: 20,
          }}
        />
        {event.alcohol === YesNo.yes && (
          <div style={{ position: "absolute", top: 15, right: 15 }}>
            <DrinkMarkSvg />
          </div>
        )}
      </div>
      <div style={{ marginTop: 20 }}>
        <EventInfo
          eventType={event.event_type}
          cuisine={event.cuisine}
          title={event.title}
        />
      </div>
      <div style={{ marginTop: 10, display: "flex" }}>
        <div>{TimeUtil.formatDayMonth(event.date)}</div>
        <div style={{ marginLeft: 10, marginRight: 10 }}>|</div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <LocationSvg
            style={{
              width: 10,
              height: 14,
              fill: colors.green,
            }}
          />
          <div style={{ marginLeft: 5 }}>{event.address}</div>
        </div>
      </div>
    </div>
  );
}

function Navigation() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const type = searchParams.get("type");
  const navigate = useNavigate();
  return (
    <div
      style={{
        padding: 15,
        backgroundColor: colors.green,
        overflow: "auto",
        display: "flex",
        columnGap: 10,
      }}
    >
      {navigations.map((navigation, index) => (
        <Box
          key={index}
          style={{
            cursor: "pointer",
            color: type === navigation.type ? colors.yellow : colors.white,
          }}
          onClick={() => {
            searchParams.set("type", navigation.type);
            navigate(
              {
                search: searchParams.toString(),
              },
              {
                replace: true,
              }
            );
          }}
          sx={{
            stroke: type === navigation.type ? colors.yellow : colors.ivory,
          }}
        >
          <div
            style={{
              width: 70,
              display: "flex",
              justifyContent: "center",
            }}
          >
            <navigation.svg
              style={{
                width: 35,
                height: 35,
              }}
            />
          </div>
          <div
            style={{
              marginTop: 10,
              textAlign: "center",
              fontSize: 14,
            }}
          >
            {navigation.label}
          </div>
        </Box>
      ))}
    </div>
  );
}

interface EventsProps {
  events: EventListItem[];
}

function EventSections({ events }: EventsProps) {
  return (
    <div
      style={{
        padding: "40px 30px",
      }}
    >
      {events.map((event, index) => (
        <Fragment key={index}>
          {index > 0 && <Horizontal marginTop={40} marginBottom={45} />}
          <EventSection event={event} />
        </Fragment>
      ))}
    </div>
  );
}

function PopularSection({ events }: EventsProps) {
  const [swiper, setSwiper] = useState<SwiperClass | null>(null);
  const [current, setCurrent] = useState<number>(0);
  const navigate = useNavigate();

  useEffect(() => {
    if (!swiper) return;
    swiper.on("activeIndexChange", (swiper: SwiperClass) =>
      setCurrent(swiper.activeIndex)
    );
  }, [swiper]);

  return (
    <div
      style={{
        padding: 30,
        backgroundColor: colors.yellow,
        color: colors.orange,
      }}
    >
      <div
        style={{
          fontSize: 30,
        }}
      >
        Popular
      </div>
      <div style={{ marginTop: 30, borderRadius: 20, overflow: "hidden" }}>
        <CustomSwiper
          slides={events.map((event) => (
            <div
              style={{
                position: "relative",
                color: colors.ivory,
                cursor: "pointer",
              }}
              onClick={() => navigate(`/events/${event.event_code}`)}
            >
              <div
                style={{
                  position: "absolute",
                  inset: 0,
                  backgroundColor: colors.black,
                  opacity: 0.3,
                }}
              ></div>
              <div style={{ position: "absolute", top: 15, left: 15 }}>
                <div>
                  {event.event_type} • {event.cuisine}
                </div>
                <div
                  style={{
                    marginTop: 5,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <LocationSvg
                    style={{
                      width: 10,
                      height: 14,
                      fill: colors.ivory,
                    }}
                  />
                  <div style={{ marginLeft: 5 }}>{event.address}</div>
                </div>
              </div>
              <div
                style={{
                  position: "absolute",
                  left: 15,
                  bottom: 15,
                  right: 15,
                }}
              >
                <div>
                  <RoundButton
                    backgroundColor={colors.yellow}
                    color={colors.orange}
                  >
                    {Math.max(event.capacity - event.reserved, 0) === 0 &&
                      "Fully booked"}
                    {Math.max(event.capacity - event.reserved, 0) > 0 &&
                      `${Math.max(
                        event.capacity - event.reserved,
                        0
                      )} Seats Left`}
                  </RoundButton>
                </div>
                <div
                  style={{
                    fontSize: 26,
                    marginTop: 10,
                    lineHeight: 1.25,
                  }}
                >
                  {event.title}
                </div>
              </div>
              <CustomImage width="100%" height={330} src={event.image} />
            </div>
          ))}
          setSwiper={setSwiper}
        />
      </div>
      {swiper && (
        <div style={{ marginTop: 25, textAlign: "center" }}>
          <Bullets
            active={current}
            total={swiper.slides.length}
            color={colors.orange}
            handleClick={(index) => {
              if (!swiper) return;
              swiper.slideTo(index);
            }}
          />
        </div>
      )}
    </div>
  );
}

function LastMinuteSection({ events }: EventsProps) {
  return (
    <div
      style={{
        padding: 30,
        backgroundColor: colors.green,
        color: colors.white,
        fontSize: 16,
      }}
    >
      <div>Today</div>
      <div style={{ marginTop: 15, fontSize: 30 }}>
        Last minute plan? No worries.
      </div>
      {events.map((event, index) => (
        <EventAvatarCard
          eventId={event.id}
          eventCode={event.event_code}
          image={event.image}
          eventType={event.event_type}
          cuisine={event.cuisine}
          address={event.address}
          title={event.title}
          infoColor={colors.yellow}
          titleColor={colors.ivory}
          key={index}
          alcohol={event.alcohol}
        />
      ))}
    </div>
  );
}

interface HomeEvent {
  events: EventListItem[];
  popular_events: EventListItem[];
  today_events: EventListItem[];
}

function Event() {
  const location = useLocation();
  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location]
  );
  const type = searchParams.get("type");
  const navigate = useNavigate();
  const { userLocation } = useContext(GlobalContext);
  const [homeEvent, setHomeEvent] = useState<HomeEvent | null>(null);
  useEffect(() => {
    if (
      !type ||
      !navigations.map((navigation) => navigation.type).includes(type)
    ) {
      searchParams.set("type", navigations[0].type);
      return navigate(
        {
          search: searchParams.toString(),
        },
        {
          replace: true,
        }
      );
    }
  }, [navigate, searchParams, type]);

  useEffect(() => {
    if (
      !type ||
      !userLocation ||
      !navigations.map((navigation) => navigation.type).includes(type)
    )
      return;
    fetchData<HomeEvent>(
      "GET",
      `/events/home?type=${type}&lat=${userLocation.latitude}&lng=${userLocation.longitude}`
    ).then(setHomeEvent);
  }, [type, userLocation]);

  if (!homeEvent) return null;

  return (
    <>
      <Navigation />
      <EventSections events={homeEvent.events} />
      {homeEvent.popular_events.length > 0 && (
        <PopularSection events={homeEvent.popular_events} />
      )}
      {homeEvent.today_events.length > 0 && (
        <LastMinuteSection events={homeEvent.today_events} />
      )}
    </>
  );
}

export default Event;
