import { useEventHooks } from "../hooks/event";
import { useCurrentMode } from "../hooks/mode";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { colors } from "../hooks/color";
import RoundButton from "../components/RoundButton";
import CustomImage from "../components/CustomImage";
import { ReactComponent as TrashSvg } from "../svgs/trash.svg";
import { ReactComponent as LocationSvg } from "../svgs/location.svg";
import CustomDialog from "../components/CustomDialog";
import { EventItem } from "../interfaces";
import { EventItemType, EventStatus } from "../enums";
import TimeUtil from "../utils/time";
import { fetchData } from "../api";
import { EventIntroLabel } from "../utils/event";
import { useUser } from "../hooks/user";
import ToggleLabel from "../components/ToggleLabel";

interface EventStatusRoundButtonProps {
  status: EventStatus;
}

function EventStatusRoundButton({ status }: EventStatusRoundButtonProps) {
  if (status === EventStatus.ready) return <RoundButton>Approved</RoundButton>;
  if (status === EventStatus.progress)
    return (
      <RoundButton color={colors.green} backgroundColor={colors.yellow}>
        Draft
      </RoundButton>
    );
  if (status === EventStatus.under_review)
    return (
      <RoundButton color={colors.green} backgroundColor={colors.pink}>
        Under Review
      </RoundButton>
    );
  if (status === EventStatus.rejected)
    return (
      <RoundButton color={colors.ivory} backgroundColor={colors.orange}>
        Rejected
      </RoundButton>
    );
  if (status === EventStatus.cancelled)
    return (
      <RoundButton color={colors.ivory} backgroundColor={colors.red}>
        Cancelled
      </RoundButton>
    );

  return null;
}

interface EventCardComponentProps {
  eventItem: EventItem;
  handleDelete: () => Promise<any>;
  status: EventStatus;
  customLabel?: string;
  handleClick?: () => void;
}

function EventCardComponent({
  eventItem,
  handleDelete,
  status,
  customLabel,
  handleClick,
}: EventCardComponentProps) {
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const user = useUser();
  if (!user) return null;
  return (
    <>
      <div
        style={{
          borderRadius: 20,
          paddingTop: "100%",
          position: "relative",
          overflow: "hidden",
          color: colors.ivory,
        }}
        onClick={handleClick}
      >
        <div
          style={{
            position: "absolute",
            inset: 0,
            zIndex: 1,
            backgroundColor: colors.black,
            opacity: 0.3,
          }}
        ></div>
        <div style={{ position: "absolute", inset: 0 }}>
          <CustomImage width="100%" height="100%" src={eventItem.image} />
        </div>
        <div style={{ position: "absolute", inset: 0, padding: 20, zIndex: 1 }}>
          <div style={{ position: "relative", width: "100%", height: "100%" }}>
            <div style={{ position: "absolute", top: 0, left: 0 }}>
              <EventStatusRoundButton status={status} />
              {customLabel && (
                <RoundButton
                  color={colors.green}
                  backgroundColor={colors.yellow}
                >
                  {customLabel}
                </RoundButton>
              )}
            </div>
            {[
              EventStatus.progress,
              EventStatus.under_review,
              EventStatus.cancelled,
              EventStatus.ready,
              EventStatus.rejected,
              EventStatus.expired,
            ].includes(status) &&
              eventItem.host_id === user.host?.id && (
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    display: "flex",
                    cursor: "pointer",
                    zIndex: 1,
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpenDelete(true);
                  }}
                >
                  <TrashSvg stroke={colors.ivory} />
                </div>
              )}
            <div
              style={{
                position: "absolute",
                bottom: 0,
                left: 0,
                right: 0,
                fontSize: 14,
              }}
            >
              <div>
                {eventItem.event_type} • {eventItem.cuisine}
              </div>
              <div
                style={{
                  fontSize: 26,
                  marginTop: 10,
                  lineHeight: 1.25,
                }}
              >
                {eventItem.title}
              </div>
              <div style={{ marginTop: 10 }}>
                {TimeUtil.formatDayMonth(eventItem.date)}
              </div>
              <div
                style={{ marginTop: 10, display: "flex", alignItems: "center" }}
              >
                <LocationSvg
                  style={{
                    width: 10,
                    height: 14,
                    fill: colors.ivory,
                  }}
                />
                <div style={{ marginLeft: 5 }}>{eventItem.address}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CustomDialog open={openDelete} handleClose={() => setOpenDelete(false)}>
        <div style={{ fontSize: 24, fontWeight: 600 }}>
          Are you sure you want to delete this event?
        </div>
        <div style={{ marginTop: 30 }}>
          <EventIntroLabel
            image={eventItem.image}
            title={eventItem.title}
            cuisine={eventItem.cuisine}
            date={eventItem.date}
            eventType={eventItem.event_type}
            address={eventItem.address}
          />
        </div>
        <div style={{ marginTop: 30, textAlign: "center" }}>
          <RoundButton
            handleClick={() => handleDelete().then(() => setOpenDelete(true))}
          >
            Delete
          </RoundButton>
        </div>
      </CustomDialog>
    </>
  );
}

interface EventItemsProps {
  eventItemTypes: EventItemType[];
}

function EventItems({ eventItemTypes }: EventItemsProps) {
  const [eventItems, setEventItems] = useState<EventItem[] | null>(null);
  const currentMode = useCurrentMode();
  // const color = useColor();
  const { deleteEvent } = useEventHooks();
  const [render, setRender] = useState<number>(0);
  const navigate = useNavigate();
  const refresh = () => setRender(render + 1);
  const location = useLocation();
  // const [toggled, setToggled] = useState<boolean>(false);
  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location]
  );
  const type = searchParams.get("type") as EventItemType;

  useEffect(() => {
    const eventItemType = eventItemTypes.find(
      (eventItemType) => eventItemType === type
    );
    if (!eventItemType) return;
    fetchData<EventItem[]>(
      "GET",
      `/events/items?mode=${currentMode}&type=${type}`
    ).then(setEventItems);
  }, [currentMode, render, type, eventItemTypes]);

  useEffect(() => {
    // check if type is not in event item types
    // if not available, then select the first one
    // if available, then select corresponding
    const eventItemType = eventItemTypes.find(
      (eventItemType) => eventItemType === type
    );
    if (eventItemType) return;
    searchParams.set("type", eventItemTypes[0]);
    navigate(`?${searchParams.toString()}`, {
      replace: true,
    });
  }, [eventItemTypes, navigate, searchParams, type]);

  if (!eventItems || !type) return null;

  const label = (eventItemType: EventItemType) => {
    if (eventItemType === EventItemType.all) {
      return "All Events";
    }
    if (eventItemType === EventItemType.pending) {
      return "Pending Events";
    }
    if (eventItemType === EventItemType.upcoming) {
      return "Upcoming Events";
    }
    if (eventItemType === EventItemType.past) {
      return "Past Events";
    }
    if (eventItemType === EventItemType.cancelled) {
      return "Cancelled Events";
    }
    if (eventItemType === EventItemType.approved) {
      return "Approved Events";
    }
    if (eventItemType === EventItemType.rejected) {
      return "Rejected Events";
    }
    if (eventItemType === EventItemType.draft) {
      return "Draft";
    }
    return "";
  };

  // const toggleCondition = eventItemTypes.length > 1;

  return (
    <>
      {/* <div
        style={{
          backgroundColor: colors.ivory,
          position: "relative",
          zIndex: 10,
          padding: 30,
          paddingBottom: 0,
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontSize: 24,
            color,
            cursor: toggleCondition ? "pointer" : "default",
          }}
          onClick={() => {
            if (!toggleCondition) return;
            setToggled(!toggled);
          }}
        >
          <div
            style={{
              fontFamily: fontFamily.simula,
            }}
          >
            {label(type)}
          </div>
          {toggleCondition && (
            <ExpandMoreIcon
              style={{
                color,
                fontSize: 35,
                transform: toggled ? "rotateX(180deg)" : undefined,
              }}
            />
          )}
        </div>
        <Horizontal marginTop={5} thick horizontalColor={color} />
        {toggled && (
          <div
            style={{
              position: "absolute",
              top: "100%",
              left: 0,
              right: 0,
              zIndex: 10,
              backgroundColor: colors.ivory,
              padding: 30,
              paddingTop: 0,
            }}
          >
            {eventItemTypes.map((eventItemType, index) => (
              <Fragment key={index}>
                {index > 0 && <Horizontal marginTop={15} />}
                <div
                  style={{
                    marginTop: 15,
                    fontWeight: 500,
                    fontSize: 20,
                    color: colors.green,
                    cursor: eventItemType === type ? "default" : "pointer",
                    opacity: eventItemType === type ? 0.2 : 1,
                  }}
                  onClick={() => {
                    searchParams.set("type", eventItemType);
                    navigate(`?${searchParams.toString()}`, {
                      replace: true,
                    });
                    setToggled(!toggled);
                  }}
                >
                  {label(eventItemType)}
                </div>
              </Fragment>
            ))}
          </div>
        )}
      </div> */}
      <ToggleLabel
        labelTypes={[
          EventItemType.all,
          EventItemType.pending,
          EventItemType.upcoming,
          EventItemType.past,
          EventItemType.cancelled,
          EventItemType.approved,
          EventItemType.rejected,
          EventItemType.draft,
        ]}
        labelType={type}
        labelFunc={(labelType) => label(labelType as EventItemType)}
        handleLabelType={(labelType) => {
          searchParams.set("type", labelType);
          navigate(`?${searchParams.toString()}`, {
            replace: true,
          });
        }}
      />
      <div style={{ padding: 30 }}>
        {eventItems.map((eventItem, index) => (
          <div style={{ marginTop: 20 }} key={index}>
            <EventCardComponent
              eventItem={eventItem}
              handleDelete={() => deleteEvent(eventItem.id).then(refresh)}
              status={eventItem.status}
              handleClick={() => navigate(`/events/${eventItem.event_code}`)}
            />
          </div>
        ))}
      </div>
    </>
  );
}

export default EventItems;
