import CustomImage from "../CustomImage";
import LogoIcon from "../../pngs/logo.png";
import LogoYellowIcon from "../../pngs/logo_yellow.png";

interface BigLogoProps {
  yellow?: boolean;
}

function BigLogo({ yellow }: BigLogoProps) {
  return (
    <CustomImage
      src={yellow ? LogoYellowIcon : LogoIcon}
      style={{ width: 205, height: 90 }}
    />
  );
}

export default BigLogo;
