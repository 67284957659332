import React from "react";
import { useColor } from "../../hooks/color";
import { useCurrentMode } from "../../hooks/mode";
import { useNavigate } from "react-router-dom";
import CustomImage from "../CustomImage";
import LogoIcon from "../../pngs/logo.png";
import LogoYellowIcon from "../../pngs/logo_yellow.png";

interface SmallLogoProps {
  yellow?: boolean;
}

function SmallLogo({ yellow }: SmallLogoProps) {
  const color = useColor();
  // const currentMode = useCurrentMode();
  const navigate = useNavigate();
  return (
    <div
      style={{
        color,
        cursor: "pointer",
        display: "flex",
        alignItems: "flex-end",
      }}
      onClick={() => navigate("/")}
    >
      <CustomImage
        src={yellow ? LogoYellowIcon : LogoIcon}
        style={{ width: 105, height: 45 }}
      />
      {/* {(currentMode === "host" || currentMode === "admin") && (
        <span style={{ marginLeft: 5, fontSize: 11 }}>{currentMode}</span>
      )} */}
    </div>
  );
}

export default SmallLogo;
