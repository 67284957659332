import { useContext } from "react";
import { GlobalContext } from "../App";
import { fetchData } from "../api";
import { UserMode } from "../enums";
import { useUser } from "./user";

export function useAllModes() {
  const user = useUser();
  const res = [] as UserMode[];
  if (!user) return res;
  if (user) {
    res.push(UserMode.guest);
  }
  if (user.host) {
    res.push(UserMode.host);
  }
  if (user.admin) {
    res.push(UserMode.admin);
  }
  return res;
}

export function useCurrentMode(): UserMode {
  const user = useUser();
  if (!user) return UserMode.unsigned;
  return user.meta.mode || UserMode.guest;
}

export function useHostMode() {
  const currentMode = useCurrentMode();
  return currentMode === UserMode.host;
}

export function useGuestMode() {
  const currentMode = useCurrentMode();
  return currentMode === UserMode.guest;
}

export function useAdminMode() {
  const currentMode = useCurrentMode();
  return currentMode === UserMode.admin;
}

export function useHostOnboarded() {
  const user = useUser();
  return Boolean(user && user.host);
}

export function useModeHooks() {
  const { refreshUser } = useContext(GlobalContext);

  const patchUserMode = (mode: UserMode) => {
    return fetchData("PATCH", "/users/mode", {
      mode,
    }).then(refreshUser);
  };

  return {
    patchUserMode,
  };
}
