import { useEffect, useState } from "react";
import { fetchData } from "../api";
import { useUser } from "./user";
import { useCurrentMode } from "./mode";
import { HostNotification, UserNotification } from "../interfaces";

export function useNotifications() {
  const [notifications, setNotifications] = useState<UserNotification[] | null>(
    null
  );
  const user = useUser();
  const currentMode = useCurrentMode();

  useEffect(() => {
    if (!user || currentMode !== "guest") {
      return setNotifications([]);
    }
    fetchData<UserNotification[]>("GET", `/notifications`).then(
      setNotifications
    );
  }, [user, currentMode]);

  return notifications;
}

export function useHostNotifications() {
  const [hostNotifications, setHostNotifications] = useState<
    HostNotification[] | null
  >(null);
  const user = useUser();
  const currentMode = useCurrentMode();

  useEffect(() => {
    if (!user || !user.host || currentMode !== "host") {
      return setHostNotifications([]);
    }
    fetchData<HostNotification[]>("GET", `/hosts/notifications`).then(
      setHostNotifications
    );
  }, [user, currentMode]);

  return hostNotifications;
}

export function useNotificationHooks() {
  const patchNotificationRead = (notification_id: number) => {
    return fetchData("PATCH", `/notifications/${notification_id}/read`);
  };

  return { patchNotificationRead };
}

export function useHostNotificationHooks() {
  const patchHostNotificationRead = (host_notification_id: number) => {
    return fetchData(
      "PATCH",
      `/hosts/notifications/${host_notification_id}/read`
    );
  };

  return { patchHostNotificationRead };
}
