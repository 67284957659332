import React, { Fragment, useState } from "react";
import { useNavigate } from "react-router";
import Header from "../../components/Header";
import TextInput from "../../inputs/TextInput";
import Avatar from "../../components/Avatar";
import Horizontal from "../../components/Horizontal";
import TimeUtil from "../../utils/time";
import UserProfile from "../../components/UserProfile";
import { fetchData } from "../../api";
import { User } from "../../interfaces";
import { UserMode, UserVerificationStatus } from "../../enums";
import ToggleLabel from "../../components/ToggleLabel";
import RoundButton from "../../components/RoundButton";
import { colors } from "../../hooks/color";
import { useUsers } from "../../hooks/user";
import CustomDialog from "../../components/CustomDialog";
import SelectInput from "../../inputs/SelectInput";
import { idVerificationRejectTypes } from "../../const";

interface RejectButtonProps {
  handleReject: (reject: string, rejectDetail: string) => void;
}

function RejectButton({ handleReject }: RejectButtonProps) {
  const [open, setOpen] = useState<boolean>(false);
  const [reject, setReject] = useState<string>("");
  const [rejectDetail, setRejectDetail] = useState<string>("");

  const disabledCondition = !(
    Boolean(reject) &&
    (reject !== "Other" || Boolean(rejectDetail))
  );

  return (
    <>
      <RoundButton
        handleClick={() => setOpen(true)}
        backgroundColor={colors.red}
      >
        Reject
      </RoundButton>
      <CustomDialog open={open} handleClose={() => setOpen(false)}>
        <div style={{ fontSize: 24, fontWeight: 600, textAlign: "center" }}>
          Reject ID Verification
        </div>
        <div style={{ marginTop: 30, fontSize: 24, fontWeight: 600 }}>
          Are you sure you want to reject this ID verification?
        </div>
        <div style={{ marginTop: 30 }}>
          <SelectInput
            items={idVerificationRejectTypes.map(
              (idVerificationRejectType) => ({
                label: idVerificationRejectType,
                value: idVerificationRejectType,
              })
            )}
            value={reject}
            fullWidth
            label="Select Your Reason"
            handleChange={setReject}
          />
        </div>
        {reject === "Other" && (
          <div style={{ marginTop: 30 }}>
            <TextInput
              label="Details"
              fullWidth
              handleChange={setRejectDetail}
              multiline
              rows={5}
              defaultValue={rejectDetail}
            />
          </div>
        )}
        <div style={{ marginTop: 30, textAlign: "center" }}>
          <RoundButton
            handleClick={() => handleReject(reject, rejectDetail)}
            disabled={disabledCondition}
          >
            Reject
          </RoundButton>
        </div>
      </CustomDialog>
    </>
  );
}

interface CurrentUserComponentProps {
  handleBack: () => void;
  user: User;
  refresh: () => void;
}

function CurrentUserComponent({
  handleBack,
  user,
  refresh,
}: CurrentUserComponentProps) {
  const [loaded, setLoaded] = useState<boolean>(false);
  type ActionType = "approve_id" | "reject_id";

  const handleAction = (actionType: ActionType, body: object = {}) => {
    return fetchData("PATCH", `/users/${user.id}/verification_status`, {
      ...body,
      action: actionType,
    })
      .then(refresh)
      .then(handleBack);
  };

  return (
    <>
      <Header handleBack={handleBack} />
      <div style={{ padding: 30, fontSize: 14 }}>
        <UserProfile
          userId={user.id}
          visualize={{
            avatar: true,
            message: true,
            summary: true,
            name: true,
            email: true,
            phone: true,
            address: true,
            birthday: true,
            restriction: true,
            identity: true,
            mealsAttended: true,
            totalSpent: true,
            attendedEvents: true,
            userReviews: true,
          }}
          userMode={UserMode.guest}
          afterLoadCallback={() => {
            setLoaded(true);
          }}
        />
        {loaded && (
          <div
            style={{
              marginTop: 30,
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            {user.meta.verification_status ===
              UserVerificationStatus.id_pending && (
              <>
                <RoundButton
                  backgroundColor={colors.blue}
                  handleClick={() => handleAction("approve_id")}
                >
                  Approve ID
                </RoundButton>
                <RejectButton
                  handleReject={(reject, rejectDetail) =>
                    handleAction("reject_id", {
                      reject,
                      reject_detail: rejectDetail,
                    })
                  }
                />
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
}

interface FilteredComponentProps {
  users: User[];
  setCurrentUser: (user: User) => void;
}

function FilteredComponent({ users, setCurrentUser }: FilteredComponentProps) {
  return (
    <>
      {users.map((user, index) => (
        <Fragment key={index}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              columnGap: 15,
              marginTop: 30,
              cursor: "pointer",
            }}
            onClick={() => setCurrentUser(user)}
          >
            <Avatar width={92} height={92} src={user.meta.image} />
            <div style={{ fontSize: 14 }}>
              <div
                style={{
                  fontSize: 25,
                  fontWeight: 600,
                }}
              >
                {user.meta.firstname}
              </div>
              <div style={{ marginTop: 10 }}>
                {user.host?.meta.rating.toFixed(1) ?? 0}★
              </div>
              <div style={{ marginTop: 10 }}>
                Joined Since: {TimeUtil.formatDateFromDateTime(user.created_at)}
              </div>
            </div>
          </div>
          <Horizontal marginTop={30} opacity={0.3} />
        </Fragment>
      ))}
    </>
  );
}

interface FilterComponentProps {
  filter: string;
  setFilter: (filter: string) => void;
}

function FilterComponent({ filter, setFilter }: FilterComponentProps) {
  return (
    <TextInput label="Filter" defaultValue={filter} handleChange={setFilter} />
  );
}

function AdminGuests() {
  const navigate = useNavigate();
  const [filter, setFilter] = useState<string>("");
  const [type, setType] = useState<string>("all");
  const { users, refresh } = useUsers();
  const [currentUser, setCurrentUser] = useState<User | null>(null);

  if (!users) return null;
  const filtered = users.filter((user) => user.meta.firstname.includes(filter));
  if (currentUser) {
    return (
      <CurrentUserComponent
        handleBack={() => setCurrentUser(null)}
        user={currentUser}
        refresh={refresh}
      />
    );
  }

  const label = (labelType: string) => {
    if (labelType === "all") {
      return "All Guests";
    }
    if (labelType === UserVerificationStatus.full_verified) {
      return "Full Verified Guests";
    }
    if (labelType === UserVerificationStatus.id_pending) {
      return "Identity Pending Guests";
    }
    if (labelType === UserVerificationStatus.email_verified) {
      return "Email Verified Guests";
    }
    if (labelType === UserVerificationStatus.unverified) {
      return "Unverified Guests";
    }
    return "";
  };

  const refinedUsers = () => {
    if (type === "all") {
      return filtered;
    }
    return filtered.filter((user) => user.meta.verification_status === type);
  };

  return (
    <>
      <Header label="Guests" handleBack={() => navigate(-1)} />
      <ToggleLabel
        labelTypes={[
          "all",
          UserVerificationStatus.full_verified,
          UserVerificationStatus.id_pending,
          UserVerificationStatus.email_verified,
          UserVerificationStatus.unverified,
        ]}
        labelType={type}
        labelFunc={label}
        handleLabelType={setType}
      />
      <div style={{ padding: 30, fontSize: 14 }}>
        <div>
          <FilterComponent filter={filter} setFilter={setFilter} />
        </div>
        <div style={{ marginTop: 30 }}>
          <FilteredComponent
            users={refinedUsers()}
            setCurrentUser={setCurrentUser}
          />
        </div>
      </div>
    </>
  );
}

export default AdminGuests;
