import { useNavigate } from "react-router-dom";
import Header from "../../components/Header";
import { getEmail } from "../../util";

function HowPaid() {
  const navigate = useNavigate();
  return (
    <>
      <Header
        label="How do I get paid?"
        handleBack={() => navigate("/profile")}
      />
      <div style={{ padding: 30, fontSize: 14 }}>
        <div>
          This page outlines the transparent and straightforward payment process
          at Tably:
        </div>
        <div style={{ marginTop: 20 }}>
          <div>
            <b>Earnings:</b>
          </div>
          <div style={{ marginTop: 10 }}>
            <ul>
              <li>
                You will receive <b>85% of the total</b> revenue generated from
                your hosted meals.
              </li>
            </ul>
          </div>
        </div>
        <div style={{ marginTop: 20 }}>
          <div>
            <b>Example:</b>
          </div>
          <div style={{ marginTop: 10 }}>
            <ul>
              <li>
                If you set a menu price of $50 per guest and host a dinner for 4
                guests, your total revenue would be $200 (50 x 4).
              </li>
              <li>
                After Tably's 7% service fee ($14), your earnings would be $186
                ($200 - $14).
              </li>
            </ul>
          </div>
        </div>
        <div style={{ marginTop: 20 }}>
          <div>
            <b>Payment Method:</b>
          </div>
          <div style={{ marginTop: 10 }}>
            <ul>
              <li>
                You can choose your preferred payment method: direct deposit to
                your Venmo or Paypal.
              </li>
              <li>
                We aim to process your earnings within 3 business days after the
                conclusion of your hosted meal.
              </li>
              <li>
                You will receive an email notification once your payment has
                been processed.
              </li>
            </ul>
          </div>
        </div>
        <div style={{ marginTop: 20 }}>
          <div>
            <b>Important Notes:</b>
          </div>
          <div style={{ marginTop: 10 }}>
            <ul>
              <li>Tably does not charge any upfront or listing fees.</li>
              <li>
                We encourage you to clearly communicate your pricing and any
                additional fees to your guests before they book your experience.
              </li>
            </ul>
          </div>
        </div>
        <div style={{ marginTop: 20 }}>
          <div>
            <b>Transparency and Fairness:</b>
          </div>
          <div style={{ marginTop: 10 }}>
            <div>
              At Tably, we believe in fair compensation for our hosts who are
              the heart of our community. We strive to provide a transparent and
              efficient payment process that allows you to focus on what you do
              best - creating memorable culinary experiences for our guests.
            </div>
            <div style={{ marginTop: 10 }}>
              If you have any further questions about the payment process or any
              aspect of hosting on Tably, please don't hesitate to contact our
              support team at {getEmail()}.
            </div>
            <div style={{ marginTop: 10 }}>
              We look forward to your continued success on Tably!
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HowPaid;
