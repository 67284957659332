import React, { useEffect, useState } from "react";
import { fontFamily } from "../hooks/font";
import RoundButton from "../components/RoundButton";
import { fetchData } from "../api";
import { useLocation, useNavigate } from "react-router-dom";
import { User } from "../interfaces";

function Confirmation() {
  const [user, setUser] = useState<User | null>(null);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    fetchData<User>(
      "GET",
      `/confirmation?token=${searchParams.get("token")}`
    ).then(setUser);
  }, [location]);

  if (!user) return null;

  return (
    <div
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        flexDirection: "column",
      }}
    >
      <div style={{ fontFamily: fontFamily.simula, fontSize: 30 }}>
        Hi {user.meta.firstname}.
        <br />
        Welcome to Tably.
        <br />
        You’re all set!
      </div>
      <div style={{ marginTop: 30 }}>
        <RoundButton
          handleClick={() =>
            navigate("/", {
              replace: true,
            })
          }
        >
          Done
        </RoundButton>
      </div>
    </div>
  );
}

export default Confirmation;
