import React from "react";
import { useNavigate } from "react-router";
import Header from "../../components/Header";
import { getEmail } from "../../util";

function Guideline() {
  const navigate = useNavigate();
  return (
    <>
      <Header label="User Guideline" handleBack={() => navigate("/profile")} />
      <div style={{ padding: 30, fontSize: 14 }}>
        <div>
          <div style={{ fontWeight: 700 }}>Community Guidelines</div>
          <div style={{ marginTop: 15 }}>
            At Tably, we believe a safe and enjoyable experience for everyone
            fosters a thriving community of passionate home chefs and
            adventurous diners. These Community Guidelines outline expectations
            for Hosts, ensuring trust and delicious experiences for all guests.
            These Community Guidelines can also be found in the Terms of Service
            you agreed to when registering as a User of Tably.
          </div>
          <div style={{ marginTop: 15 }}>
            For being able to host through Tably and utilize its services, I
            agree to the following guidelines in addition to the Terms and
            Conditions I agreed to when signing up for Tably.
          </div>
        </div>
        <div style={{ marginTop: 30 }}>
          <div style={{ fontWeight: 700 }}>Food and Safety Guidance</div>
          <div style={{ marginTop: 15 }}>
            I will refrigerate food items to an internal temperature of 41°F or
            lower, as required by law, if ingredients are not in use.
          </div>
          <div style={{ marginTop: 15 }}>
            I will fully and truthfully disclose in my dish descriptions and
            allergens in the food, per federal, state, and local laws.
          </div>
          <div style={{ marginTop: 15 }}>
            I agree not to cook while ill and comply with all local, state, and
            federal COVID guidelines.
          </div>
        </div>
        <div style={{ marginTop: 30 }}>
          <div style={{ fontWeight: 700 }}>Scheduling & Menu Guidance</div>
          <div style={{ marginTop: 15 }}>
            I understand and acknowledge that creating my menu, recipes,
            determining what ingredients to purchase, how to prepare my menu and
            how often to sell my food is entirely up to me.
          </div>
          <div style={{ marginTop: 15 }}>
            I understand and acknowledge that determining what price to set for
            each event I sell seats for is entirely up to me.
          </div>
          <div style={{ marginTop: 15 }}>
            When creating and serving for events I created, I will ensure that
            the quality of food and service are of a consistently high quality
            and will use fresh ingredients.
          </div>
          <div style={{ marginTop: 15 }}>
            I understand that publishing my event allows me to sell seats to the
            event in advance and it is a commitment between me and the guests. 
          </div>
          <div style={{ marginTop: 15 }}>
            I will fulfill the commitments I make to other users of the platform
            including safe, professional and high quality manner. In the event
            of illness or emergency, if I am unable to host the event, I will
            cancel the event as soon as possible and inform {getEmail()}
            as soon as possible to make them aware of my emergency and if there
            are any seats that I need to cancel and refund.
          </div>
          <div style={{ marginTop: 15 }}>
            I will submit changes to {getEmail()} for an existing event at least
            three days before the given event day. These changes include changes
            to event name, price, number of seats, ingredients, etc.
          </div>
          <div style={{ marginTop: 15 }}>
            I agree that in order to give the Tably Team enough time to review
            and approve new events, I will submit new menu items at least three
            days before the intended publishing date.
          </div>
        </div>
        <div style={{ marginTop: 30 }}>
          <div style={{ fontWeight: 700 }}>Supplies and Packaging</div>
          <div style={{ marginTop: 15 }}>
            I am responsible for the curation of my event. This includes finding
            my own space to host, sourcing ingredients, creating my menu, using
            my own utensils, etc.
          </div>
        </div>
        <div style={{ marginTop: 30 }}>
          <div style={{ fontWeight: 700 }}>Media Release</div>
          <div style={{ marginTop: 15 }}>
            I agree to the Media Release of the pictures I upload for the event
            and I understand that Tably may use photographs, video, voice
            recordings, and/or biographical information of or about me, my food,
            and cooking techniques on Tably’s website and other advertising and
            marketing materials. I agree to grant Tably an irrevocable license
            to the Materials, as defined in the Terms of Service.
          </div>
        </div>
        <div style={{ marginTop: 30 }}>
          <div style={{ fontWeight: 700 }}>Continuing Provisions</div>
          <div style={{ marginTop: 15 }}>
            I understand and agree that any provision of this Agreement that
            logically ought to survive the termination of my business
            relationship with Tably will continue in full force and effect after
            I stop doing business with Tably, including without limitation the
            media release.
          </div>
        </div>
        <div style={{ marginTop: 30 }}>
          <div style={{ fontWeight: 700 }}>
            Amendments to These Community Guidelines
          </div>
          <div style={{ marginTop: 15 }}>
            I understand that these guidelines may be revised by Tably, from
            time to time, in its sole discretion. I agree to adhere to the most
            recent version of the Community Guidelines, which is accessible
            online. My continued use of Tably’s platform after this document has
            been revised will constitute my acceptance of the revised Tably
            Community Guidelines.
          </div>
        </div>
        <div style={{ marginTop: 30 }}>
          <div style={{ fontWeight: 700 }}>Violations of these Guidelines</div>
          <div style={{ marginTop: 15 }}>
            I understand and acknowledge that failure to abide by the Tably’s
            Terms of Service and /or these Community Guidelines may result in me
            no longer being able to host events through Tably and/or termination
            of my account as a Host on TheTably.com. In the event that there is
            an allegation or belief that I may have breached the Terms of
            Service or these Community Guidelines, I agree to cooperate fully
            and promptly in any investigation conducted by Tably.
          </div>
        </div>
        <div style={{ marginTop: 30 }}>Updated March 25, 2024</div>
      </div>
    </>
  );
}

export default Guideline;
