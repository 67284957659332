import { useContext, useState } from "react";
import { useNotificationHooks } from "../../hooks/notification";
import { useEventDetail } from "../../hooks/event";
import { fetchData } from "../../api";
import CustomImage from "../CustomImage";
import CustomRating from "../CustomRating";
import TextInput from "../../inputs/TextInput";
import { UserNotification } from "../../interfaces";
import CustomDialog from "../CustomDialog";
import RoundButton from "../RoundButton";
import { GlobalContext } from "../../App";
import HostApprovedBackgroundPng from "../../pngs/host_approved_background.png";
import { fontFamily } from "../../hooks/font";
import { colors } from "../../hooks/color";
import { useNavigate } from "react-router-dom";

interface HostNotificationComponentProps {
  notification: UserNotification;
}

function HostRatingNotificationComponent({
  notification,
}: HostNotificationComponentProps) {
  const [open, setOpen] = useState<boolean>(true);
  const [rating, setRating] = useState<number>(5);
  const [review, setReview] = useState<string>("");
  const eventId = notification.meta.event_id;
  const { eventDetail } = useEventDetail({ eventId });
  const { patchNotificationRead } = useNotificationHooks();
  const { refreshUser } = useContext(GlobalContext);

  const handleSubmit = () => {
    return fetchData("POST", `/events/${eventId}/host_ratings`, {
      rating,
      review,
    }).then(handleClose);
  };

  const handleClose = () => {
    return patchNotificationRead(notification.id)
      .then(() => setOpen(false))
      .then(refreshUser);
  };

  if (!eventDetail) return null;

  return (
    <CustomDialog open={open} handleClose={handleClose}>
      <div
        style={{
          textAlign: "center",
        }}
      >
        <div style={{ marginTop: 30 }}>
          <CustomImage
            height={180}
            style={{ borderRadius: 20 }}
            src={eventDetail.images[0] || ""}
          />
        </div>
        <div style={{ marginTop: 15 }}>
          How was your experience with {eventDetail.host.firstname}?
        </div>
        <div
          style={{
            marginTop: 30,
            fontSize: 20,
            fontWeight: 600,
          }}
        >
          {eventDetail.title}
        </div>
        <div style={{ marginTop: 15 }}>
          <CustomRating value={rating} handleChange={setRating} />
        </div>
        <div style={{ marginTop: 15 }}>
          <TextInput
            label="Review"
            handleChange={setReview}
            multiline
            rows={5}
            defaultValue={review}
          />
        </div>
        <div
          style={{
            marginTop: 30,
          }}
        >
          <RoundButton handleClick={handleSubmit}>Submit</RoundButton>
        </div>
      </div>
    </CustomDialog>
  );
}

function HostApprovedNotificationComponent({
  notification,
}: HostNotificationComponentProps) {
  const [open, setOpen] = useState<boolean>(true);
  const { user, refreshUser } = useContext(GlobalContext);
  const { patchNotificationRead } = useNotificationHooks();
  const navigate = useNavigate();

  const handleClose = () => {
    return patchNotificationRead(notification.id)
      .then(() => setOpen(false))
      .then(refreshUser);
  };

  if (!user) return null;

  return (
    <CustomDialog open={open} handleClose={handleClose}>
      <div
        style={{
          margin: -30,
          display: "flex",
        }}
      >
        <img
          src={HostApprovedBackgroundPng}
          alt=""
          style={{ width: 300, height: "auto" }}
        />
      </div>
      <div
        style={{
          position: "absolute",
          inset: 0,
          textAlign: "center",
        }}
      >
        <div
          style={{
            marginTop: 50,
            fontFamily: fontFamily.simula,
            color: colors.orange,
            fontSize: 28,
          }}
        >
          Congratulations
          <br />
          {user.meta.firstname}.
        </div>
        <div style={{ marginTop: 50, color: colors.orange, fontSize: 18 }}>
          You’re now a host!
        </div>
        <div style={{ marginTop: 30 }}>
          <RoundButton
            backgroundColor={colors.orange}
            color={colors.yellow}
            style={{ padding: "5px 20px" }}
            handleClick={() => {
              navigate("/profile");
              handleClose();
            }}
          >
            To profile page
          </RoundButton>
        </div>
      </div>
    </CustomDialog>
  );
}

interface NotificationRenderProps {
  notification: UserNotification;
}

function NotificationRender({ notification }: NotificationRenderProps) {
  if (notification.meta.type === "host_rating")
    return <HostRatingNotificationComponent notification={notification} />;
  if (notification.meta.type === "host_approved")
    return <HostApprovedNotificationComponent notification={notification} />;
  return null;
}

export default NotificationRender;
