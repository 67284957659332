import React, { createContext, useContext, useEffect, useState } from "react";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import {
  Outlet,
  RouterProvider,
  createBrowserRouter,
  useLocation,
} from "react-router-dom";
import EventsDetailEdit from "./pages/events/EventsDetailEdit";
import EventsDetail from "./pages/events/EventsDetail";
import EventsDetailReserve from "./pages/events/EventsDetailReserve";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import Home from "./pages/Home";
import Event from "./pages/Event";
import Message from "./pages/Message";
import Profile from "./pages/Profile";
import Config from "./config";
import Rollbar from "./components/Rollbar";
import AboutMe from "./pages/profiles/AboutMe";
import AccountInfo from "./pages/profiles/AccountInfo";
import History from "./pages/profiles/History";
import TermsAndCondition from "./pages/profiles/TermsAndCondition";
import FAQ from "./pages/profiles/FAQ";
import HostOnboard from "./pages/profiles/HostOnboard";
import Signout from "./pages/profiles/Signout";
import HowPaid from "./pages/profiles/HowPaid";
import Payouts from "./pages/profiles/Payouts";
import {
  CircularProgress,
  Modal,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import { colors } from "./hooks/color";
import Init from "./pages/Init";
import Sign from "./pages/Sign";
import PasswordReset from "./pages/PasswordReset";
import { fontFamily } from "./hooks/font";
import Guideline from "./pages/profiles/Guideline";
import AdminHome from "./pages/AdminHome";
import { HostNotification, User, UserNotification } from "./interfaces";
import PromoRewards from "./pages/profiles/PromoRewards";
import AdminEvents from "./pages/profiles/AdminEvents";
import { useSelf } from "./hooks/user";
import Loading from "./components/Loading";
import LocationUtil from "./utils/location";
import Upcoming from "./pages/Upcoming";
import Search from "./pages/Search";
import RoomDetail from "./pages/RoomDetail";
import { fetchData, getCredential, getWebsocketServer } from "./api";
import AdminGuests from "./pages/profiles/AdminGuests";
import Confirmation from "./pages/Confirmation";
import NotificationsComponent from "./components/notifications/Notifications";
import AdminHosts from "./pages/profiles/AdminHosts";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      {
        path: "",
        element: <Init />,
      },
      {
        path: "beta",
        element: null,
      },
      {
        path: "unbeta",
        element: null,
      },
      {
        path: "",
        element: <Home />,
        children: [
          {
            path: "event",
            element: <Event />,
          },
          {
            path: "search",
            element: <Search />,
          },
          {
            path: "upcoming",
            element: <Upcoming />,
          },
          {
            path: "message",
            element: <Message />,
          },
          {
            path: "profile",
            element: <Profile />,
          },
        ],
      },
      {
        path: "profile",
        children: [
          {
            path: "about_me",
            element: <AboutMe />,
          },
          {
            path: "account_info",
            element: <AccountInfo />,
          },
          {
            path: "payouts",
            element: <Payouts />,
          },
          {
            path: "history",
            element: <History />,
          },
          {
            path: "terms_and_condition",
            element: <TermsAndCondition />,
          },
          {
            path: "guideline",
            element: <Guideline />,
          },
          {
            path: "faq",
            element: <FAQ />,
          },
          {
            path: "become_a_host",
            element: <HostOnboard />,
          },
          {
            path: "how_paid",
            element: <HowPaid />,
          },
          {
            path: "promo_rewards",
            element: <PromoRewards />,
          },
          {
            path: "logout",
            element: <Signout />,
          },
        ],
      },
      {
        path: "events",
        children: [
          {
            path: ":eventCode",
            element: <EventsDetail />,
          },
          {
            path: ":eventId/edit",
            element: <EventsDetailEdit />,
          },
          {
            path: ":eventCode/reserve",
            element: <EventsDetailReserve />,
          },
        ],
      },
      {
        path: "sign",
        element: <Sign />,
      },
      {
        path: "password/reset",
        element: <PasswordReset />,
      },
      {
        path: "confirmation",
        element: <Confirmation />,
      },
      {
        path: "rooms/:roomId",
        element: <RoomDetail />,
      },
      {
        path: "admin",
        element: <AdminHome />,
        children: [
          {
            path: "events",
            element: <AdminEvents />,
          },
          {
            path: "hosts",
            element: <AdminHosts />,
          },
          {
            path: "guests",
            element: <AdminGuests />,
          },
        ],
      },
    ],
  },
]);

export interface UserLocation {
  latitude: number;
  longitude: number;
}

interface GlobalContextProps {
  user: User | null;
  notifications: UserNotification[];
  host_notifications: HostNotification[];
  refreshUser: () => Promise<any>;
  toggleSpinner: () => void;
  userLocation?: UserLocation | null;
  websocket: WebSocket | null;
}

export const GlobalContext = createContext({} as GlobalContextProps);

function Root() {
  const location = useLocation();
  const { userLocation } = useContext(GlobalContext);
  const [loaded, setLoaded] = useState<boolean>(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  if (!userLocation || !loaded)
    return (
      <Loading
        loadingIn
        handleOut={() => setLoaded(true)}
        manualOut
        out={Boolean(userLocation)}
      />
    );

  return (
    <>
      <Outlet />
      <NotificationsComponent />
    </>
  );
}

const theme = createTheme({
  components: {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontFamily: fontFamily.manrope,
        },
      },
    },
  },
  palette: {
    primary: {
      main: colors.green,
    },
    error: {
      main: colors.error,
    },
  },
});

function App() {
  const { self, refresh } = useSelf();
  // yes -> userlocation no -> null
  const [userLocation, setUserLocation] = useState<
    UserLocation | null | undefined
  >(undefined);
  const [spinner, setSpinner] = useState<boolean>(false);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [websocket, setWebsocket] = useState<WebSocket | null>(null);

  useEffect(() => {
    refresh().then(() => setLoaded(true));
  }, [refresh]);

  useEffect(() => {
    if (!self || websocket) return;
    const connect = () => {
      const ws = new WebSocket(
        `${getWebsocketServer()}/ws?token=${getCredential()}`
      );
      ws.onopen = () => {
        console.log("Connected to WebSocket server");
      };
      ws.onclose = (e) => {
        console.log(
          "Socket is closed. Reconnect will be attempted in 1 second.",
          e.reason
        );
        setTimeout(() => {
          connect();
        }, 1000);
      };

      ws.onerror = (e) => {
        console.log("Socket error:", e);
      };
      setWebsocket(ws);
    };
    connect();
  }, [self, websocket]);

  useEffect(() => {
    const currentPosition = LocationUtil.getCurrentPosition();
    if (currentPosition) {
      setUserLocation({
        latitude: currentPosition.lat,
        longitude: currentPosition.lng,
      });
    } else {
      fetchData(
        "GET",
        `https://api.ipgeolocation.io/ipgeo?apiKey=${Config.getIpgeolocationApiKey()}`,
        false,
        false,
        false
      ).then((res: any) => {
        LocationUtil.setCurrentLocation(
          Number(res.latitude),
          Number(res.longitude),
          60 * 60 * 24
        );
        setUserLocation({
          latitude: Number(res.latitude),
          longitude: Number(res.longitude),
        });
      });
    }
  }, []);

  if (!loaded) return null;

  return (
    <Rollbar>
      <PayPalScriptProvider
        options={{
          clientId: Config.getPaypalClientId(),
          components: "buttons,marks,funding-eligibility",
          "enable-funding": "venmo",
          intent: "authorize",
        }}
      >
        <ThemeProvider theme={theme}>
          <GlobalContext.Provider
            value={{
              user: self?.user ?? null,
              notifications: self?.notifications ?? [],
              host_notifications: self?.host_notifications ?? [],
              refreshUser: refresh,
              toggleSpinner: () => setSpinner((spinner) => !spinner),
              userLocation,
              websocket,
            }}
          >
            <div
              className="App"
              style={{
                height: "100%",
                fontSize: 16,
                backgroundColor: colors.ivory,
                color: colors.green,
                fontWeight: 500,
                overflow: "auto",
              }}
            >
              <RouterProvider router={router} />
            </div>
            <Modal open={spinner}>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress style={{ color: "white" }} />
              </div>
            </Modal>
          </GlobalContext.Provider>
        </ThemeProvider>
      </PayPalScriptProvider>
    </Rollbar>
  );
}

export default App;
