import { Drawer } from "@mui/material";
import React, { ReactNode } from "react";
import { colors } from "../hooks/color";
import { ReactComponent as CloseSvg } from "../svgs/close.svg";

interface CustomDrawerProps {
  open?: boolean;
  handleClose?: () => void;
  children?: ReactNode;
  height?: string;
}

function CustomDrawer({
  open,
  handleClose,
  children,
  height = "80vh",
}: CustomDrawerProps) {
  return (
    <Drawer
      anchor="bottom"
      open={open}
      PaperProps={{
        sx: {
          borderRadius: "32px 32px 0px 0px",
          backgroundColor: colors.ivory,
          color: colors.green,
        },
      }}
    >
      <div
        style={{
          height,
          position: "relative",
          padding: 30,
          overflow: "auto",
        }}
      >
        {handleClose && (
          <CloseSvg
            style={{
              position: "absolute",
              top: 15,
              right: 15,
              cursor: "pointer",
              stroke: colors.green,
            }}
            onClick={handleClose}
          />
        )}
        {children}
      </div>
    </Drawer>
  );
}

export default CustomDrawer;
