import { monthNames } from "./const";

export function capitalizeFirstLetter(input: string) {
  if (input.length === 0) {
    return input; // Return unchanged if the string is empty
  }

  const firstLetter = input.charAt(0).toUpperCase();
  const restOfString = input.slice(1);

  return firstLetter + restOfString;
}

type Environment = "production" | "staging" | "development";

export function getEnvironment() {
  return (process.env.REACT_APP_ENVIRONMENT || "development") as Environment;
}

export function formatDate(inputDate: string) {
  if (!inputDate) return "";
  const [year, month, day] = inputDate.split("-").map(Number);
  const formattedDate = `${monthNames[month - 1]} ${day}, ${year}`;
  return formattedDate;
}

export function getLocalYearMonthDay(dateTimeStr: string) {
  const dateObj = new Date(dateTimeStr);
  return [dateObj.getFullYear(), dateObj.getMonth() + 1, dateObj.getDate()];
}

export function formatPrice(price: number) {
  if (price <= 50) {
    return "$";
  }
  if (price <= 100) {
    return "$$";
  }
  return "$$$";
}

export function formatTwoDecimalNumber(price: number) {
  return formatNumberDigits(price, 2);
}

export function formatNumberDigits(number: number, digits: number) {
  const formattedNumber = number.toLocaleString("en-US", {
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  });
  return formattedNumber;
}

export function getEmail() {
  return `info@thetably.com`;
}

export function calculateMonthsElapsed(dateString: string) {
  // Convert the input string to a Date object
  const startDateObj = new Date(dateString);

  // Get the current date
  const currentDate = new Date();

  // Calculate the difference in months with day precision
  const yearsDifference =
    currentDate.getFullYear() - startDateObj.getFullYear();
  const monthsDifference = currentDate.getMonth() - startDateObj.getMonth();
  const dayDifference = currentDate.getDate() - startDateObj.getDate();

  // Calculate the total number of months elapsed
  const monthsElapsed =
    yearsDifference * 12 + monthsDifference + (dayDifference >= 0 ? 0 : -1) + 1;

  return Math.max(monthsElapsed, 0);
}

export function formatPhoneNumber(phone: string) {
  const cleaned = phone.replace(/\D/g, "");
  if (cleaned.length <= 3) {
    return cleaned;
  } else if (cleaned.length <= 6) {
    return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3)}`;
  }
  return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}-${cleaned.slice(
    6,
    10
  )}`;
}

export function convertToInternationalFormat(formattedPhoneNumber: string) {
  // Remove non-numeric characters
  const numericOnly = formattedPhoneNumber.replace(/\D/g, "");

  // Add the international dialing code
  return `+1${numericOnly}`;
}

export function convertTo12HourFormat(time24: string) {
  // Split the time string into hours and minutes
  let [hours, minutes] = time24.split(":").map(Number);

  // Determine the period (AM or PM)
  let period = hours >= 12 ? "PM" : "AM";

  // Convert hours to 12-hour format
  hours = hours % 12 || 12; // Handle midnight (0:00)

  // Format the time in 12-hour format with AM/PM indicator
  let time12 = hours + ":" + (minutes < 10 ? "0" : "") + minutes + period;

  return time12;
}

export function timeFromToLabel(
  startTime: string,
  endTime: string,
  timezone: string
) {
  return `${convertTo12HourFormat(startTime)} - ${convertTo12HourFormat(
    endTime
  )} ${timezone}`;
}
