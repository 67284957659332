import { Dialog } from "@mui/material";
import React, { ReactNode } from "react";
import { ReactComponent as CloseSvg } from "../svgs/close.svg";
import { colors } from "../hooks/color";

interface CustomDialogProps {
  open: boolean;
  children?: ReactNode;
  handleClose?: () => void;
  fullHeight?: boolean;
  fullWidth?: boolean;
}

function CustomDialog({
  open,
  children,
  handleClose,
  fullHeight,
  fullWidth,
}: CustomDialogProps) {
  return (
    <Dialog
      open={open}
      maxWidth="xs"
      PaperProps={{
        style: {
          borderRadius: "20px",
          backgroundColor: colors.ivory,
          color: colors.green,
        },
      }}
      fullWidth={fullWidth}
    >
      <div
        style={{
          maxWidth: "85vw",
          position: "relative",
          padding: 30,
          height: fullHeight ? "100%" : undefined,
        }}
      >
        {handleClose && (
          <CloseSvg
            style={{
              position: "absolute",
              top: 5,
              right: 5,
              cursor: "pointer",
              stroke: colors.green,
              zIndex: 100,
            }}
            onClick={handleClose}
          />
        )}
        {children}
      </div>
    </Dialog>
  );
}

export default CustomDialog;
