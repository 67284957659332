import { useUserHooks } from "../../hooks/user";
import { useEffect } from "react";

function Signout() {
  const { signout } = useUserHooks();

  useEffect(() => {
    signout();
  }, [signout]);

  return null;
}

export default Signout;
