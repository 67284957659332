import React from "react";
import Backward from "./Backward";

interface HeaderProps {
  handleBack?: () => void;
  label?: string;
}

function Header({ handleBack, label }: HeaderProps) {
  return (
    <div
      style={{
        marginTop: 15,
        height: 55,
        fontSize: 24,
        fontWeight: 600,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
      }}
    >
      {label || ""}
      {handleBack && (
        <Backward
          handleClick={handleBack}
          style={{
            position: "absolute",
            top: "50%",
            transform: "translateY(-50%)",
            left: 30,
          }}
        />
      )}
    </div>
  );
}

export default Header;
