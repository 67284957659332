import React, { Fragment, useState } from "react";
import { colors, useColor } from "../hooks/color";
import { fontFamily } from "../hooks/font";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Horizontal from "./Horizontal";

interface ToggleLabelProps {
  labelTypes: string[];
  labelFunc: (labelType: string) => string;
  handleLabelType: (labelType: string) => void;
  labelType: string;
}

function ToggleLabel({
  labelTypes,
  labelFunc,
  handleLabelType,
  labelType,
}: ToggleLabelProps) {
  const color = useColor();
  const [toggled, setToggled] = useState<boolean>(false);
  return (
    <>
      <div
        style={{
          backgroundColor: colors.ivory,
          position: "relative",
          zIndex: 10,
          padding: 30,
          paddingBottom: 0,
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontSize: 24,
            color,
            cursor: "pointer",
          }}
          onClick={() => {
            setToggled(!toggled);
          }}
        >
          <div
            style={{
              fontFamily: fontFamily.simula,
            }}
          >
            {labelFunc(labelType)}
          </div>
          <ExpandMoreIcon
            style={{
              color,
              fontSize: 35,
              transform: toggled ? "rotateX(180deg)" : undefined,
            }}
          />
        </div>
        <Horizontal marginTop={5} thick horizontalColor={color} />
        {toggled && (
          <div
            style={{
              position: "absolute",
              top: "100%",
              left: 0,
              right: 0,
              zIndex: 10,
              backgroundColor: colors.ivory,
              padding: 30,
              paddingTop: 0,
            }}
          >
            {labelTypes.map((currLabelType, index) => (
              <Fragment key={index}>
                {index > 0 && <Horizontal marginTop={15} />}
                <div
                  style={{
                    marginTop: 15,
                    fontWeight: 500,
                    fontSize: 20,
                    color: colors.green,
                    cursor: currLabelType === labelType ? "default" : "pointer",
                    opacity: currLabelType === labelType ? 0.2 : 1,
                  }}
                  onClick={() => {
                    handleLabelType(currLabelType);
                    setToggled(!toggled);
                  }}
                >
                  {labelFunc(currLabelType)}
                </div>
              </Fragment>
            ))}
          </div>
        )}
      </div>
      {toggled && (
        <div
          style={{
            position: "absolute",
            inset: 0,
            zIndex: 3,
            backgroundColor: colors.black,
            opacity: 0.5,
          }}
        ></div>
      )}
    </>
  );
}

export default ToggleLabel;
