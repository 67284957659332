import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import React, { ReactNode } from "react";

interface RadioInputItem {
  label: ReactNode;
  value: string;
}

interface RadioInputProps {
  value: string;
  items: RadioInputItem[];
  row?: boolean;
  handleChange?: (value: string) => void;
  left?: boolean;
}

function RadioInput({
  value,
  items,
  row,
  handleChange,
  left,
}: RadioInputProps) {
  return (
    <RadioGroup
      row={row}
      value={value}
      onChange={(e) => handleChange && handleChange(e.target.value)}
    >
      {items.map((item, index) => (
        <FormControlLabel
          value={item.value}
          control={<Radio />}
          label={item.label}
          key={index}
          labelPlacement={left ? "start" : "end"}
          sx={{
            "& .MuiFormControlLabel-label": {
              display: "flex",
            },
          }}
        />
      ))}
    </RadioGroup>
  );
}

export default RadioInput;
