import { useCallback, useEffect, useState } from "react";
import { fetchData } from "../api";
import { HostPayoutObject, User } from "../interfaces";

interface PostHostBody {
  aboutMe: string;
  experienceInPartySize: string;
  languages: string[];
  payout: HostPayoutObject;
}

interface PutHostBody {
  aboutMe: string;
  experienceInPartySize: string;
  languages: string[];
  payout: HostPayoutObject;
}

export function useHosts() {
  const [hostUsers, setHostUsers] = useState<User[] | null>(null);

  const refresh = useCallback(() => {
    return fetchData<User[]>("GET", "/hosts").then(setHostUsers);
  }, []);

  useEffect(() => {
    refresh();
  }, [refresh]);

  return { hostUsers, refresh };
}

export function useHostHooks() {
  const createHost = ({
    aboutMe,
    experienceInPartySize,
    languages,
    payout,
  }: PostHostBody) => {
    return fetchData("POST", "/hosts/signup", {
      about_me: aboutMe,
      experience_in_party_size: experienceInPartySize,
      languages,
      payout,
    });
  };

  const putHost = ({
    aboutMe,
    experienceInPartySize,
    languages,
    payout,
  }: PutHostBody) => {
    return fetchData("PUT", "/hosts", {
      about_me: aboutMe,
      experience_in_party_size: experienceInPartySize,
      languages,
      payout,
    });
  };

  return {
    createHost,
    putHost,
  };
}
