export const USStates = [
  "AL",
  "AK",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "FL",
  "GA",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
];

export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const abbrMonths = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export const idVerificationRejectTypes = [
  "Blurry or Low-Quality Image",
  "Incomplete Information",
  "Expired ID",
  "Unsupported ID Type",
  "Mismatch Between ID and User Information",
  "ID Damaged or Altered",
  "ID Not Legible",
  "Uploaded File is Corrupted",
  "Fake or Forged ID",
  "Insufficient Coverage in the Image",
  "Other",
];

export const hostRejectTypes = [
  "Incomplete Profile Information",
  "Invalid or Inaccurate Contact Information",
  "Previous Negative Reviews",
  "Conflict with Platform Guidelines",
  "Suspicious Activity or Fraud Concerns",
  "Unverified Payment Information",
  "Host Does Not Meet Age Requirement",
  "Unclear or Vague Profile Description",
  "Violation of Terms of Service",
  "Other",
];

export const rejectTypes = [
  "Incomplete Profile Information",
  "Invalid or Inaccurate Contact Information",
  "Previous Negative Reviews",
  "Conflict with Platform Guidelines",
  "Suspicious Activity or Fraud Concerns",
  "Unverified Payment Information",
  "Host Does Not Meet Age Requirement",
  "Unclear or Vague Profile Description",
  "Violation of Terms of Service",
  "Other",
];

export const cancelTypes = [
  "Personal Emergencies",
  "Unforeseen Circumstances",
  "Inclement Weather",
  "Supply Shortages",
  "Illness",
  "Home Maintenance Issues",
  "Health and Safety Concerns",
  "Transportation Issues",
  "Legal or Regulatory Issues",
  "Other",
];

export const worldLanguages = [
  "Afrikaans",
  "Albanian",
  "Amharic",
  "Arabic",
  "Armenian",
  "Azerbaijani (Azeri)",
  "Bassa",
  "Belarusian",
  "Bengali",
  "Bosnian",
  "Braille",
  "Bulgarian",
  "Burmese",
  "Cambodian (Khmer)",
  "Cape Verde Creole",
  "Cebuano",
  "Chinese",
  "Chuukese",
  "Croatian",
  "Czech",
  "Danish",
  "Dari",
  "Dutch",
  "English",
  "Estonian",
  "Farsi (Persian)",
  "Finnish",
  "Flemmish",
  "French",
  "Fulani",
  "Georgian",
  "German",
  "Greek",
  "Gujarati",
  "Haitian Creole",
  "Hakha Chin",
  "Hakka (Chinese)",
  "Hebrew",
  "Hindi",
  "Hmong",
  "Hungarian",
  "Icelandic",
  "Igbo/Ibo",
  "Ilocano",
  "Ilonggo (Hiligaynon)",
  "Indonesian",
  "Italian",
  "Japanese",
  "Javanese",
  "Kannada",
  "Karen",
  "Kazakh",
  "Kinyarwanda",
  "Kirundi",
  "Korean",
  "Kurdish",
  "Kyrgyz/Kirgiz",
  "Lao (Laotian)",
  "Latvian",
  "Lithuanian",
  "Macedonian",
  "Malay (Malaysian)",
  "Mandinka",
  "Marathi",
  "Marshallese",
  "Mien",
  "Mongolian",
  "Montenegrin",
  "Navajo",
  "Nepali",
  "Norwegian",
  "Oromo",
  "Pashto",
  "Polish",
  "Portuguese (Brazil)",
  "Portuguese (Portugal)",
  "Punjabi",
  "Rohingya",
  "Romanian (Moldavan)",
  "Russian",
  "Serbian",
  "Slovak",
  "Slovenian",
  "Somali",
  "Spanish",
  "Swahili",
  "Swedish",
  "Tagalog",
  "Tamil",
  "Telugu",
  "Thai",
  "Tibetan",
  "Tigrinya",
  "Turkish",
  "Ukrainian",
  "Urdu",
  "Uzbek",
  "Vietnamese",
  "Wolof",
  "Yoruba",
];

export const restrictions = [
  "None",
  "Vegetarian",
  "Vegan",
  "Gluten-Free",
  "Dairy-Free",
  "Nut-Free",
  "Shellfish-Free",
  "Egg-Free",
  "Soy-Free",
  "Paleo",
  "Keto",
  "Halal",
  "Kosher",
  "Pescatarian",
  "No Pork",
  "No Beef",
  "No Lamb",
  "No Seafood",
  "Low Sodium",
  "Diabetic-Friendly",
];

export const allergens = [
  "Milk",
  "Eggs",
  "Fish",
  "Shellfish",
  "Tree Nuts",
  "Peanuts",
  "Wheat",
  "Soybeans",
  "Sesame",
];

export const locationTypes = [
  "Apartment",
  "Backyard",
  "Event Venue",
  "House",
  "Shared Kitchken",
  "Other",
];

export const cuisines = [
  "American",
  "Arab",
  "Caribbean",
  "Chinese",
  "Filipino",
  "French",
  "German",
  "Greek",
  "Hawaiian",
  "Indian",
  "Italian",
  "Japanese",
  "Mexican",
  "Korean",
  "Spanish",
  "Taiwanese",
  "Thai",
  "Turkish",
  "Vietnames",
  "Other",
];

export const eventTypes = ["Breakfast", "Brunch", "Lunch", "Dinner", "Party"];

export const guestCancelTypes = [
  "Inappropriate Behavior or Concerns",
  "Unclear or Misleading Profile",
  "Health Concerns",
  "Changes in Event Details",
  "Safety Concerns",
  "Other",
];
