import React, { ReactNode } from "react";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { colors } from "../hooks/color";
import Horizontal from "../components/Horizontal";

interface AccordionSelectItem {
  label: ReactNode;
  value: string;
}

interface AccordionSelectProps {
  label: ReactNode;
  items: AccordionSelectItem[];
  value?: string;
  handleClick?: (value: string) => void;
}

function AccordionSelect({
  label,
  items,
  value,
  handleClick,
}: AccordionSelectProps) {
  const current = items.find((item) => item.value === value);
  return (
    <Accordion
      elevation={0}
      style={{
        backgroundColor: "inherit",
        color: colors.green,
      }}
      disableGutters
      sx={{
        "&::before": {
          display: "none",
        },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon style={{ color: colors.green }} />}
        sx={{
          padding: 0,
          minHeight: 0,
          "& .MuiAccordionSummary-content": {
            margin: 0,
          },
          alignItems: "flex-start",
        }}
      >
        <div>
          {label}
          {current && <div style={{ marginTop: 10 }}>{current.label}</div>}
        </div>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: 0 }}>
        <Horizontal marginTop={30} />
        {items.map((item, index) => (
          <div
            key={index}
            style={{
              marginTop: 20,
              textAlign: "center",
              fontWeight: 600,
              color: current === item ? colors.orange : colors.green,
            }}
            onClick={() => handleClick && handleClick(item.value)}
          >
            {item.label}
          </div>
        ))}
      </AccordionDetails>
    </Accordion>
  );
}

export default AccordionSelect;
