import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { fetchData } from "../../api";
import { PayPalButtons } from "@paypal/react-paypal-js";
import Backward from "../../components/Backward";
import CustomImage from "../../components/CustomImage";
import StarIcon from "@mui/icons-material/Star";
import Horizontal from "../../components/Horizontal";
import SelectInput from "../../inputs/SelectInput";
import {
  formatDate,
  formatNumberDigits,
  formatTwoDecimalNumber,
  timeFromToLabel,
} from "../../util";
import Success from "../../components/Success";
import SmallLogo from "../../components/logos/SmallLogo";
import { CustomerEventStatus } from "../../enums";

interface SuccessComponentProps {
  title: string;
}

function SuccessComponent({ title }: SuccessComponentProps) {
  const params = useParams();
  const navigate = useNavigate();
  return (
    <div style={{ padding: 30, height: "100%" }}>
      <SmallLogo />
      <Success
        label={
          <div style={{ fontSize: 24 }}>
            <div>Your reservation to</div>
            <div style={{ marginTop: 5, fontWeight: 700 }}>{title}</div>
            <div style={{ marginTop: 5 }}>is complete!</div>
          </div>
        }
        buttonLabel="Continue"
        handleClick={() => navigate(`/events/${params.eventCode}`)}
      />
    </div>
  );
}

interface EventReservationDetail {
  id: number;
  event_code: string;
  image: string;
  title: string;
  host: {
    firstname: string;
    rating: number;
  };
  date: string;
  start_time: string;
  end_time: string;
  timezone: string;
  max_spots: number;
  price: number;
  home_rule: string;
  customer_status: CustomerEventStatus;
}

function EventsDetailReserve() {
  const [partySize, setPartySize] = useState<number>(1);
  const [success, setSuccess] = useState<boolean>(false);
  const [eventReservationDetail, setEventReservationDetail] =
    useState<EventReservationDetail | null>(null);
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    fetchData<EventReservationDetail>(
      "GET",
      `/events/${params.eventCode}/reservation`
    ).then(setEventReservationDetail);
  }, [params]);

  if (!eventReservationDetail) return null;
  if (success) return <SuccessComponent title={eventReservationDetail.title} />;

  return (
    <>
      <Backward handleClick={() => navigate(-1)} />
      <div style={{ padding: 30 }}>
        <div style={{ display: "flex", columnGap: 20 }}>
          <div style={{ flex: 1 }}>
            <CustomImage
              src={eventReservationDetail.image}
              height={150}
              width="100%"
            />
          </div>
          <div style={{ flex: 1 }}>
            <div style={{ fontWeight: 700 }}>
              {eventReservationDetail.title}
            </div>
            <div style={{ marginTop: 10, display: "flex" }}>
              {formatNumberDigits(eventReservationDetail.host.rating, 1)}
              <StarIcon fontSize="small" style={{ marginLeft: 5 }} />
            </div>
            <div style={{ marginTop: 10 }}>
              Hosted by {eventReservationDetail.host.firstname}
            </div>
          </div>
        </div>
        <Horizontal marginTop={15} marginBottom={15} mode />
        <div style={{ fontWeight: 700 }}>Event details</div>
        <div style={{ marginTop: 5 }}>
          {formatDate(eventReservationDetail.date)}
        </div>
        <div style={{ marginTop: 5 }}>
          {timeFromToLabel(
            eventReservationDetail.start_time,
            eventReservationDetail.end_time,
            eventReservationDetail.timezone
          )}
        </div>
        {eventReservationDetail.customer_status ===
          CustomerEventStatus.live && (
          <div style={{ marginTop: 5 }}>
            <SelectInput
              name="party_size"
              label="Party size"
              value={String(partySize)}
              items={(() => {
                const res = [];
                for (let i = 1; i <= eventReservationDetail.max_spots; i++) {
                  res.push({
                    label: i,
                    value: String(i),
                  });
                }
                return res;
              })()}
              handleChange={(value) => setPartySize(Number(value))}
            />
          </div>
        )}
        {eventReservationDetail.customer_status ===
          CustomerEventStatus.live && (
          <>
            <Horizontal marginTop={15} marginBottom={15} mode />
            <div style={{ fontWeight: 700 }}>Price details</div>
            <div
              style={{
                marginTop: 5,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div>
                ${formatTwoDecimalNumber(eventReservationDetail.price)} x{" "}
                {partySize}
              </div>
              <div>
                $
                {formatTwoDecimalNumber(
                  eventReservationDetail.price * partySize
                )}
              </div>
            </div>
            <Horizontal marginTop={15} marginBottom={15} />
            <div
              style={{
                marginTop: 5,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div>Total (USD)</div>
              <div>
                $
                {formatTwoDecimalNumber(
                  eventReservationDetail.price * partySize
                )}
              </div>
            </div>
            <Horizontal marginTop={15} marginBottom={15} mode />
            <div style={{ fontWeight: 700 }}>Payment instructions</div>
            <div style={{ marginTop: 15 }}>
              <PayPalButtons
                key={partySize}
                style={{ color: "blue" }}
                createOrder={() =>
                  fetchData<any>(
                    "POST",
                    `/events/${eventReservationDetail.id}/orders`,
                    {
                      party_size: partySize,
                    }
                  ).then((orderData) => orderData.id)
                }
                onApprove={(data, actions) =>
                  fetchData("POST", `/orders/${data.orderID}/capture`).then(
                    () => setSuccess(true)
                  )
                }
              >
                <h3 style={{ color: "#dc3545", textTransform: "capitalize" }}>
                  You are not eligible to pay with Venmo.
                </h3>
              </PayPalButtons>
            </div>
            <Horizontal marginTop={15} marginBottom={15} mode />
            <div style={{ fontWeight: 700 }}>Cancellation policy</div>
            <div style={{ marginTop: 5 }}>
              Guests can cancel their attendance to a dinner party up to 48
              hours before the scheduled start time without any penalty.
              Cancellations made within 48 hours will result in a non-refundable
              fee equivalent to 50% of the total booking cost. No-shows will be
              charged the full booking amount.
            </div>
          </>
        )}
        <Horizontal marginTop={15} marginBottom={15} mode />
        <div style={{ fontWeight: 700 }}>House rules</div>
        <div style={{ marginTop: 5 }}>{eventReservationDetail.home_rule}</div>
      </div>
    </>
  );
}

export default EventsDetailReserve;
