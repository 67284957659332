import React, { useContext, useEffect, useState } from "react";
import Backward from "../components/Backward";
import { colors } from "../hooks/color";
import TextInput, { PhoneInput } from "../inputs/TextInput";
import RoundButton from "../components/RoundButton";
import { fontFamily } from "../hooks/font";
import { fetchData, setCredential } from "../api";
import { useLocation, useNavigate } from "react-router-dom";
import { GlobalContext } from "../App";
import Horizontal from "../components/Horizontal";
import SelectInput from "../inputs/SelectInput";
import { USStates, restrictions } from "../const";
import CustomCheckbox from "../inputs/CustomCheckbox";
import CustomDialog from "../components/CustomDialog";
import TimeUtil from "../utils/time";
import TextUtil from "../utils/text";

interface SignInitProps {
  email: string;
  setEmail: (email: string) => void;
  handleContinue: () => void;
}

function SignInit({ email, setEmail, handleContinue }: SignInitProps) {
  const navigate = useNavigate();
  const [condition, setCondition] = useState<boolean | null>(null);

  return (
    <div
      style={{
        padding: "90px 30px",
      }}
    >
      <Backward handleClick={() => navigate(-1)} />
      <div
        style={{
          fontSize: 24,
          fontFamily: fontFamily.simula,
        }}
      >
        One step closer to your meal
      </div>
      <div
        style={{
          marginTop: 15,
          color: colors.orange,
        }}
      >
        Sign up or create an account
      </div>
      <div
        style={{
          marginTop: 15,
        }}
      >
        <TextInput
          type="email"
          label="Email"
          value={email}
          handleChange={setEmail}
          errorMessage={
            condition === false ? "Please enter a valid email" : undefined
          }
        />
      </div>
      <div
        style={{
          marginTop: 15,
          textAlign: "end",
        }}
      >
        <RoundButton
          backgroundColor={colors.green}
          color={colors.white}
          handleClick={() => {
            if (!TextUtil.isValidEmail(email)) {
              setCondition(false);
              return;
            }
            handleContinue();
          }}
        >
          Continue
        </RoundButton>
      </div>
    </div>
  );
}

function EmailConfirmation() {
  const navigate = useNavigate();
  const location = useLocation();
  const redirect = location.state.redirect || "/";
  const { refreshUser } = useContext(GlobalContext);

  return (
    <div style={{ position: "relative", height: "100%" }}>
      <div
        style={{
          position: "absolute",
          inset: 0,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: 250,
            textAlign: "center",
          }}
        >
          <div style={{ fontSize: 30, fontFamily: fontFamily.simula }}>
            We’ve sent you a confirmation email.
          </div>
          <div style={{ marginTop: 30, fontSize: 14 }}>
            Please check your inbox and confirm your email.
          </div>
          <div
            style={{
              marginTop: 15,
              fontSize: 14,
              color: colors.orange,
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => fetchData("POST", `/users/confirmation/resend`)}
          >
            Resend Email
          </div>
          <div style={{ marginTop: 30 }}>
            <RoundButton
              style={{ width: 130 }}
              handleClick={() => {
                refreshUser().then(() =>
                  navigate(redirect, {
                    replace: true,
                  })
                );
              }}
            >
              Done
            </RoundButton>
          </div>
        </div>
      </div>
    </div>
  );
}

interface SignupProps {
  email: string;
  handleBack: () => void;
}

enum SignupStep {
  signup = "signup",
  confirmation = "confirmation",
}

function Signup({ email, handleBack }: SignupProps) {
  const [firstname, setFirstname] = useState<string>("");
  const [lastname, setLastname] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [birthday, setBirthday] = useState<string>("");
  const [address1, setAddress1] = useState<string>("");
  const [address2, setAddress2] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [state, setState] = useState<string>("");
  const [zipcode, setZipcode] = useState<string>("");
  const [restriction, setRestriction] = useState<string>("");
  const [termsAndCondition, setTermsAndCondition] = useState<boolean>(false);
  const { refreshUser } = useContext(GlobalContext);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [step, setStep] = useState<SignupStep>(SignupStep.signup);

  const signup = () => {
    return fetchData<{ token: string }>("POST", "/signup", {
      email,
      firstname,
      lastname,
      phone,
      password,
      confirm_password: confirmPassword,
      birthday,
      address1,
      address2,
      city,
      state,
      zipcode,
      restriction,
      terms_and_condition: termsAndCondition,
    })
      .then(({ token }) => setCredential(token))
      .then(refreshUser)
      .then(() => setStep(SignupStep.confirmation))
      .catch(setErrors);
  };

  if (step === SignupStep.confirmation) return <EmailConfirmation />;

  const disabledCondition = !(
    Boolean(firstname) &&
    Boolean(lastname) &&
    Boolean(phone) &&
    Boolean(password) &&
    Boolean(confirmPassword) &&
    Boolean(birthday) &&
    Boolean(address1) &&
    Boolean(city) &&
    Boolean(state) &&
    Boolean(zipcode) &&
    Boolean(restriction) &&
    Boolean(termsAndCondition)
  );

  return (
    <div
      style={{
        padding: "90px 30px",
      }}
    >
      <Backward handleClick={handleBack} />
      <div
        style={{
          fontSize: 24,
          fontFamily: fontFamily.simula,
        }}
      >
        Sign up
      </div>
      <div
        style={{
          marginTop: 50,
        }}
      >
        <TextInput name="email" label="Email" defaultValue={email} disabled />
      </div>
      <div
        style={{
          marginTop: 15,
        }}
      >
        <TextInput
          name="firstname"
          label="First Name"
          defaultValue={firstname}
          handleChange={setFirstname}
        />
      </div>
      <div
        style={{
          marginTop: 15,
        }}
      >
        <TextInput
          name="lastname"
          label="Last Name"
          defaultValue={lastname}
          handleChange={setLastname}
        />
      </div>
      <div
        style={{
          marginTop: 15,
        }}
      >
        <PhoneInput
          name="phone"
          type="text"
          label="Phone"
          handleChange={setPhone}
          value={phone}
          errorMessage={errors.phone}
        />
      </div>
      <div
        style={{
          marginTop: 15,
        }}
      >
        <TextInput
          name="password"
          type="password"
          label="Password"
          handleChange={setPassword}
          defaultValue={password}
          errorMessage={errors.password}
        />
      </div>
      <div
        style={{
          marginTop: 15,
        }}
      >
        <TextInput
          type="password"
          label="Confirm Password"
          handleChange={setConfirmPassword}
          defaultValue={confirmPassword}
          errorMessage={errors.confirm_password}
        />
      </div>
      <div style={{ marginTop: 15 }}>
        <TextInput
          name="birthday"
          type="date"
          label="Birthday"
          handleChange={setBirthday}
          defaultValue={birthday}
          errorMessage={errors.birthday}
          inputProps={{
            max: TimeUtil.getNowDateString(),
          }}
        />
      </div>
      <Horizontal marginTop={30} marginBottom={30} opacity={0.3} />
      <div>
        <TextInput
          name="address1"
          label="Street Address"
          handleChange={setAddress1}
          defaultValue={address1}
        />
      </div>
      <div style={{ marginTop: 15 }}>
        <TextInput
          name="address2"
          label="Apt. / Suite Number (Optional)"
          handleChange={setAddress2}
          defaultValue={address2}
        />
      </div>
      <div style={{ marginTop: 15 }}>
        <TextInput
          name="city"
          label="City"
          handleChange={setCity}
          defaultValue={city}
        />
      </div>
      <div style={{ marginTop: 15 }}>
        <SelectInput
          name="state"
          label="State"
          value={state}
          items={USStates.map((state) => ({
            value: state,
            label: state,
          }))}
          handleChange={setState}
        />
      </div>
      <div style={{ marginTop: 15 }}>
        <TextInput
          name="zipcode"
          label="Zip Code"
          handleChange={setZipcode}
          defaultValue={zipcode}
        />
      </div>
      <Horizontal marginTop={30} marginBottom={30} opacity={0.3} />
      <SelectInput
        name="restriction"
        label="Dietary restriction"
        value={restriction}
        items={restrictions.map((restriction) => ({
          value: restriction,
          label: restriction,
        }))}
        handleChange={setRestriction}
      />
      <div style={{ marginTop: 30, fontSize: 14 }}>
        <CustomCheckbox
          label="By signing up, I agree to terms and condition."
          checked={termsAndCondition}
          handleCheck={setTermsAndCondition}
        />
      </div>
      <div style={{ marginTop: 30, textAlign: "center" }}>
        <RoundButton
          backgroundColor={colors.green}
          color={colors.white}
          handleClick={signup}
          disabled={disabledCondition}
        >
          Create Account
        </RoundButton>
      </div>
    </div>
  );
}

interface SigninProps {
  email: string;
  handleBack: () => void;
}

function Signin({ email, handleBack }: SigninProps) {
  const [password, setPassword] = useState<string>("");
  const navigate = useNavigate();
  const { refreshUser } = useContext(GlobalContext);
  const [error, setError] = useState<string>("");
  const [openForgot, setOpenForgot] = useState<boolean>(false);
  const [emailSent, setEmailSent] = useState<boolean>(false);
  const location = useLocation();
  const redirect = location.state.redirect || "/";

  const signin = () => {
    fetchData<{ token: string }>("POST", "/signin", {
      email,
      password,
    })
      .then(({ token }) => setCredential(token))
      .then(refreshUser)
      .then(() => navigate(redirect))
      .catch(setError);
  };

  const handleForgot = () => setOpenForgot(true);

  const forgotEmailSend = () => {
    fetchData<{ token: string }>("POST", "/password/reset", {
      email,
    })
      .then(() => setOpenForgot(false))
      .then(() => setEmailSent(true))
      .catch(alert);
  };

  return (
    <>
      <div
        style={{
          padding: "90px 30px",
        }}
      >
        <Backward handleClick={handleBack} />
        <div
          style={{
            fontSize: 24,
            fontFamily: fontFamily.simula,
          }}
        >
          Welcome back
        </div>
        <div
          style={{
            marginTop: 15,
          }}
        >
          <TextInput label="Email" type="email" value={email} disabled />
        </div>
        <div
          style={{
            marginTop: 15,
          }}
        >
          <TextInput
            type="password"
            label="Password"
            handleChange={setPassword}
            defaultValue={password}
            errorMessage={error}
          />
        </div>
        <div
          style={{
            marginTop: 15,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div style={{ textDecoration: "underline" }} onClick={handleForgot}>
            Forgot Password?
          </div>
          <div>
            <RoundButton
              backgroundColor={colors.green}
              color={colors.white}
              handleClick={signin}
            >
              Sign In
            </RoundButton>
          </div>
        </div>
      </div>
      <CustomDialog open={openForgot} handleClose={() => setOpenForgot(false)}>
        <div
          style={{
            fontFamily: fontFamily.simula,
            color: colors.orange,
            fontSize: 26,
          }}
        >
          Reset Password
        </div>
        <div style={{ marginTop: 30 }}>
          Press send to receive password reset with given email.
        </div>
        <div style={{ marginTop: 30, textAlign: "center" }}>
          <RoundButton
            backgroundColor={colors.green}
            color={colors.white}
            style={{ width: 150 }}
            handleClick={forgotEmailSend}
          >
            Send
          </RoundButton>
        </div>
      </CustomDialog>
      <CustomDialog open={emailSent}>
        <div
          style={{
            fontSize: 24,
            fontFamily: fontFamily.simula,
          }}
        >
          Password reset link has been sent to your email.
        </div>
        <span
          style={{
            marginTop: 15,
            color: colors.orange,
            textDecoration: "underline",
            cursor: "pointer",
          }}
          onClick={forgotEmailSend}
        >
          Resend Email
        </span>
        <div style={{ marginTop: 30, textAlign: "center" }}>
          <RoundButton
            backgroundColor={colors.green}
            color={colors.white}
            handleClick={() => setEmailSent(false)}
          >
            Done
          </RoundButton>
        </div>
      </CustomDialog>
    </>
  );
}

function Sign() {
  const [email, setEmail] = useState<string>("");
  const [verified, setVerified] = useState<boolean | null>(null);
  const { user } = useContext(GlobalContext);
  const navigate = useNavigate();

  const verify = () => {
    return fetchData<{ verified: boolean }>("POST", "/users/verify", {
      email,
    }).then(({ verified }) => setVerified(verified));
  };

  useEffect(() => {
    // if user is there, then we don't put here
    if (!user) return;
    navigate("/", { replace: true });
  }, [user, navigate]);

  if (verified === null)
    return (
      <SignInit email={email} setEmail={setEmail} handleContinue={verify} />
    );
  if (!verified)
    return <Signup email={email} handleBack={() => setVerified(null)} />;
  return <Signin email={email} handleBack={() => setVerified(null)} />;
}

export default Sign;
