import { useNavigate } from "react-router-dom";
import { fetchData } from "../api";
import { useCurrentMode } from "./mode";
import { useEffect, useState } from "react";
import { EventDetail, EventItem, HostEvent } from "../interfaces";
import { EventStatus } from "../enums";

type EventType = "all" | "upcoming" | "history";

export function useHostedEventItems(host_id: number) {
  const [eventItems, setEventItems] = useState<EventItem[] | null>(null);
  const [render, setRender] = useState<number>(0);

  useEffect(() => {
    if (host_id === 0) return;
    fetchData<EventItem[]>("GET", `/hosts/${host_id}/events/items`).then(
      setEventItems
    );
  }, [host_id, render]);

  const refresh = () => setRender((render) => render + 1);

  return { eventItems, refresh };
}

export function useAttendedEventItems(user_id: number) {
  const [eventItems, setEventItems] = useState<EventItem[] | null>(null);
  const [render, setRender] = useState<number>(0);

  useEffect(() => {
    fetchData<EventItem[]>("GET", `/users/${user_id}/events/items`).then(
      setEventItems
    );
  }, [user_id, render]);

  const refresh = () => setRender((render) => render + 1);

  return { eventItems, refresh };
}

export function useEventList(type: EventType) {
  const [eventItems, setEventItems] = useState<EventItem[] | null>(null);
  const [render, setRender] = useState<number>(0);
  const currentMode = useCurrentMode();

  useEffect(() => {
    fetchData<EventItem[]>(
      "GET",
      `/events?mode=${currentMode}&type=${type}`
    ).then(setEventItems);
  }, [currentMode, type, render]);

  const refresh = () => setRender((render) => render + 1);

  return { eventItems, refresh };
}

interface useEventDetailProps {
  eventId?: number;
  eventCode?: string;
}

export function useEventDetail({ eventId, eventCode }: useEventDetailProps) {
  const [eventDetail, setEventDetail] = useState<EventDetail | null>(null);
  const [render, setRender] = useState<number>(0);

  useEffect(() => {
    fetchData<EventDetail>(
      "GET",
      `/events/detail?${(() => {
        if (eventCode) return `event_code=${eventCode}`;
        return `event_id=${eventId}`;
      })()}`
    ).then(setEventDetail);
  }, [eventCode, render]);

  const refresh = () => setRender(render + 1);

  return { eventDetail, refresh };
}

export function useEventHooks() {
  const navigate = useNavigate();
  const patchStatus = (eventId: number, status: EventStatus, body?: any) => {
    return fetchData<HostEvent>("PATCH", `/events/${eventId}/status`, {
      status,
      ...body,
    });
  };

  const preCreateEvent = () => {
    return navigate(`/events/new/edit`);
  };

  const createEvent = () => {
    return fetchData<HostEvent>("POST", "/events");
  };

  const deleteEvent = (eventId: number) => {
    return fetchData<HostEvent>("DELETE", `/events/${eventId}`);
  };

  return {
    patchStatus,
    createEvent,
    deleteEvent,
    preCreateEvent,
  };
}
