import { useContext, useEffect, useState } from "react";
import TextInput from "../../inputs/TextInput";
import SelectInput from "../../inputs/SelectInput";
import {
  USStates,
  allergens,
  cuisines,
  eventTypes,
  locationTypes,
} from "../../const";
import { fetchData } from "../../api";
import { useNavigate, useParams } from "react-router-dom";
import { useEventHooks } from "../../hooks/event";
import Horizontal from "../../components/Horizontal";
import UploadPhotos, { UploadPhotoImage } from "../../components/UploadPhotos";
import CustomButton from "../../inputs/CustomButton";
import { colors } from "../../hooks/color";
import { useImageUpload } from "../../hooks/image";
import { v4 } from "uuid";
import Header from "../../components/Header";
import { GlobalContext } from "../../App";
import CustomCheckbox from "../../inputs/CustomCheckbox";
import RoundButton from "../../components/RoundButton";
import { fontFamily } from "../../hooks/font";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { ReactComponent as AddSvg } from "../../svgs/add.svg";
import { ReactComponent as PlusSvg } from "../../svgs/plus.svg";
import { ReactComponent as TrashSvg } from "../../svgs/trash.svg";
import {
  EventDisclosure,
  EventPaymentType,
  EventStatus,
  YesNo,
} from "../../enums";
import CustomDialog from "../../components/CustomDialog";
import ScrollSelect from "../../inputs/ScrollSelect";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { EventMenu, HostEvent } from "../../interfaces";
import { useUser } from "../../hooks/user";
import TimeUtil from "../../utils/time";

function EventDetailStep3() {
  const navigate = useNavigate();
  return (
    <div
      style={{
        padding: 30,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      <div
        style={{
          fontFamily: fontFamily.simula,
          fontSize: 30,
          color: colors.orange,
          textAlign: "center",
        }}
      >
        Thank you for creating a new event!
      </div>
      <div
        style={{
          marginTop: 30,
          fontSize: 14,
          textAlign: "center",
        }}
      >
        <div>
          We will review your event in a moment. Review process can take up to 1
          week.
        </div>
        <div style={{ marginTop: 30 }}>
          Your event is editable until it goes under review.
        </div>
      </div>
      <div style={{ marginTop: 30 }}>
        <RoundButton style={{ width: 150 }} handleClick={() => navigate(-1)}>
          Done
        </RoundButton>
      </div>
    </div>
  );
}

interface MenuCardComponentProps {
  menu: EventMenu;
  index: number;
  menus: EventMenu[];
  setMenus: (menus: EventMenu[]) => void;
}

function MenuCardComponent({
  menu,
  index,
  menus,
  setMenus,
}: MenuCardComponentProps) {
  const [open, setOpen] = useState<boolean>(false);
  return (
    <>
      <div
        style={{
          position: "relative",
          borderRadius: 20,
          border: `1px solid ${colors.green}`,
          padding: 20,
          backgroundColor: colors.ivory,
        }}
      >
        <div style={{ fontSize: 20, color: colors.orange }}>
          Menu {index + 1}
        </div>
        <div style={{ marginTop: 15 }}>
          <TextInput
            label="Menu Name"
            handleChange={(title) => {
              if (title.length > 50) return;
              menu.title = title;
              setMenus([...menus]);
            }}
            value={menu.title}
            sublabel={`${menu.title.length}/50`}
          />
        </div>
        <div style={{ marginTop: 15 }}>
          <TextInput
            label="Menu Description"
            handleChange={(description) => {
              if (description.length > 100) return;
              menu.description = description;
              setMenus([...menus]);
            }}
            multiline
            rows={5}
            value={menu.description}
            sublabel={`${100 - menu.description.length} Characters Left`}
          />
        </div>
        <div
          style={{
            marginTop: 15,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>Allergens</div>
          <AddSvg style={{ cursor: "pointer" }} onClick={() => setOpen(true)} />
        </div>
        {menu.allergens.length > 0 && (
          <div style={{ marginTop: 15 }}>{menu.allergens.join(", ")}</div>
        )}
        {menus.length > 1 && (
          <div
            style={{
              position: "absolute",
              top: 20,
              right: 20,
              cursor: "pointer",
              display: "flex",
              zIndex: 1,
            }}
            onClick={() => {
              menus.splice(index, 1);
              setMenus([...menus]);
            }}
          >
            <TrashSvg stroke={colors.green} />
          </div>
        )}
      </div>
      <CustomDialog open={open} handleClose={() => setOpen(false)}>
        <ScrollSelect
          items={allergens.map((allergen) => ({
            label: allergen,
            value: allergen,
          }))}
          handleChange={(values) => {
            menu.allergens = values;
            setMenus([...menus]);
          }}
          label="Allergens"
          values={menu.allergens}
        />
      </CustomDialog>
    </>
  );
}

interface EventDetailStep2Props {
  menus: EventMenu[];
  setMenus: (menus: EventMenu[]) => void;
}

function EventDetailStep2({ menus, setMenus }: EventDetailStep2Props) {
  useEffect(() => {
    if (menus.length === 0) {
      setMenus([
        {
          id: v4(),
          description: "",
          title: "",
          allergens: [],
        },
      ]);
    }
  }, [menus, setMenus]);

  return (
    <>
      <div
        style={{
          color: colors.orange,
        }}
      >
        Hold and drag the menu cards to reorder.
      </div>
      <DragDropContext
        onDragEnd={(props) => {
          if (!props.destination) return;
          // swap index
          const temp = menus[props.source.index];
          menus[props.source.index] = menus[props.destination.index];
          menus[props.destination.index] = temp;
          setMenus([...menus]);
        }}
      >
        <Droppable droppableId="droppable">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {menus.map((menu, index) => (
                <Draggable draggableId={menu.id} index={index} key={menu.id}>
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={{
                        ...provided.draggableProps.style,
                        marginTop: 20,
                      }}
                    >
                      <MenuCardComponent
                        menu={menu}
                        index={index}
                        menus={menus}
                        setMenus={setMenus}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <div style={{ marginTop: 15 }}>
        <CustomButton
          fullWidth
          style={{
            backgroundColor: colors.green,
            stroke: colors.ivory,
            borderRadius: 10,
          }}
          handleClick={() => {
            setMenus([
              ...menus,
              {
                id: v4(),
                title: "",
                description: "",
                allergens: [],
              },
            ]);
          }}
        >
          <PlusSvg />
        </CustomButton>
      </div>
    </>
  );
}

interface EventDetailStep1Props {
  title: string;
  setTitle: (title: string) => void;
  description: string;
  setDescription: (description: string) => void;
  images: UploadPhotoImage[];
  setImages: (images: UploadPhotoImage[]) => void;
  location: string;
  setLocation: (location: string) => void;
  locationDetail: string;
  setLocationDetail: (locationDetail: string) => void;
  entryInstruction: string;
  setEntryInstruction: (entryInstruction: string) => void;
  useHome: boolean;
  setUseHome: (useHome: boolean) => void;
  address1: string;
  setAddress1: (address1: string) => void;
  address2: string;
  setAddress2: (address2: string) => void;
  city: string;
  setCity: (city: string) => void;
  state: string;
  setState: (state: string) => void;
  zipcode: string;
  setZipcode: (zipcode: string) => void;
  date: string;
  setDate: (date: string) => void;
  startTime: string;
  setStartTime: (startTime: string) => void;
  endTime: string;
  setEndTime: (endTime: string) => void;
  eventType: string;
  setEventType: (eventType: string) => void;
  cuisine: string;
  setCuisine: (cuisine: string) => void;
  capacity: number;
  setCapacity: (capacity: number) => void;
  eventPaymentType: EventPaymentType;
  setEventPaymentType: (eventPaymentType: EventPaymentType) => void;
  price: number;
  setPrice: (price: number) => void;
  homeRule: string;
  setHomeRule: (homeRule: string) => void;
  eventDisclosure: EventDisclosure;
  setEventDisclosure: (eventDisclosure: EventDisclosure) => void;
  alcohol: YesNo;
  setAlcohol: (alcohol: YesNo) => void;
}

function EventDetailStep1({
  title,
  setTitle,
  description,
  setDescription,
  images,
  setImages,
  location,
  setLocation,
  locationDetail,
  setLocationDetail,
  entryInstruction,
  setEntryInstruction,
  useHome,
  setUseHome,
  address1,
  setAddress1,
  address2,
  setAddress2,
  city,
  setCity,
  state,
  setState,
  zipcode,
  setZipcode,
  date,
  setDate,
  startTime,
  setStartTime,
  endTime,
  setEndTime,
  eventType,
  setEventType,
  cuisine,
  setCuisine,
  capacity,
  setCapacity,
  eventPaymentType,
  setEventPaymentType,
  price,
  setPrice,
  homeRule,
  setHomeRule,
  eventDisclosure,
  setEventDisclosure,
  alcohol,
  setAlcohol,
}: EventDetailStep1Props) {
  const user = useUser();

  useEffect(() => {
    if (!user) return;
    if (useHome) {
      setAddress1(user.meta.address1);
      setAddress2(user.meta.address2);
      setCity(user.meta.city);
      setState(user.meta.state);
      setZipcode(user.meta.zipcode);
    }
  }, [useHome, user, setAddress1, setAddress2, setCity, setState, setZipcode]);

  return (
    <>
      <div style={{ marginTop: 15 }}>
        <TextInput
          label="Event Title"
          handleChange={setTitle}
          defaultValue={title}
        />
      </div>
      <div style={{ marginTop: 15 }}>
        <TextInput
          label="Event description"
          handleChange={setDescription}
          multiline
          rows={5}
          defaultValue={description}
        />
      </div>
      <div style={{ marginTop: 15 }}>
        <UploadPhotos images={images} setImages={setImages} />
      </div>
      <div style={{ marginTop: 15 }}>
        {(() => {
          const minDateString = TimeUtil.getNowDateString();
          const today = new Date();
          today.setDate(today.getDate() + 25);
          const maxDateString = TimeUtil.convertDateString(today);
          return (
            <TextInput
              type="date"
              label="Date"
              handleChange={(date) => {
                if (date < minDateString) return;
                if (date > maxDateString) return;
                setDate(date);
              }}
              value={date}
              inputProps={{
                min: minDateString,
                max: maxDateString,
              }}
            />
          );
        })()}
      </div>
      <div
        style={{
          marginTop: 15,
          display: "flex",
          alignItems: "flex-end",
          justifyContent: "space-between",
        }}
      >
        <div>
          <TextInput
            type="time"
            label="Time"
            handleChange={setStartTime}
            value={startTime}
            placeholder="hh:mm"
          />
        </div>
        <div style={{ width: 30 }}>
          <Horizontal marginBottom={25} />
        </div>
        <div>
          <TextInput
            type="time"
            handleChange={setEndTime}
            value={endTime}
            placeholder="hh:mm"
          />
        </div>
      </div>
      <div style={{ marginTop: 15 }}>
        <SelectInput
          name="event_type"
          label="Event Type"
          value={eventType}
          items={eventTypes.map((eventType) => ({
            label: eventType,
            value: eventType,
          }))}
          handleChange={setEventType}
        />
      </div>
      <div style={{ marginTop: 15 }}>
        <SelectInput
          name="cuisine"
          label="Cuisine"
          value={cuisine}
          items={cuisines.map((cuisine) => ({
            label: cuisine,
            value: cuisine,
          }))}
          handleChange={setCuisine}
        />
      </div>
      <div style={{ marginTop: 15 }}>
        <TextInput
          type="number"
          label="Maximum Capacity"
          handleChange={(value) => setCapacity(Number(value))}
          value={String(capacity)}
          inputProps={{
            min: 0,
          }}
        />
      </div>
      <div style={{ marginTop: 15 }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>Is this a free event?</div>
          <div style={{ display: "flex", gap: 10 }}>
            <CustomCheckbox
              label={YesNo.yes}
              checked={eventPaymentType === EventPaymentType.free}
              handleCheck={() => setEventPaymentType(EventPaymentType.free)}
              left
            />
            <CustomCheckbox
              label={YesNo.no}
              checked={eventPaymentType === EventPaymentType.paid}
              handleCheck={() => setEventPaymentType(EventPaymentType.paid)}
              left
            />
          </div>
        </div>
      </div>
      {eventPaymentType === EventPaymentType.paid && (
        <div style={{ marginTop: 15 }}>
          <TextInput
            type="number"
            label="Price Per Person ($)"
            handleChange={(value) => setPrice(Number(value))}
            value={String(price)}
            inputProps={{
              min: 0,
            }}
          />
        </div>
      )}
      <Horizontal marginTop={30} marginBottom={30} />
      <div>
        <SelectInput
          name="location"
          label="Hosting Location Type"
          value={location}
          items={locationTypes.map((locationType) => ({
            label: locationType,
            value: locationType,
          }))}
          handleChange={setLocation}
        />
      </div>
      {location === "Other" && (
        <div style={{ marginTop: 15 }}>
          <TextInput
            label="Specify other location"
            handleChange={setLocationDetail}
            defaultValue={locationDetail}
          />
        </div>
      )}
      <div style={{ marginTop: 15 }}>
        <TextInput
          label="Entry Instruction"
          handleChange={setEntryInstruction}
          multiline
          rows={5}
          defaultValue={entryInstruction}
          sublabel="(Optional)"
        />
      </div>
      <div style={{ marginTop: 15 }}>
        <CustomCheckbox
          label="Use home address"
          checked={useHome}
          handleCheck={setUseHome}
        />
      </div>
      <div style={{ marginTop: 15 }}>
        <TextInput
          disabled={useHome}
          name="address1"
          label="Street Address"
          handleChange={setAddress1}
          value={address1}
        />
      </div>
      <div style={{ marginTop: 15 }}>
        <TextInput
          disabled={useHome}
          name="address2"
          label="Apt. / Suite Number (Optional)"
          handleChange={setAddress2}
          value={address2}
        />
      </div>
      <div style={{ marginTop: 15 }}>
        <TextInput
          disabled={useHome}
          name="city"
          label="City"
          handleChange={setCity}
          value={city}
        />
      </div>
      <div style={{ marginTop: 15 }}>
        <SelectInput
          disabled={useHome}
          name="state"
          label="State"
          value={state}
          items={USStates.map((state) => ({
            value: state,
            label: state,
          }))}
          handleChange={setState}
        />
      </div>
      <div style={{ marginTop: 15 }}>
        <TextInput
          disabled={useHome}
          name="zipcode"
          label="Zip Code"
          handleChange={setZipcode}
          maxLength={5}
          value={zipcode}
        />
      </div>
      <div style={{ marginTop: 15 }}>
        <TextInput
          label="House rules"
          handleChange={setHomeRule}
          multiline
          rows={5}
          defaultValue={homeRule}
          sublabel="(Optional)"
        />
      </div>
      <Horizontal marginTop={15} marginBottom={0} />
      <div style={{ marginTop: 15 }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>Is this a private event?</div>
          <div style={{ display: "flex", gap: 10 }}>
            <CustomCheckbox
              label={YesNo.yes}
              checked={eventDisclosure === EventDisclosure.private}
              handleCheck={() => setEventDisclosure(EventDisclosure.private)}
              left
            />
            <CustomCheckbox
              label={YesNo.no}
              checked={eventDisclosure === EventDisclosure.public}
              handleCheck={() => setEventDisclosure(EventDisclosure.public)}
              left
            />
          </div>
        </div>
      </div>
      <div style={{ marginTop: 15 }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>Does this event have alcohol?</div>
          <div style={{ display: "flex", gap: 10 }}>
            <CustomCheckbox
              label={YesNo.yes}
              checked={alcohol === YesNo.yes}
              handleCheck={() => setAlcohol(YesNo.yes)}
              left
            />
            <CustomCheckbox
              label={YesNo.no}
              checked={alcohol === YesNo.no}
              handleCheck={() => setAlcohol(YesNo.no)}
              left
            />
          </div>
        </div>
      </div>
      {/* <Horizontal marginTop={30} marginBottom={30} />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            fontFamily: fontFamily.simula,
            fontSize: 20,
            color: colors.orange,
          }}
        >
          Add Promo Code
        </div>
        <div>
          <RoundButton
            backgroundColor={colors.orange}
            color={colors.ivory}
            style={{ width: 100 }}
          >
            Add
          </RoundButton>
        </div>
      </div> */}
      <Horizontal marginTop={30} marginBottom={30} />
    </>
  );
}

function EventsDetailEdit() {
  const [title, setTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [images, setImages] = useState<UploadPhotoImage[]>([]);
  const [location, setLocation] = useState<string>("");
  const [locationDetail, setLocationDetail] = useState<string>("");
  const [entryInstruction, setEntryInstruction] = useState<string>("");
  const [useHome, setUseHome] = useState<boolean>(false);
  const [address1, setAddress1] = useState<string>("");
  const [address2, setAddress2] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [state, setState] = useState<string>("");
  const [zipcode, setZipcode] = useState<string>("");
  const [date, setDate] = useState<string>("");
  const [startTime, setStartTime] = useState<string>("");
  const [endTime, setEndTime] = useState<string>("");
  const [eventType, setEventType] = useState<string>("");
  const [cuisine, setCuisine] = useState<string>("");
  const [capacity, setCapacity] = useState<number>(0);
  const [eventPaymentType, setEventPaymentType] = useState<EventPaymentType>(
    EventPaymentType.paid
  );
  const [price, setPrice] = useState<number>(0);
  const [menus, setMenus] = useState<EventMenu[]>([]);
  const [homeRule, setHomeRule] = useState<string>("");
  const [eventDisclosure, setEventDisclosure] = useState<EventDisclosure>(
    EventDisclosure.public
  );
  const [alcohol, setAlcohol] = useState<YesNo>(YesNo.no);
  const [loaded, setLoaded] = useState<boolean>(false);
  const navigate = useNavigate();
  const params = useParams();
  const { createEvent, patchStatus } = useEventHooks();
  const [step, setStep] = useState<number>(0);
  const { imageUpload } = useImageUpload();
  const { toggleSpinner } = useContext(GlobalContext);

  const next = () => {
    if (step < 1) {
      return setStep(step + 1);
    }
    return handlePublish().then(() => setStep(step + 1));
  };

  const disabledCondition = () => {
    if (step === 0) {
      return !(
        Boolean(title) &&
        Boolean(description) &&
        Boolean(images.length) &&
        Boolean(location) &&
        (location !== "Other" || Boolean(locationDetail)) &&
        Boolean(address1) &&
        Boolean(city) &&
        Boolean(state) &&
        Boolean(zipcode) &&
        Boolean(date) &&
        Boolean(startTime) &&
        Boolean(endTime) &&
        Boolean(capacity) &&
        (eventPaymentType === EventPaymentType.free || Boolean(price)) &&
        Boolean(eventDisclosure) &&
        Boolean(alcohol)
      );
    }
    if (step === 1) {
      // make sure all menus are filled
      for (const menu of menus) {
        if (!menu.title) return true;
      }
      return false;
    }
    return false;
  };

  useEffect(() => {
    if (params.eventId === "new") return setLoaded(true);
    fetchData<HostEvent>("GET", `/events/${params.eventId}`).then((event) => {
      setTitle(event.meta.title || "");
      setDescription(event.meta.description || "");
      setImages(
        event.meta.images.map((image) => ({
          location: image,
          file: null,
        }))
      );
      setLocation(event.meta.location || "");
      setLocationDetail(event.meta.location_detail || "");
      setEntryInstruction(event.meta.entry_instruction || "");
      setUseHome(event.meta.use_home || false);
      setAddress1(event.meta.address1 || "");
      setAddress2(event.meta.address2 || "");
      setCity(event.meta.city || "");
      setState(event.meta.state || "");
      setZipcode(event.meta.zipcode || "");
      setDate(event.meta.date || "");
      setStartTime(event.meta.start_time || "");
      setEndTime(event.meta.end_time || "");
      setEventType(event.meta.event_type || "");
      setCuisine(event.meta.cuisine || "");
      setEventPaymentType(
        event.meta.event_payment_type || EventPaymentType.paid
      );
      setCapacity(event.meta.capacity || 0);
      setPrice(event.meta.price || 0);
      setEventDisclosure(event.meta.event_disclosure);
      setAlcohol(event.meta.alcohol);
      setMenus(event.meta.menus || []);
      setHomeRule(event.meta.home_rule || "");
      setLoaded(true);
    });
  }, [params]);

  const handlePublish = () => {
    return handleSave()
      .then((eventId) => patchStatus(eventId, EventStatus.under_review))
      .then(() => navigate(-1));
  };

  const handleSave = () => {
    toggleSpinner();
    // if it's new, then create event first
    let prePromise = () => new Promise((resolve) => resolve(params.eventId));
    if (params.eventId === "new") {
      prePromise = () => createEvent().then((event) => event.id);
    }
    // do image first
    return prePromise().then(async (eventId) => {
      const image_uploads = await Promise.all(
        images.map((image) => {
          if (!image.file) {
            return {
              location: image.location,
            };
          }
          return imageUpload(image.file);
        })
      );
      return fetchData<HostEvent>("PUT", `/events/${eventId}`, {
        title,
        description,
        images: image_uploads.map((image_uploads) => image_uploads.location),
        location,
        location_detail: locationDetail,
        entry_instruction: entryInstruction,
        use_home: useHome,
        address1,
        address2,
        city,
        state,
        zipcode,
        date,
        start_time: startTime,
        end_time: endTime,
        event_type: eventType,
        cuisine,
        capacity,
        event_payment_type: eventPaymentType,
        price,
        home_rule: homeRule,
        event_disclosure: eventDisclosure,
        alcohol,
        menus,
      })
        .then(toggleSpinner)
        .then(() => Number(eventId));
    });
  };

  if (!loaded) return null;

  if (step === 2) return <EventDetailStep3 />;

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <Header
        label={params.eventId === "new" ? "New Event" : "Event edit"}
        handleBack={() => {
          if (step === 0) return navigate(-1);
          setStep(step - 1);
        }}
      />
      <div
        style={{
          padding: 30,
          flex: 1,
        }}
      >
        {step === 0 && (
          <EventDetailStep1
            title={title}
            setTitle={setTitle}
            description={description}
            setDescription={setDescription}
            images={images}
            setImages={setImages}
            location={location}
            setLocation={setLocation}
            locationDetail={locationDetail}
            setLocationDetail={setLocationDetail}
            entryInstruction={entryInstruction}
            setEntryInstruction={setEntryInstruction}
            useHome={useHome}
            setUseHome={setUseHome}
            address1={address1}
            setAddress1={setAddress1}
            address2={address2}
            setAddress2={setAddress2}
            city={city}
            setCity={setCity}
            state={state}
            setState={setState}
            zipcode={zipcode}
            setZipcode={setZipcode}
            date={date}
            setDate={setDate}
            startTime={startTime}
            setStartTime={setStartTime}
            endTime={endTime}
            eventType={eventType}
            setEventType={setEventType}
            cuisine={cuisine}
            setCuisine={setCuisine}
            setEndTime={setEndTime}
            capacity={capacity}
            setCapacity={setCapacity}
            eventPaymentType={eventPaymentType}
            setEventPaymentType={setEventPaymentType}
            price={price}
            setPrice={setPrice}
            homeRule={homeRule}
            setHomeRule={setHomeRule}
            eventDisclosure={eventDisclosure}
            setEventDisclosure={setEventDisclosure}
            alcohol={alcohol}
            setAlcohol={setAlcohol}
          />
        )}
        {step === 1 && <EventDetailStep2 menus={menus} setMenus={setMenus} />}
        {step < 2 && (
          <div
            style={{
              marginTop: 30,
              textAlign: "center",
            }}
          >
            <div>
              <RoundButton disabled={disabledCondition()} handleClick={next}>
                {step === 0 && "Continue"}
                {step === 1 && "Request to publish"}
              </RoundButton>
            </div>
            <div
              style={{
                marginTop: 15,
                display: "inline-flex",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => handleSave().then(() => navigate(-1))}
            >
              <ChevronLeftIcon />
              <div style={{ fontWeight: 600 }}>Save & Exit</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default EventsDetailEdit;
