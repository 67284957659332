import Header from "../../components/Header";
import { useContext, useEffect, useState } from "react";
import { HostInfoInputComponent } from "./HostOnboard";
import { useUser } from "../../hooks/user";
import { useHostHooks } from "../../hooks/host";
import { GlobalContext } from "../../App";
import { useNavigate } from "react-router-dom";
import RoundButton from "../../components/RoundButton";

function AboutMe() {
  const { refreshUser } = useContext(GlobalContext);
  const user = useUser();
  const [aboutMe, setAboutMe] = useState<string>("");
  const [experienceInPartySize, setExperienceInPartySize] =
    useState<string>("");
  const [languages, setLanguages] = useState<string[]>([]);
  const [identityImage, setIdentityImage] = useState<string>("");
  const [loaded, setLoaded] = useState<boolean>(false);
  const { putHost } = useHostHooks();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user || !user.host) return;
    setAboutMe(user.host.meta.about_me);
    setExperienceInPartySize(user.host.meta.experience_in_party_size);
    setLanguages(user.host.meta.languages);
    setIdentityImage(user.host.meta.identity_image);
    setLoaded(true);
  }, [user]);

  const disabledCondition = !(
    Boolean(aboutMe) &&
    Boolean(experienceInPartySize) &&
    Boolean(languages) &&
    Boolean(identityImage)
  );

  if (!loaded || !user || !user.host) return null;

  return (
    <>
      <Header label="About Me" handleBack={() => navigate("/profile")} />
      <div style={{ padding: 30 }}>
        <HostInfoInputComponent
          aboutMe={aboutMe}
          setAboutMe={setAboutMe}
          experienceInPartySize={experienceInPartySize}
          setExperienceInPartySize={setExperienceInPartySize}
          languages={languages}
          setLanguages={setLanguages}
        />
        <div style={{ marginTop: 15, textAlign: "center" }}>
          <RoundButton
            handleClick={() => {
              // file upload first
              return putHost({
                aboutMe,
                experienceInPartySize,
                languages,
                payout: user.host!.meta.payout,
              })
                .then(refreshUser)
                .then(() => navigate("/profile"));
            }}
            disabled={disabledCondition}
          >
            Save
          </RoundButton>
        </div>
      </div>
    </>
  );
}

export default AboutMe;
