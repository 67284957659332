import React, { ReactNode } from "react";
import ImageUpload from "../inputs/ImageUpload";
import CustomImage from "./CustomImage";
import { colors } from "../hooks/color";
import { ReactComponent as PlusSvg } from "../svgs/plus.svg";
import DeleteIcon from "@mui/icons-material/Delete";

interface InnerCardComponentProps {
  image: UploadPhotoImage | null;
  toAdd: boolean;
  setFile: (file: File) => void;
  handleDelete: () => void;
}

function InnerCardComponent({
  image,
  toAdd,
  setFile,
  handleDelete,
}: InnerCardComponentProps) {
  const Wrapper = ({ children }: { children: ReactNode }) => {
    if (!toAdd) return <>{children}</>;
    return (
      <ImageUpload handleChange={(file) => setFile(file)}>
        {image && (
          <div
            style={{
              position: "absolute",
              bottom: 5,
              right: 5,
              display: "flex",
              cursor: "pointer",
              zIndex: 10,
            }}
            onClick={(e) => {
              handleDelete();
              e.stopPropagation();
            }}
          >
            <DeleteIcon
              style={{
                stroke: colors.green,
                fill: colors.ivory,
                width: 20,
                height: 20,
              }}
            />
          </div>
        )}
        {children}
      </ImageUpload>
    );
  };

  return (
    <Wrapper>
      <div
        style={{
          width: "100%",
          paddingTop: "100%",
          borderRadius: 8,
          border: image ? undefined : `1px dashed ${colors.green}`,
          position: "relative",
          overflow: "hidden",
        }}
      >
        {image && (
          <CustomImage
            src={image.location}
            style={{
              position: "absolute",
              inset: 0,
            }}
          />
        )}
        {!image && (
          <div
            style={{
              position: "absolute",
              inset: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {toAdd && <PlusSvg style={{ stroke: colors.green }} />}
          </div>
        )}
      </div>
    </Wrapper>
  );
}

export interface UploadPhotoImage {
  location: string;
  file: File | null;
}

interface UploadPhotosProps {
  images: UploadPhotoImage[];
  setImages: (images: UploadPhotoImage[]) => void;
}

function UploadPhotos({ images, setImages }: UploadPhotosProps) {
  const filledUp = [...images] as (UploadPhotoImage | null)[];
  while (filledUp.length < 10) {
    filledUp.push(null);
  }

  const handleFile = (file: File, index: number) => {
    // check if image is null or not
    // if null, then make it into object
    const currImage = images[index];
    if (!currImage) {
      images.push({
        file,
        location: URL.createObjectURL(file),
      });
    } else {
      currImage.file = file;
      currImage.location = URL.createObjectURL(file);
    }
    setImages([...images]);
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <div>Add Photos</div>
        </div>
        <div style={{ opacity: 0.5 }}>Upload up to 10 photos</div>
      </div>
      <div
        style={{
          marginTop: 5,
          borderRadius: 10,
          border: `1px solid ${colors.green}`,
          padding: 5,
        }}
      >
        <div
          style={{
            display: "flex",
            gap: 5,
          }}
        >
          {filledUp.slice(0, 5).map((image, index) => (
            <div
              key={index}
              style={{
                width: "20%",
              }}
            >
              <InnerCardComponent
                image={image}
                toAdd={
                  Boolean(image) || index === 0 || Boolean(images[index - 1])
                }
                setFile={(file) => handleFile(file, index)}
                handleDelete={() => {
                  images.splice(index, 1);
                  setImages([...images]);
                }}
              />
            </div>
          ))}
        </div>
        <div
          style={{
            marginTop: 5,
            display: "flex",
            gap: 5,
          }}
        >
          {filledUp.slice(5, 10).map((image, index) => (
            <div
              key={index}
              style={{
                width: "20%",
              }}
            >
              <InnerCardComponent
                image={image}
                toAdd={Boolean(image) || Boolean(images[5 + index])}
                setFile={(file) => handleFile(file, 5 + index)}
                handleDelete={() => {
                  if (!image) return undefined;
                  images.splice(5 + index, 1);
                  setImages([...images]);
                }}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default UploadPhotos;
