import React, { ReactNode, useState } from "react";
import { AvatarName } from "./Avatar";
import CustomDrawer from "./CustomDrawer";
import UserProfile from "./UserProfile";
import { UserMode } from "../enums";

interface UserAvatarProps {
  firstname: string;
  partySize: number;
  image: string;
  userId: number;
  footer?: ReactNode;
}

function UserAvatar({
  firstname,
  partySize,
  image,
  userId,
  footer,
}: UserAvatarProps) {
  const [open, setOpen] = useState<boolean>(false);
  return (
    <>
      <div
        style={{ marginTop: 30, cursor: "pointer" }}
        onClick={() => setOpen(true)}
      >
        <AvatarName width={50} label={firstname} pops={partySize} src={image} />
      </div>
      <CustomDrawer
        open={open}
        handleClose={() => setOpen(false)}
        height="90vh"
      >
        {open && (
          <UserProfile
            userId={userId}
            visualize={{
              avatar: true,
              message: true,
              summary: true,
              name: true,
              attendedEvents: true,
              userReviews: true,
            }}
            userMode={UserMode.guest}
          />
        )}
        {footer}
      </CustomDrawer>
    </>
  );
}

export default UserAvatar;
