import React, { ReactNode } from "react";
import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";

interface CustomSwiperProps {
  slides: ReactNode[];
  spaceBetween?: number;
  slidesPerView?: number;
  centeredSlides?: boolean;
  pagination?: boolean;
  setSwiper?: (swiper: SwiperClass) => void;
}

function CustomSwiper({
  slides,
  spaceBetween = 0,
  slidesPerView = 1,
  centeredSlides,
  pagination,
  setSwiper,
}: CustomSwiperProps) {
  return (
    <Swiper
      onSwiper={setSwiper}
      centeredSlides={centeredSlides}
      spaceBetween={spaceBetween}
      slidesPerView={slidesPerView}
      pagination={
        pagination
          ? {
              clickable: true,
            }
          : undefined
      }
      modules={[Pagination]}
      noSwiping={false}
    >
      {slides.map((slide, index) => (
        <SwiperSlide key={index}>{slide}</SwiperSlide>
      ))}
    </Swiper>
  );
}

export default CustomSwiper;
