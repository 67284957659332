import React from "react";
import Header from "../../components/Header";
import { useNavigate } from "react-router-dom";

export function TermsAndConditionContent() {
  return (
    <>
      <div style={{ fontWeight: 700 }}>Acceptance of Terms</div>
      <ul>
        <li>
          By using the Tably platform, you agree to comply with and be bound by
          the following terms and conditions. If you do not agree with these
          terms, please refrain from using our services.
        </li>
      </ul>
      <div style={{ fontWeight: 700, marginTop: 30 }}>
        User Responsibilities
      </div>
      <ul>
        <li>
          Users are responsible for providing accurate and up-to-date
          information during registration.
        </li>
        <li>
          Guests must behave respectfully at hosted dinner parties and adhere to
          house rules.
        </li>
      </ul>
      <div style={{ fontWeight: 700, marginTop: 30 }}>
        Dinner Party Bookings
      </div>
      <ul>
        <li>
          Guests can browse and sign up for dinner parties hosted by others.
        </li>
        <li>
          Hosts are responsible for providing accurate details of their events.
        </li>
      </ul>
      <div style={{ fontWeight: 700, marginTop: 30 }}>Cancellation Policy</div>
      <ul>
        <li>
          Guests may cancel their attendance up to 48 hours before the scheduled
          event without penalty.
        </li>
        <li>
          Cancellations made within 48 hours will result in a non-refundable fee
          equal to 50% of the total booking cost.
        </li>
        <li>No-shows will be charged the full booking amount.</li>
        <li>Exceptions for documented emergencies.</li>
      </ul>
      <div style={{ fontWeight: 700, marginTop: 30 }}>
        Host Responsibilities
      </div>
      <ul>
        <li>Hosts must honor their commitment to host confirmed events.</li>
        <li>
          Tably reserves the right to take appropriate actions, including
          temporary suspension, for frequent host cancellations.
        </li>
      </ul>

      <div style={{ fontWeight: 700, marginTop: 30 }}>
        Intellectual Property
      </div>
      <ul>
        <li>
          Tably retains ownership of the platform's intellectual property.
        </li>
        <li>
          Users must not use, reproduce, or distribute content from Shared Table
          without permission.
        </li>
      </ul>

      <div style={{ fontWeight: 700, marginTop: 30 }}>Privacy Policy</div>
      <ul>
        <li>
          Tably respects user privacy. Tably reserves the right to collect your
          data but not share your personal information.
        </li>
      </ul>

      <div style={{ fontWeight: 700, marginTop: 30 }}>
        Modification of Terms
      </div>
      <ul>
        <li>
          Tably reserves the right to modify these terms at any time. Users will
          be notified of changes.
        </li>
      </ul>

      <div style={{ fontWeight: 700, marginTop: 30 }}>
        Limitation of Liability
      </div>
      <ul>
        <li>
          Tably is not liable for damages arising from the use of the platform
          or participating in dinner parties.
        </li>
      </ul>

      <div style={{ marginTop: 30 }}>
        By using Tably, you agree to these terms and conditions. Please review
        them regularly for any updates.
      </div>
    </>
  );
}

function TermsAndCondition() {
  const navigate = useNavigate();
  return (
    <>
      <Header label="Terms & Condition" handleBack={() => navigate(-1)} />
      <div style={{ padding: 30, fontSize: 14 }}>
        <TermsAndConditionContent />
      </div>
    </>
  );
}

export default TermsAndCondition;
