import React from "react";
import EventItems from "../EventItems";
import Header from "../../components/Header";
import { useNavigate } from "react-router-dom";
import { EventItemType } from "../../enums";

function AdminEvents() {
  const navigate = useNavigate();
  return (
    <>
      <Header label="Events" handleBack={() => navigate(-1)} />
      <EventItems
        eventItemTypes={[
          EventItemType.all,
          EventItemType.upcoming,
          EventItemType.approved,
          EventItemType.pending,
          EventItemType.past,
          EventItemType.cancelled,
          EventItemType.rejected,
        ]}
      />
    </>
  );
}

export default AdminEvents;
