import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Welcome from "../components/Welcome";
import Loading from "../components/Loading";
import { useCurrentMode } from "../hooks/mode";
import { UserMode } from "../enums";

function Init() {
  const navigate = useNavigate();
  const [started, setStarted] = useState<boolean>(false);
  const currentMode = useCurrentMode();
  if (!started) return <Welcome handleStart={() => setStarted(true)} />;
  return (
    <Loading
      loadingIn
      handleOut={() => {
        if (
          currentMode === UserMode.unsigned ||
          currentMode === UserMode.guest
        ) {
          navigate("/event");
        } else if (currentMode === UserMode.host) {
          navigate("/upcoming");
        } else if (currentMode === UserMode.admin) {
          navigate("/profile");
        }
      }}
    />
  );
}

export default Init;
