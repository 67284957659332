import React, { useContext, useEffect } from "react";
import SmallLogo from "./logos/SmallLogo";
import { colors } from "../hooks/color";
import RoundButton from "./RoundButton";
import { GlobalContext } from "../App";
import { useNavigate } from "react-router-dom";

interface WelcomeProps {
  handleStart?: () => void;
}

function Welcome({ handleStart }: WelcomeProps) {
  const { user } = useContext(GlobalContext);
  useEffect(() => {
    // if user is there, then we don't put here
    if (!user || !handleStart) return;
    handleStart();
  }, [user]);
  return (
    <div
      style={{ padding: 30, backgroundColor: colors.green, minHeight: "100%" }}
    >
      <div style={{ display: "flex" }}>
        <SmallLogo yellow />
      </div>
      <div
        style={{
          marginTop: 60,
          color: colors.white,
          fontSize: 60,
          lineHeight: 1.15,
        }}
      >
        Dining experience unlike any other.
      </div>
      <div
        style={{
          marginTop: 60,
          color: colors.white,
          fontSize: 16,
        }}
      >
        Shared service for cooking, eating and making friends. All at once.
      </div>
      <div style={{ marginTop: 100 }}>
        <RoundButton
          backgroundColor={colors.yellow}
          color={colors.green}
          handleClick={handleStart}
        >
          Get Started
        </RoundButton>
      </div>
    </div>
  );
}

export default Welcome;
