import React, { useState } from "react";

interface ReadMoreProps {
  children?: string;
}

function ReadMore({ children }: ReadMoreProps) {
  const [more, setMore] = useState<boolean>(false);
  if (!children) return null;
  const moreCondition = children.length > 250;
  const trimmedChildren = children.slice(0, 250);

  const toggle = () => setMore(!more);

  if (!more) {
    return (
      <>
        <div>{trimmedChildren}</div>
        {moreCondition && (
          <div
            style={{ marginTop: 10, fontWeight: 700, cursor: "pointer" }}
            onClick={toggle}
          >
            Read More &gt;
          </div>
        )}
      </>
    );
  }
  return (
    <>
      <div>{children}</div>
      <div
        style={{ marginTop: 10, fontWeight: 700, cursor: "pointer" }}
        onClick={toggle}
      >
        Show Less &lt;
      </div>
    </>
  );
}

export default ReadMore;
