import { Button } from "@mui/material";
import React, { ReactNode } from "react";
import { useColor } from "../hooks/color";

interface CustomButtonProps {
  fullWidth?: boolean;
  children?: ReactNode;
  handleClick?: () => void;
  type?: "button" | "submit" | "reset";
  style?: React.CSSProperties;
  disabled?: boolean;
}

function CustomButton({
  fullWidth,
  children,
  handleClick,
  type,
  style,
  disabled,
}: CustomButtonProps) {
  const color = useColor();
  return (
    <Button
      style={{
        backgroundColor: color,
        color: "white",
        textTransform: "none",
        opacity: disabled ? 0.6 : 1,
        padding: 15,
        ...style,
      }}
      fullWidth={fullWidth}
      onClick={handleClick}
      type={type}
      disabled={disabled}
    >
      {children}
    </Button>
  );
}

export default CustomButton;
