import React, { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Backward from "../components/Backward";
import { fetchData } from "../api";
import { Message } from "../interfaces";
import TimeUtil from "../utils/time";
import Avatar from "../components/Avatar";
import { colors } from "../hooks/color";
import TextInput from "../inputs/TextInput";
import RoundButton from "../components/RoundButton";
import { GlobalContext } from "../App";
import { MessageType, SocketMessageType } from "../enums";
import { ReactComponent as CameraSvg } from "../svgs/camera.svg";
import ImageUpload from "../inputs/ImageUpload";
import CustomImage from "../components/CustomImage";
import DeleteIcon from "@mui/icons-material/Delete";
import { useImageUpload } from "../hooks/image";

interface MessageComponentProps {
  refinedMessage: RefinedMessage;
}

function MessageComponent({ refinedMessage }: MessageComponentProps) {
  if (refinedMessage.date) {
    return (
      <div style={{ textAlign: "center", fontWeight: 600, fontSize: 12 }}>
        {refinedMessage.date}
      </div>
    );
  }
  if (refinedMessage.message) {
    if (refinedMessage.message.type === MessageType.joined) {
      return (
        <div style={{ textAlign: "center" }}>
          {refinedMessage.message.name} joined
        </div>
      );
    }
    if (refinedMessage.message.type === MessageType.left) {
      return (
        <div style={{ textAlign: "center" }}>
          {refinedMessage.message.name} left
        </div>
      );
    }
    if (!refinedMessage.message.self) {
      return (
        <div style={{ display: "flex", columnGap: 10, fontSize: 14 }}>
          <div>
            <Avatar width={50} height={50} src={refinedMessage.message.image} />
          </div>
          <div
            style={{
              border: `1px solid ${colors.green}`,
              padding: 15,
              whiteSpace: "pre-line",
              borderRadius: "0 20px 20px 20px",
              textWrap: "wrap",
            }}
          >
            {refinedMessage.message.message_image && (
              <img
                src={refinedMessage.message.message_image}
                alt=""
                style={{ width: "100%", height: "auto" }}
              />
            )}
            {refinedMessage.message.message}
          </div>
          <div style={{ alignSelf: "flex-end", textWrap: "nowrap" }}>
            {TimeUtil.convertTo12HourFormat(
              TimeUtil.formatTimeFromISODateString(
                refinedMessage.message.created_at
              )
            )}
          </div>
        </div>
      );
    }
    return (
      <div
        style={{
          display: "flex",
          columnGap: 10,
          fontSize: 14,
          justifyContent: "flex-end",
        }}
      >
        <div style={{ alignSelf: "flex-end" }}>
          {TimeUtil.convertTo12HourFormat(
            TimeUtil.formatTimeFromISODateString(
              refinedMessage.message.created_at
            )
          )}
        </div>
        <div
          style={{
            border: `1px solid ${colors.green}`,
            padding: 15,
            whiteSpace: "pre-line",
            borderRadius: "20px 20px 0 20px",
            backgroundColor: colors.yellow,
            maxWidth: 215,
            textWrap: "wrap",
          }}
        >
          {refinedMessage.message.message_image && (
            <img
              src={refinedMessage.message.message_image}
              alt=""
              style={{ width: "100%", height: "auto" }}
            />
          )}
          {refinedMessage.message.message}
        </div>
      </div>
    );
  }
  return null;
}

interface RefinedMessage {
  message?: Message;
  date?: string;
}

function refineMessages(messages: Message[]) {
  // we need to append to res, if there's date diff
  const reversed = [...messages].reverse();
  const res = [] as RefinedMessage[];
  for (const message of reversed) {
    // for the first, we append date, of first message
    const date = TimeUtil.formatDateFromDateTime(message.created_at);
    if (
      res.length === 0 ||
      TimeUtil.formatDateFromDateTime(
        res[res.length - 1].message!.created_at
      ) !== date
    ) {
      const date = TimeUtil.formatDateFromDateTime(message.created_at);
      res.push({
        date,
      });
    }
    res.push({ message });
  }
  return res;
}

interface RoomDetailData {
  title: string;
  messages: Message[];
}

function RoomDetail() {
  const params = useParams();
  const [roomDetailData, setRoomDetailData] = useState<RoomDetailData | null>(
    null
  );
  const navigate = useNavigate();
  const [message, setMessage] = useState<string>("");
  const [file, setFile] = useState<File | null>(null);
  const [messageContainerRef, setMessageContainerRef] =
    useState<HTMLDivElement | null>(null);
  const [messageEndRef, setMessageEndRef] = useState<HTMLDivElement | null>(
    null
  );
  const { websocket } = useContext(GlobalContext);
  const fileUrl = useMemo(
    () => (file ? URL.createObjectURL(file) : ""),
    [file]
  );
  const { imageUpload } = useImageUpload();
  const { toggleSpinner } = useContext(GlobalContext);
  const [page, setPage] = useState<number>(1);
  const [lastRoomDetailData, setLastRoomDetailData] =
    useState<RoomDetailData | null>(null);
  const amount = 20;
  const [pagedFetched, setPageFetched] = useState<number[]>([]);

  useEffect(() => {
    if (pagedFetched.includes(page)) return;
    // order by last message desc
    fetchData<RoomDetailData>(
      "GET",
      `/rooms/${params.roomId}/detail?page=${page}&amount=${amount}`
    ).then((data) => {
      setPageFetched([...pagedFetched, page]);
      setLastRoomDetailData(data);
      setRoomDetailData((roomDetailData) => {
        if (!roomDetailData) return data;
        roomDetailData.messages = [
          ...roomDetailData.messages,
          ...data.messages,
        ];
        return { ...roomDetailData };
      });
    });
  }, [params, page, pagedFetched]);

  useEffect(() => {
    if (!messageEndRef) return;
    setTimeout(() => {
      messageEndRef.scrollIntoView();
    }, 100);
  }, [messageEndRef]);

  useEffect(() => {
    if (!websocket) return;
    if (!roomDetailData) return;
    if (!messageEndRef) return;
    websocket.onmessage = (event) => {
      // Handle incoming messages
      const message = JSON.parse(event.data);
      if (message.type !== SocketMessageType.message) return;
      roomDetailData.messages = [
        message.data.message,
        ...roomDetailData.messages,
      ];
      setRoomDetailData({ ...roomDetailData });
      setTimeout(() => {
        messageEndRef.scrollIntoView({
          behavior: "smooth",
        });
      }, 100);
    };
  }, [websocket, params, roomDetailData, messageEndRef]);

  useEffect(() => {
    if (!roomDetailData) return;
    if (!messageContainerRef) return;

    const handleScroll = () => {
      const scrollTop = messageContainerRef.scrollTop;
      const scrollHeight =
        messageContainerRef.scrollHeight - messageContainerRef.clientHeight;
      const scrollPercentage = scrollTop / scrollHeight;
      // if scroll up and it's more than 30% of it, then we make next page
      if (
        lastRoomDetailData &&
        lastRoomDetailData.messages.length > 0 &&
        scrollPercentage <= 0.3
      ) {
        setPage(page + 1);
        setLastRoomDetailData(null);
      }
    };

    messageContainerRef.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [roomDetailData, messageContainerRef, page, lastRoomDetailData]);

  if (!roomDetailData) return null;

  const refinedMessages = refineMessages(roomDetailData.messages);

  const handleSend = () => {
    if (!message && !file) return;
    new Promise<string>((resolve) => {
      if (!file) return resolve("");
      toggleSpinner();
      return imageUpload(file).then(({ location }) => {
        toggleSpinner();
        resolve(location);
      });
    }).then((location) =>
      fetchData<Message>("POST", `/rooms/${params.roomId}/message`, {
        message,
        image: location,
      })
        .then(() => setMessage(""))
        .then(() => setFile(null))
    );
  };

  return (
    <div
      style={{
        padding: 30,
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Backward handleClick={() => navigate(-1)} />
      <div
        style={{
          textAlign: "center",
          fontSize: 16,
          fontWeight: 600,
          marginTop: 10,
        }}
      >
        {roomDetailData.title}
      </div>
      <div style={{ flex: 1, overflow: "auto" }} ref={setMessageContainerRef}>
        {refinedMessages.map((refinedMessage, index) => (
          <div style={{ marginTop: 30 }} key={index}>
            <MessageComponent refinedMessage={refinedMessage} />
          </div>
        ))}
        <div ref={setMessageEndRef} />
      </div>
      <div
        style={{
          marginTop: 15,
          display: "flex",
          alignItems: "center",
          columnGap: 10,
        }}
      >
        <ImageUpload handleChange={setFile}>
          <CameraSvg />
        </ImageUpload>
        <div style={{ flex: 1 }}>
          {fileUrl && (
            <div
              style={{
                borderRadius: 20,
                border: `1px solid ${colors.green}`,
                overflow: "hidden",
                position: "relative",
              }}
            >
              <CustomImage height={130} width="100%" src={fileUrl} />
              <div
                style={{
                  position: "absolute",
                  top: 15,
                  right: 15,
                  display: "flex",
                  cursor: "pointer",
                  zIndex: 10,
                }}
                onClick={() => setFile(null)}
              >
                <DeleteIcon
                  style={{
                    width: 30,
                    height: 30,
                  }}
                />
              </div>
            </div>
          )}
          <TextInput
            placeholder="Write a message"
            handleChange={setMessage}
            fullWidth
            value={message}
            multiline
            maxRows={4}
          />
        </div>
        <RoundButton handleClick={handleSend} disabled={!file && !message}>
          Send
        </RoundButton>
      </div>
    </div>
  );
}

export default RoomDetail;
