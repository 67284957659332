import { getEnvironment } from "./util";

type Method = "GET" | "POST" | "PUT" | "DELETE" | "PATCH";

export function getWebsocketServer() {
  if (getEnvironment() === "development") {
    return "ws://localhost:8000";
  }
  return "wss://api.thetably.com";
}

function getServer() {
  if (getEnvironment() === "development") {
    return "http://localhost:8000";
  }
  return "https://api.thetably.com";
}

export function setCredential(credential: string) {
  return window.localStorage.setItem("credential", credential);
}

export function getCredential() {
  return window.localStorage.getItem("credential");
}

export function removeCredential() {
  return window.localStorage.removeItem("credential");
}

export function fetchData<T>(
  method: Method,
  url: string,
  body?: any,
  file?: boolean,
  local: boolean = true
) {
  const headers: Record<string, string> = {};
  const credential = getCredential();
  // put environment header
  headers.environment = getEnvironment();
  if (credential) {
    headers.Authorization = `Bearer ${credential}`;
  }
  if (!file && body) {
    headers["Content-Type"] = "application/json";
  }

  return fetch(`${local ? getServer() : ""}${url}`, {
    method,
    mode: "cors",
    credentials: "include",
    headers,
    ...(() => {
      if (body) {
        if (file) {
          // if file, then upload as file
          return {
            body,
          };
        }
        return {
          body: JSON.stringify(body),
        };
      }
      return {};
    })(),
  })
    .then((res) => {
      if (!res.ok) {
        if (res.status === 401) {
          if (credential) {
            // removeCredential();
          }
          return null;
        }
        return res.json().then(({ detail }) => {
          throw detail;
        });
      }
      return res.json();
    })
    .then((data) => data as T);
}
