import React, { ReactNode, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";

interface ImageUploadProps {
  children?: ReactNode;
  handleChange?: (file: File) => any;
  handleDelete?: () => void;
  style?: React.CSSProperties;
}

function ImageUpload({
  children,
  handleChange,
  handleDelete,
  style,
}: ImageUploadProps) {
  const [fileInputRef, setFileInputRef] = useState<HTMLInputElement | null>(
    null
  );
  return (
    <div
      style={{
        display: "flex",
        cursor: "pointer",
        position: "relative",
        ...style,
      }}
      onClick={() => {
        if (!fileInputRef) return;
        fileInputRef.click();
      }}
    >
      <input
        type="file"
        name="file"
        accept="image/*"
        onChange={(event) => {
          const files = event.target.files;
          if (!files) return;
          const file = files[0];
          if (!file) return;
          if (handleChange) {
            handleChange(file);
          }
          event.target.value = "";
        }}
        ref={setFileInputRef}
        style={{ display: "none" }}
      />
      {children}
      {handleDelete && (
        <div
          style={{
            position: "absolute",
            bottom: 15,
            right: 15,
            display: "flex",
            cursor: "pointer",
            zIndex: 10,
          }}
          onClick={(e) => {
            handleDelete();
            e.stopPropagation();
          }}
        >
          <DeleteIcon
            style={{
              width: 30,
              height: 30,
            }}
          />
        </div>
      )}
    </div>
  );
}

export default ImageUpload;
